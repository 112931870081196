import { RequireAuth } from "@fusionauth/react-sdk";
import { GetStores } from "../../Services/Requests";
import { useEffect, useState } from "react";
import { Store } from "../../Model/Store";
import { ScrollBar } from "./Components/ScrollBar";
import { Page } from "./Components/Pages";
import { BatchPage } from "./Pages/BatchPage";
import { JournalPage } from "./Pages/JournalPage";

function ViewStoreTransactions(){
    
    const { isLoading: isLoadingStores, data: storeData, isError: isErrorStores } = GetStores();
    const [selectedStore, setSelectedStore] = useState<Store | undefined>();
    const [selectedStoreID, setSelectedStoreID] = useState(-1);
    const [selectedPage, setSelectedPage] = useState(Page.Batch);

    useEffect(() => {
        if(selectedStoreID === -1){
            if(storeData && storeData.length != 0){
                let store = storeData[0];
                setSelectedStore(store);
                setSelectedStoreID(store.id);
            }
            else
                setSelectedStore(undefined);
            return;
        }

        if(storeData){
            let store = storeData.find(i => i.id === selectedStoreID);
            store && setSelectedStore(store);
        }
        console.log(storeData)
    }, [selectedStoreID, storeData]);

    return (
    <RequireAuth>
        <div className="h-full flex flex-col">
            <div>
                <div className="flex flex-row bg-black bg-opacity-50 px-5 space-x-2">
                    <div className="pb-2 bg-offWhite rounded-md p-2">
                        <label className="font-bold text-nowrap">Select a store:</label>
                        {
                            isLoadingStores ?
                            <p >Loading...</p>
                            :
                            <select name="storeSelection" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
                            focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            onChange={(e) => setSelectedStoreID(Number(e.target.value))} value={selectedStoreID}
                            >
                                {   
                                    isErrorStores ?
                                    <option value={-1}>Error</option>
                                    :
                                    storeData && storeData.map((store) => {
                                        return <option value={store.id} key={store.id}>{store.name}</option>
                                    })
                                }
                            </select>
                        }
                    </div>

                    <ScrollBar selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
                </div>
                {   !isLoadingStores ?
                    selectedStore ?
                    <StorePage store={selectedStore} selectedPage={selectedPage} />
                    :
                    <div>
                        Nothing selected
                    </div>
                    :
                    <div>
                        Loading...
                    </div>
                }
            </div>
            
        </div>
    </RequireAuth>
    );
}

export const StorePage: React.FC<{ store: Store, selectedPage:Page }> = ({ store, selectedPage }) => {

    switch(selectedPage){
        case Page.Batch:
            return <BatchPage store={store} />;

        case Page.Journal:
            return <JournalPage store={store} />;
        
        default:
            return(
                <div>
                    Unknown page
                </div>
            );
    }
}

export default ViewStoreTransactions;