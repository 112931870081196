import { ItemPreview } from "./Item";

export type Supplier = {
    name: string;
    code: string;
    country?: string;
    state?: string;
    city?: string;
    address1?: string;
    address2?: string;
    zip?: string;
    phone?: string;
    fax?: string;
    email?: string;
    homePage?: string;
    currencyId: string;
    terms?: string;
    minOrderAmount?: number;
    notes?: string;
    active: boolean;
    contact?: string;
    customText1: string;
    customText2: string;
    customText3: string;
    customText4: string;
    customText5: string;
    customNumber1: number;
    customNumber2: number;
    customNumber3: number;
    customNumber4: number;
    customNumber5: number;
    customDate1?: Date;
    customDate2?: Date;
    customDate3?: Date;
    customDate4?: Date;
    customDate5?: Date;
    modifyUser: string;
    modifyDatetime: Date;
    accountNumber?: string;
    syncGuid: string;
    isCompanyData: boolean;
  };
  
export type SupplierPackage = {
  totalCount: number,
  suppliers: SupplierView[]
}

export type SupplierView = {
  syncGuid: string,
  code: string,
  name: string,
  contact: string,
  phone: string,
  fax: string,
  lastUpdated: Date
}

export const Template:Supplier = {
  name: "",
  code: "",
  country: "",
  state: "",
  city: "",
  address1: "",
  address2: "",
  zip: "",
  phone: "",
  fax: "",
  email: "",
  homePage: "",
  terms: "",
  notes: "",
  active: true,
  contact: "",
  customText1: "",
  customText2: "",
  customText3: "",
  customText4: "",
  customText5: "",
  customNumber1: 0,
  customNumber2: 0,
  customNumber3: 0,
  customNumber4: 0,
  customNumber5: 0,
  modifyUser: "00000000-0000-0000-0000-000000000000",
  modifyDatetime: new Date,
  accountNumber: "",
  syncGuid: "00000000-0000-0000-0000-000000000000",
  currencyId: "00000000-0000-0000-0000-000000000000",
  isCompanyData: false,
};

export type SupplierFilter = {
  chunkSize: number,
  page: number,
  search: string
}

export type SupplierInjestPackage = {
  supplier: Supplier,
  basket: ItemPreview[]
};