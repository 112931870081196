export const config = {
    //clientID: '5179c057-289c-4947-9dd2-8f45c1f015df',
    //clientId: '5a2d2703-c793-4818-94fd-8c263658df91', //Debug
    //serverUrl: 'http://localhost:9011', //Local Debug
    //redirectUri: 'http://localhost:3000', //Local Debug
    //serverUrl: 'http://192.168.2.21:9011', //Server Debug
    //redirectUri: 'http://192.168.2.21:3200', //Server Debug
    clientId: 'c493fb57-b33e-4162-98d4-4eeab76776ba', //Prod
    serverUrl: 'https://auth.1solutions.ca', //Server Prod
    redirectUri: 'https://cloud.1solutions.ca', //Server Prod
    shouldAutoFetchUserInfo: true, // Automatically fetch userInfo when logged in. Defaults to false.
    shouldAutoRefresh: true, // Enables automatic token refresh. Defaults to false.
  }