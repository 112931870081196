import { IoAddCircleOutline, IoArrowBack } from "react-icons/io5";
import FormCheckbox from "../../../Components/FormCheckbox";
import FormInput from "../../../Components/FormInput";
import { ReadableTime } from "../../../Helper/ConversionHelper";
import { useQueryClient } from "react-query";
import { useState } from "react";
import { FaPercentage, FaPen, FaTrashAlt } from "react-icons/fa";
import { CreateStoreTax, DeleteStoreTax, UpdateStoreTax } from "../../../Services/Requests";
import Tax from "../../../Model/POSModel/Tax";

export const TaxCard: React.FC<{ tax: Tax, storeID:number, modifyUser:string, editable?:boolean, deleteable?:boolean, isOrgStore?:boolean }> = ({ tax, storeID, modifyUser, editable = true, deleteable = true, isOrgStore = false }) => {

    const [editMode, setEditMode] = useState(false);

    return(
        <div className={`bg-slate-300 rounded-2xl px-4 pt-2 pb-4 transition-all duration-300 ease-linear border-black`}>
            {   !editMode &&
                <div className="flex justify-between items-center">
                    <div className="flex justify-between items-center space-x-2">
                        <h2 className="font-bold text-lg">{tax.code}</h2>
                        {editable && isOrgStore === tax.isCompanyData && <FaPen size="18" className="items-center relative flex justify-center
                        hover:text-orange-500 transition-all duration-300 ease-linear cursor-pointer"
                        onClick={() => setEditMode(true)}/>}
                    </div>
                    
                    <div className="flex justify-between items-center space-x-2">
                        {/* <p className={`items-center relative flex justify-center
                        ${ tax.active ? "bg-green-500" : "bg-red-500"}
                        rounded-md text-white p-1 h-8`} >{tax.active ? "Enabled" : "Disabled"}</p>
                         */}
                        <FaPercentage size="45" className="items-center relative flex justify-center"/>
                    </div>
                </div>
            }
            

            { editMode ?
                <TaxEdit tax={tax} setEditMode={setEditMode} storeID={storeID} deleteable={deleteable}/>
                :
                <TaxInfo tax={tax} modifyUser={modifyUser} />
            }
        </div>
    );
}


interface FormProps {
    handleSubmit(e: any): void,
    onChange(e: any): void,
    onCheckChange(e: any): void,
    setEditMode(state: boolean): void,
    handleDelete?(): void,
    copy: Tax,
    isLoading: boolean,
    isError: boolean,
    storeID: number
}

const TaxForm: React.FC<FormProps> = ({handleSubmit, onChange, onCheckChange, setEditMode, handleDelete, copy, isLoading, isError, storeID}) => {

    return (
        <form onSubmit={handleSubmit}>
            <FormInput label="Code"
            type="text" required={true} pattern="^(?! *$)[A-Za-z0-9\(\)\- .]+$" errorMessage="An code is required. (No special characters)" placeholder="HST"
            name="code" value={copy.code} onChange={onChange}/>

            <FormInput label="Description"
            type="text" required={true} pattern="^(?! *$)[A-Za-z0-9\(\)\- .]+$" errorMessage="An description is required. (No special characters)" placeholder="13 percent tax"
            name="description" value={copy.description} onChange={onChange}/>

            <div className="flex justify-between items-center space-x-2">
                <FormInput label="Tax rate (%)"
                type="number" required={true} pattern="^(?! *$)[A-Za-z0-9\- .]+$" errorMessage="An tax rate is required, if zero enter 0. (Ex. 13.0)" placeholder="13.0"
                name="percentage" value={copy.percentage} onChange={onChange}/>

                <span>+</span>

                <FormInput label="Fixed Amount ($)"
                type="number" required={true} pattern="^(?! *$)[A-Za-z0-9\- .]+$" errorMessage="Invalid format" placeholder="0"
                name="fixedAmount" value={copy.fixedAmount} onChange={onChange}/>
            </div>
            

            <FormCheckbox className="mb-4 flex space-x-2" label="Tax included in Item Price"
            name="includePrice" checked={copy.includePrice} onChange={onCheckChange} />

            <div className="flex justify-between">
                <IoArrowBack size="28" className="items-center relative flex justify-center mt-1
                    hover:text-white transition-all duration-300 ease-linear cursor-pointer"
                    onClick={() => setEditMode(false)}/>

                { handleDelete &&
                    <div className="flex flex-col items-center">
                        <p className="text-sm">Delete</p>
                        <FaTrashAlt size="28" className="items-center relative flex justify-center
                        hover:text-red-600 transition-all duration-300 ease-linear cursor-pointer"
                        onClick={() => handleDelete()}/>
                    </div>
                }

                {
                    isLoading ?
                    <button className="bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" disabled>
                        Processing...
                    </button>
                    :
                    isError ?
                    <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                        Error
                    </button>
                    :
                    <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                    Save
                    </button>
                }
            </div>
        </form>
    );
}

const TaxInfo: React.FC<{ tax: Tax, modifyUser:string }> = ({ tax, modifyUser }) => {


    return(
        <div>
            <p className="text-sm text-gray-700 -my-1">
                <span className="font-bold">Description: </span>
                {tax.description}
            </p>
            <p className="text-sm text-gray-700 -my-1">
                <span className="font-bold">Tax rate: </span>
                {tax.percentage}%
            </p>
            <p className="text-sm text-gray-700 -my-1">
                <span className="font-bold">Last modified: </span>
                {ReadableTime(tax.modifyDatetime)}
            </p>
            <p className="text-sm text-gray-700 -my-1">
                <span className="font-bold">Company managed: </span>
                {tax.isCompanyData ? "Yes" : "No"}
            </p>
        </div>
    );
}


const TaxEdit: React.FC<{ tax: Tax, storeID: number, setEditMode: (state: boolean) => void, deleteable:boolean }> = ({ tax, setEditMode, storeID, deleteable }) => {

    const queryClient = useQueryClient();
    const { mutateAsync, isLoading, isError } = UpdateStoreTax(queryClient, storeID);
    const { mutateAsync: deleteAsync, isLoading: isDeleting } = DeleteStoreTax(queryClient, storeID)
    const [copy, setCopy] = useState(tax);

    const onChange = (e:any) => {
        setCopy({...copy, [e.target.name]: e.target.value})
    }
    const onCheckChange = (e:any) => {
        setCopy({...copy, [e.target.name]: e.target.checked})
        console.log(copy);
    }
    const handleSubmit = async (event: any) => {
        event.preventDefault();

        try {
            let result = await mutateAsync(copy);

            if(result){
                setEditMode(false);
            }

        } catch (e) {
            console.error(e);
        }
    }
    const handleDelete = async () => {
        try {
            let result = await deleteAsync(copy);

            // if(result){
            //     setEditMode(false);
            // }

        } catch (e) {
            console.error(e);
        }
    }

    return(
        <TaxForm handleSubmit={handleSubmit} onChange={onChange} onCheckChange={onCheckChange} setEditMode={setEditMode} handleDelete={deleteable ? handleDelete : undefined} copy={copy} isLoading={isLoading} isError={isError} storeID={storeID} />
    );
}

export const TaxNew: React.FC<{ storeID: number, }> = ({ storeID, }) => {
    const [editMode, setEditMode] = useState(false);

    const queryClient = useQueryClient();
    const { mutateAsync, isLoading, isError } = CreateStoreTax(queryClient, storeID);

    // useEffect(() => {
    //     resetEditMode(false);
    // }, [departmentGuid])

    const template = {
        code: "",
        description: "",
        active: true,
        modifyUser: "00000000-0000-0000-0000-000000000000",
        modifyDatetime: new Date(),
        syncGuid: "00000000-0000-0000-0000-000000000000", //Placeholder - replaced by server
        isCompanyData: false,
        percentage: 0,
        fixedAmount: 0,
        includePrice: false,
        backgroundColor: "",
        printChar: ""
      };

    const [copy, setCopy] = useState<Tax>(template);

    const resetEditMode = (state: boolean) => {
        setEditMode(state);
        setCopy(template);
    }

    const onChange = (e:any) => {
        setCopy({...copy, [e.target.name]: e.target.value})
        console.log(copy);
    }
    const onCheckChange = (e:any) => {
        setCopy({...copy, [e.target.name]: e.target.checked})
        console.log(copy);
    }


    const handleSubmit = async (event: any) => {
        event.preventDefault();

        try {
            let result = await mutateAsync(copy);

            if(result){
                resetEditMode(false);
            }

        } catch (e) {
            console.error(e);
        }
    }

    return (
        <div className={`relative flex rounded-2xl px-4 py-2 justify-center bg-slate-300
                transition-all duration-300 ease-linear ${editMode ? "" : "cursor-pointer hover:bg-slate-600 hover:text-white" }`}
                onClick={() => !editMode && setEditMode(true)}>


                { !editMode ?
                    <IoAddCircleOutline size="28"/>
                    :
                    <TaxForm handleSubmit={handleSubmit} onChange={onChange} onCheckChange={onCheckChange} setEditMode={resetEditMode} copy={copy} isLoading={isLoading} isError={isError} storeID={storeID} />
                }
                
            </div>
    );
}