import { useState } from "react";
import { Department } from "../../../Model/POSModel/Department";
import { FaBuilding, FaPen, FaTrashAlt } from "react-icons/fa";
import FormInput from "../../../Components/FormInput";
import { IoAddCircleOutline, IoArrowBack } from "react-icons/io5";
import { useQueryClient } from "react-query";
import { CreateStoreDepartment, DeleteStoreDepartment, UpdateStoreDepartment } from "../../../Services/Requests";
import { ReadableTime } from "../../../Helper/ConversionHelper";
import FormCheckbox from "../../../Components/FormCheckbox";
import FormTime from "../../../Components/FormTime";

const DepartmentInfo: React.FC<{ department: Department, modifyUser:string }> = ({ department, modifyUser }) => {


    return(
        <div>
            {/* <p className="text-sm text-gray-700 -my-1">{department.name}</p> */}
            <p className="text-sm text-gray-700 -my-1">
                <span className="font-bold">Code: </span>
                {department.code}
            </p>
            {/* <p className="text-sm text-gray-700 -my-1">{department.active}</p> */}
            <p className="text-sm text-gray-700 -my-1">
                <span className="font-bold">Modify user: </span>
                {modifyUser}
            </p>
            <p className="text-sm text-gray-700 -my-1">
                <span className="font-bold">Last modified: </span>
                {ReadableTime(department.modifyDatetime)}
            </p>
            <p className="text-sm text-gray-700 -my-1">
                <span className="font-bold">Ext summary: </span>
                {department.noTaxSummary ? "Yes" : "No"}
            </p>
            <p className="text-sm text-gray-700 -my-1">
                <span className="font-bold">Company managed: </span>
                {department.isCompanyData ? "Yes" : "No"}
            </p>
            <p className="text-sm text-gray-700 -my-1">
                <span className="font-bold">Using changeable tax: </span>
                {department.usingChangeableTax ? "Yes" : "No"}
            </p>
            <p className="text-sm text-gray-700 -my-1">
                <span className="font-bold">Base total amount: </span>
                {department.baseTotalAmount}
            </p>
            <p className="text-sm text-gray-700 -my-1">
                <span className="font-bold">Change Tax ID: </span>
                {department.changeTaxID}
            </p>
            <p className="text-sm text-gray-700 -my-1">
                <span className="font-bold">No Summary: </span>
                {department.noSummary  ? "Yes" : "No"}
            </p>
            <p className="text-sm text-gray-700 -my-1">
                <span className="font-bold">Use item options: </span>
                {department.useItemOptions ? "Yes" : "No"}
            </p>
            {/* <p className="text-sm text-gray-700 -my-1">{department.itemOptions}</p> */}
            <p className="text-sm text-gray-700 -my-1">
                <span className="font-bold">Use time check: </span>
                {department.useTimeCheck ? "Yes" : "No"}
            </p>
            {department.startTime && department.useTimeCheck &&
                <p className="text-sm text-gray-700 -my-1">
                    <span className="font-bold">Start Time: </span>
                    {ReadableTime(department.startTime)}
                </p>
            }
            {department.endTime && department.useTimeCheck &&
                <p className="text-sm text-gray-700 -my-1">
                    <span className="font-bold">End Time: </span>
                    {ReadableTime(department.endTime)}
                </p>
            }
            {/* <div className="flex justify-between mt-2 items-end">
                <p className="text-sm text-gray-700">POS Machines: {storeData.posSystems.length}</p>
                { storeData.store.status !== StoreStatus.Active &&
                <div className="flex flex-col items-center">
                    <p className="text-sm">Delete Store</p>
                    <FaStoreSlash size="28" className="items-center relative flex justify-center
                    hover:text-red-600 transition-all duration-300 ease-linear cursor-pointer"
                    onClick={() => handleDelete()}/>
                </div>
                }
            </div> */}
        </div>
    );
}


const DepartmentEdit: React.FC<{ department: Department, storeID: number, setEditMode: (state: boolean) => void }> = ({ department, setEditMode, storeID }) => {

    const queryClient = useQueryClient();
    const { mutateAsync, isLoading, isError } = UpdateStoreDepartment(queryClient, storeID);
    const { mutateAsync: deleteAsync, isLoading: isDeleting } = DeleteStoreDepartment(queryClient, storeID)
    const [copy, setCopy] = useState(department);

    const onChange = (e:any) => {
        setCopy({...copy, [e.target.name]: e.target.value})
    }
    const onCheckChange = (e:any) => {
        setCopy({...copy, [e.target.name]: e.target.checked})
        console.log(copy);
    }
    const handleSubmit = async (event: any) => {
        event.preventDefault();

        try {
            let result = await mutateAsync(copy);

            if(result){
                setEditMode(false);
            }

        } catch (e) {
            console.error(e);
        }
    }
    const handleDelete = async () => {
        try {
            let result = await deleteAsync(copy);

            // if(result){
            //     setEditMode(false);
            // }

        } catch (e) {
            console.error(e);
        }
    }

    return(
        <form onSubmit={handleSubmit}>
            <FormInput className="mb-4" label="Name"
            type="text" required={true} pattern="^(?! *$)[A-Za-z0-9\- .]+$" errorMessage="An name is required. (No special characters)" placeholder="Grocery"
            name="name" value={copy.name} onChange={onChange}/>

            <FormInput className="mb-4" label="Code"
            type="text" required={true} pattern="^(?! *$)[A-Za-z0-9\- .]+$" errorMessage="An code is required. (No special characters)" placeholder="D1"
            name="code" value={copy.code} onChange={onChange}/>

            <FormCheckbox className="mb-4 flex space-x-2" label="Ext summary"
            name="noTaxSummary" checked={copy.noTaxSummary} onChange={onCheckChange}  />

            <FormCheckbox className="mb-4 flex space-x-2" label="No Summary"
            name="noSummary" checked={copy.noSummary} onChange={onCheckChange}  />

            {/* <FormCheckbox className="mb-4 flex space-x-2" label="Changeable Tax Options"
            name="usingChangeableTax" value={copy.usingChangeableTax} onChange={onChange}  /> */}

            {/* <FormCheckbox className="mb-4 flex space-x-2" label="Use Group Item Options"
            name="useItemOptions" value={copy.useItemOptions} onChange={onChange}  /> */}

            <FormCheckbox className="mb-4 flex space-x-2" label="Deposit included in item price"
            name="includeDeposit" checked={copy.includeDeposit} onChange={onCheckChange}  />

            <FormCheckbox className="mb-4 flex space-x-2" label="Use time for sale"
            name="useTimeCheck" checked={copy.useTimeCheck} onChange={onCheckChange}  />

            {   copy.useTimeCheck &&
                <FormTime className="mb-4" label="Start Time (24h)"
                name="startTime" initialDateUTC={copy.startTime} onChange={onChange}/>
            }

            {   copy.useTimeCheck &&
                <FormTime className="mb-4" label="End Time (24h)"
                name="endTime" initialDateUTC={copy.endTime} onChange={onChange}/>
            }


            {/* <div className="mb-8">
                <label className="block text-gray-700 text-sm font-bold mb-2">Province</label>
                <select name="province" value={copy.province} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
                    focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    onChange={onChange} required
                >
                    <option value="">Select an option</option>
                    <option value="Ontario">Ontario</option>
                    <option value="British Columbia">British Columbia</option>
                </select>
            </div> */}
            <div className="flex justify-between">
                <IoArrowBack size="28" className="items-center relative flex justify-center mt-1
                hover:text-white transition-all duration-300 ease-linear cursor-pointer"
                onClick={() => setEditMode(false)}/>

                <div className="flex flex-col items-center">
                    <p className="text-sm">Delete</p>
                    <FaTrashAlt size="28" className="items-center relative flex justify-center
                    hover:text-red-600 transition-all duration-300 ease-linear cursor-pointer"
                    onClick={() => handleDelete()}/>
                </div>

                {
                    isLoading ?
                    <button className="bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" disabled>
                        Processing...
                    </button>
                    :
                    isError ?
                    <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                        Error
                    </button>
                    :
                    <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                    Save
                    </button>
                }
            </div>
        </form>
    );
}

export const DepartmentCard: React.FC<{ department: Department, storeID:number, isSelected:boolean, onSelectDept: (dept: Department) => void, modifyUser:string, isOrgStore?:boolean }> = ({ department, storeID, isSelected, onSelectDept, modifyUser, isOrgStore = false }) => {

    const [editMode, setEditMode] = useState(false);

    return(
        <div className={`bg-slate-300 rounded-2xl px-4 pt-2 pb-4 ${isSelected ? "border-2 bg-opacity-100" : "cursor-pointer bg-opacity-75 hover:bg-opacity-100"} transition-all duration-300 ease-linear border-black`}
            onClick={() => {
                onSelectDept(department)
            }}
        >
            {   !editMode &&
                <div className="flex justify-between items-center">
                    <div className="flex justify-between items-center space-x-2">
                        <h2 className="font-bold text-lg">{department.name}</h2>
                        {isOrgStore === department.isCompanyData && <FaPen size="18" className="items-center relative flex justify-center
                        hover:text-orange-500 transition-all duration-300 ease-linear cursor-pointer"
                        onClick={() => setEditMode(true)}/>}
                    </div>
                    
                    <div className="flex justify-between items-center space-x-2">
                        <p className={`items-center relative flex justify-center
                        ${ department.active ? "bg-green-500" : "bg-red-500"}
                        rounded-md text-white p-1 h-8`} >{department.active ? "Enabled" : "Disabled"}</p>
                        
                        <FaBuilding size="45" className="items-center relative flex justify-center"/>
                    </div>
                </div>
            }
            

            { editMode ?
                <DepartmentEdit department={department} setEditMode={setEditMode} storeID={storeID}/>
                :
                <DepartmentInfo department={department} modifyUser={modifyUser} />
            }
        </div>
    );
}

export const DepartmentNew: React.FC<{ storeID: number }> = ({ storeID }) => {
    const [editMode, setEditMode] = useState(false);

    const queryClient = useQueryClient();
    const { mutateAsync, isLoading, isError } = CreateStoreDepartment(queryClient, storeID);

    const template = {
        name: "",
        code: "",
        active: true,
        modifyUser: "00000000-0000-0000-0000-000000000000",
        modifyDatetime: new Date(),
        noTaxSummary: false,
        syncGuid: "00000000-0000-0000-0000-000000000000", //Placeholder - replaced by server
        isCompanyData: false,
        usingChangeableTax: false,
        baseTotalAmount: 0,
        changeTaxID: "00000000-0000-0000-0000-000000000000",
        noSummary: false,
        useItemOptions: false,
        itemOptions: "",
        useTimeCheck: false,
        messageID: "00000000-0000-0000-0000-000000000000",
        startTime: new Date(Date.UTC(2023, 5, 25, 14, 30)),
        endTime: new Date(Date.UTC(0, 0, 0, 0, 0, 0)),
        includeDeposit: false
      };

    const [copy, setCopy] = useState<Department>(template);

    const resetEditMode = (state: boolean) => {
        setEditMode(state);
        setCopy(template);
    }

    const onChange = (e:any) => {
        setCopy({...copy, [e.target.name]: e.target.value})
        console.log(copy);
    }
    const onCheckChange = (e:any) => {
        setCopy({...copy, [e.target.name]: e.target.checked})
        console.log(copy);
    }


    const handleSubmit = async (event: any) => {
        event.preventDefault();

        try {
            let result = await mutateAsync(copy);

            if(result){
                resetEditMode(false);
            }

        } catch (e) {
            console.error(e);
        }
    }

    return (
        <div className={`relative flex rounded-2xl px-4 py-2 justify-center bg-slate-300
                transition-all duration-300 ease-linear ${editMode ? "" : "cursor-pointer hover:bg-slate-600 hover:text-white" }`}
                onClick={() => !editMode && setEditMode(true)}>


                { !editMode ?
                    <IoAddCircleOutline size="28"/>
                    :
                    <form onSubmit={handleSubmit}>
                        <FormInput className="mb-4" label="Name"
                        type="text" required={true} pattern="^(?! *$)[A-Za-z0-9\- .]+$" errorMessage="An name is required. (No special characters)" placeholder="Grocery"
                        name="name" value={copy.name} onChange={onChange}/>

                        <FormInput className="mb-4" label="Code"
                        type="text" required={true} pattern="^(?! *$)[A-Za-z0-9\- .]+$" errorMessage="An code is required. (No special characters)" placeholder="D1"
                        name="code" value={copy.code} onChange={onChange}/>

                        <FormCheckbox className="mb-4 flex space-x-2" label="Ext summary"
                        name="noTaxSummary" checked={copy.noTaxSummary} onChange={onCheckChange}  />

                        <FormCheckbox className="mb-4 flex space-x-2" label="No Summary"
                        name="noSummary" checked={copy.noSummary} onChange={onCheckChange}  />

                        {/* <FormCheckbox className="mb-4 flex space-x-2" label="Changeable Tax Options"
                        name="usingChangeableTax" value={copy.usingChangeableTax} onChange={onChange}  /> */}

                        {/* <FormCheckbox className="mb-4 flex space-x-2" label="Use Group Item Options"
                        name="useItemOptions" value={copy.useItemOptions} onChange={onChange}  /> */}

                        <FormCheckbox className="mb-4 flex space-x-2" label="Deposit included in item price"
                        name="includeDeposit" checked={copy.includeDeposit} onChange={onCheckChange}  />

                        <FormCheckbox className="mb-4 flex space-x-2" label="Use time for sale"
                        name="useTimeCheck" checked={copy.useTimeCheck} onChange={onCheckChange}  />

                        {   copy.useTimeCheck &&
                            <FormTime className="mb-4" label="Start Time (24h)"
                            name="startTime" initialDateUTC={copy.startTime} onChange={onChange}/>
                        }

                        {   copy.useTimeCheck &&
                            <FormTime className="mb-4" label="End Time (24h)"
                            name="endTime" initialDateUTC={copy.endTime} onChange={onChange}/>
                        }

                        {/* <div className="mb-8">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Province</label>
                            <select name="province" value={copy.province} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
                                focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                onChange={onChange} required
                            >
                                <option value="">Select an option</option>
                                <option value="Ontario">Ontario</option>
                                <option value="British Columbia">British Columbia</option>
                            </select>
                        </div> */}
                        <div className="flex justify-between">
                        <IoArrowBack size="28" className="items-center relative flex justify-center mt-1
                            hover:text-white transition-all duration-300 ease-linear cursor-pointer"
                            onClick={() => resetEditMode(false)}/>

                            {
                                isLoading ?
                                <button className="bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" disabled>
                                    Processing...
                                </button>
                                :
                                isError ?
                                <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                                    Error
                                </button>
                                :
                                <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                                Save
                                </button>
                            }
                        </div>
                    </form>
                }
                
            </div>
    );
}