import { useEffect, useState } from "react";
import { Store } from "../../../Model/Store";
import { CreateStoreSupplier, DeleteStoreSupplier, GetStoreSuppliers, GetSupplierItems, GetStoreSupplierView, UpdateStoreSupplier, GetStoreCurrencies } from "../../../Services/Requests";
import { ReadableDate, ReadableMoney, ReadableTime } from "../../../Helper/ConversionHelper";
import FormInput from "../../../Components/FormInput";
import { FaPen, FaTimes, FaTrashAlt } from "react-icons/fa";
import PageNavigator from "../../../Components/PageNavigator";
import { Supplier, SupplierFilter, SupplierInjestPackage, SupplierView, Template } from "../../../Model/POSModel/Supplier";
import { ConvertDiscountType } from "../../../Helper/EnumToString";
import FormCheckbox from "../../../Components/FormCheckbox";
import SupplierDetails, { ItemSearchWindow, BasketEditWindow, EditSupplierWindow } from "../Components/SupplierDetails";
import { ItemPreview } from "../../../Model/POSModel/Item";
import { useQueryClient } from "react-query";

export const SupplierPage: React.FC<{ store:Store, isOrgStore:boolean }> = ({ store, isOrgStore = false }) => {

    const template = {
        chunkSize: 100,
        page: 1,
        search: ""
    };
    const [supplierFilter, setSupplier] = useState<SupplierFilter>(template);
    const [selectedDiscount, setSelectedDiscount] = useState<SupplierView | undefined>();
    const [editingDiscount, setEditingDiscount] = useState<Supplier | undefined>();
    const [editingBasket, setEditingBasket] = useState<ItemPreview[] | undefined>();
    const {data:currencyData} = GetStoreCurrencies(store.id);

    // const { isLoading:isLoadingRegisters, data:registerData } = GetStoreRegisters(store.id);
    // const { isLoading:isLoadingUsers, data:userData } = GetStoreEmployees(store.id);
    const { isLoading, data } = GetStoreSupplierView(store.id, supplierFilter);

    const HandleSetPageInfo = (page:number, chunkSize: number) => {
        setSupplier({...supplierFilter, page: page, chunkSize: chunkSize});
    }

    const HandleWindowExit = () => {
        if(editingBasket){
            setEditingDiscount(undefined);
            setEditingBasket(undefined);
        }else{
            setSelectedDiscount(undefined);
        }
    }

    const HandleSetFilter = (filter: SupplierFilter) => {
        setSupplier({...filter, page: 1, chunkSize: supplierFilter.chunkSize});
    }

    const HandleNewDiscount = () => {
        handleEditClick(Template, []);
    }

    const handleEditClick = (discount: Supplier, basket: ItemPreview[]) => {
        setEditingDiscount(discount);
        setEditingBasket(basket);
    }

    const HandleRemoveItemFromBasket = (itemIndex: number) => {
        if(editingBasket){
            setEditingBasket(editingBasket.filter((_, j) => j !== itemIndex));
        }
    }
    const HandleAddItemToBasket = (item: ItemPreview) => {
        if(editingBasket){
            setEditingBasket([...editingBasket, item])
        }
    }

    useEffect(() => {
        if(selectedDiscount && data){
            const discount = data.suppliers.find((x) => x.syncGuid === selectedDiscount.syncGuid);
            if(discount){
                setSelectedDiscount(discount);
            }
        }
    }, [data, selectedDiscount]);

    return(<div>
        <div className="flex justify-evenly pt-6 px-2 flex-row h-screen space-x-2">
            {   editingDiscount && editingBasket ?
                <div className="flex justify-evenly flex-row h-screen space-x-2 pb-10 w-3/4">
                    <ItemSearchWindow storeID={store.id} discount={editingDiscount} basket={editingBasket} addItemToBasket={HandleAddItemToBasket}/>
                    <BasketEditWindow storeID={store.id} discount={editingDiscount} basket={editingBasket} removeItemFromBasket={HandleRemoveItemFromBasket}/>
                </div>
                :
                <div className="bg-offWhite shadow-xl rounded-3xl px-5 pb-8 pt-4 mb-4 flex flex-col w-full">
                    <div className="flex justify-between items-center">
                            <h1 className='font-bold text-xl'>Suppliers</h1>
                            {/* <button onClick={() => setSearchVisible(true)}>
                                <FaSearch />
                            </button> */}
                    </div>

                    <FilterComponent filter={supplierFilter} setFilter={HandleSetFilter} handleNewDiscount={HandleNewDiscount}/>

                    <div className="flex flex-row justify-between my-2">
                        <p className='text-sm'>Click on a supplier to view its details.</p>

                        <PageNavigator totalCount={data?.totalCount} currentPage={supplierFilter.page} chunkSize={supplierFilter.chunkSize} setPageInfo={HandleSetPageInfo}/>
                    </div>
                    
                    <div className="relative overflow-y-auto h-full flex flex-col items-center">
                    <table className="w-full table-auto">
                    <thead className="bg-slate-400 sticky top-0">
                        <tr>
                        <th>Code</th>
                        <th>Name</th>
                        <th>Contact</th>
                        <th>Phone</th>
                        <th>Fax</th>
                        <th>Last Updated</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                        !isLoading && data && currencyData && data.suppliers.map((discount) => {
                            return (<tr key={discount.syncGuid} className={`${selectedDiscount && selectedDiscount.syncGuid === discount.syncGuid ? "bg-slate-500" : "even:bg-offWhite odd:bg-slate-300"}
                                    border-b border-gray-700 ${"hover:bg-slate-600 cursor-pointer"}`}
                                    onClick={() => setSelectedDiscount(discount)}
                                    >
                            <td>
                                <div className="flex justify-center">
                                {discount.code}
                                </div>
                            </td>
                            <td>
                                <div className="flex justify-center">
                                {discount.name}
                                </div>
                            </td>
                            <td>
                                <div className="flex justify-center">
                                {discount.contact}
                                </div>
                            </td>
                            <td>
                                <div className="flex justify-center">
                                {discount.phone}
                                </div>
                            </td>
                            <td>
                                <div className="flex justify-center">
                                {discount.fax}
                                </div>
                            </td>
                            <td>
                                <div className="flex justify-center">
                                {ReadableTime(discount.lastUpdated)}
                                </div>
                            </td>
                            </tr>)
                        })
                        }
                    </tbody>
                    </table>
                    {isLoading && <span className="mt-2">Loading...</span>}
                    </div>
                </div>
            }

            {   currencyData &&
                (editingDiscount ?
                editingBasket && <EditSupplierWindow storeID={store.id} editingDiscount={editingDiscount} editingBasket={editingBasket} currencyData={currencyData} onWindowExit={HandleWindowExit} setEditingDiscount={setEditingDiscount}/>
                :
                selectedDiscount && <SupplierDetails selectedSupplier={selectedDiscount} storeID={store.id} currencyData={currencyData} onWindowExit={HandleWindowExit} isOrgStore={isOrgStore} handleEditClick={handleEditClick}/>)
            }
        </div>
      </div>
    );
}

const FilterComponent: React.FC<{ filter:SupplierFilter, setFilter(filter:SupplierFilter):void, handleNewDiscount():void }> = ({ filter, setFilter, handleNewDiscount }) => {

    const [copy, setCopy] = useState(filter);

    const onChange = (e:any) => {
        setCopy({...copy, [e.target.name]: e.target.value})
        //console.log(copy);
    }

    const onSubmit = (e:any) => {
        e.preventDefault();
        setFilter(copy);
    }

    return (
        <div>
            <form onSubmit={onSubmit} className="space-y-2">
                <div className="grid grid-cols-2 gap-4">
                    <FormInput label="Name" placeholder="Imperial Tobacco"
                    type="text" required={false} pattern="^(?! *$)[A-Za-z0-9\$\*\- .]+$"
                    name="search" value={copy.search} onChange={onChange}/>
                </div>

                <div className="flex justify-between">
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                        Filter
                    </button>

                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={handleNewDiscount}>
                        New Supplier
                    </button>
                </div>
            </form>
        </div>
    );
}