import { useState } from "react";
import { Store } from "../../../Model/Store";
import { GetStoreDepartmentCategories, GetStoreDepartments, GetStoreEmployees } from "../../../Services/Requests";
import { DepartmentCard, DepartmentNew } from "../Components/DepartmentCard";
import { Department } from "../../../Model/POSModel/Department";
import { CategoryCard, CategoryNew } from "../Components/CategoryCard";
import { PosUser } from "../../../Model/POSModel/PosUser";
import { FindEmployeeName } from "../../../Helper/FinderHelper";

export const DepartmentPage: React.FC<{ store:Store, isOrgStore?:boolean }> = ({ store, isOrgStore = false }) => {

    const { isLoading, data } = GetStoreDepartments(store.id);
    const { isLoading:isLoadingUsers, data:userData } = GetStoreEmployees(store.id);
    const [selectedDept, setSelectedDept] = useState<Department | undefined>();

    return(
        <div className="flex justify-evenly pt-6 px-2 flex-row h-screen space-x-2">
            <div className="bg-offWhite shadow-xl rounded-3xl px-5 pb-8 mb-4 flex flex-col">
                <div className="mb-4 mt-4">
                    <div className="flex justify-between items-center">
                        <h1 className='font-bold text-xl'>Departments:</h1>
                        {/* <button onClick={() => setSearchVisible(true)}>
                            <FaSearch />
                        </button> */}
                    </div>
                    
                    <p className='text-sm'>Click on a department to view it's categories.</p>
                </div>
                <div className="bg-slate-400 rounded-lg overflow-y-auto space-y-7 p-3">

                    { !isLoading && !isLoadingUsers ?
                        data && userData && data.map((department) => {
                            return <DepartmentCard department={department}
                            storeID={store.id}
                            key={department.syncGuid}
                            onSelectDept={setSelectedDept}
                            isSelected={selectedDept?.syncGuid === department.syncGuid}
                            modifyUser={FindEmployeeName(department.modifyUser, userData)}
                            isOrgStore={isOrgStore}/>
                        })
                        :
                        <div>Loading...</div>
                    }

                    <DepartmentNew storeID={store.id} />
                </div>
            </div>

            { selectedDept && userData &&
                <CategoryView store={store} department={selectedDept} users={userData} isOrgStore={isOrgStore} />
            }

            
        </div>
    );
}

const CategoryView: React.FC<{ department:Department, store:Store, users:PosUser[], isOrgStore:boolean }> = ({ store, department, users, isOrgStore }) => {

    const { isLoading, data } = GetStoreDepartmentCategories(store.id, department.syncGuid);

    return (
        <div className="bg-offWhite shadow-xl rounded-3xl px-5 pb-8 mb-4 flex flex-col">
            <div className="mb-4 mt-4">
                <div className="flex justify-between items-center">
                    <h1 className='font-bold text-xl'>Categories:</h1>
                    {/* <button onClick={() => setSearchVisible(true)}>
                        <FaSearch />
                    </button> */}
                </div>
            </div>
            <div className="bg-slate-400 rounded-lg overflow-y-auto space-y-7 p-3">
                { !isLoading ?
                    data && data.map((category) => {
                        return <CategoryCard category={category}
                        storeID={store.id}
                        key={category.syncGuid}
                        modifyUser={FindEmployeeName(category.modifyUser, users)}
                        isOrgStore={isOrgStore}/>
                    })
                    :
                    <div>loading</div>
                }

                <CategoryNew storeID={store.id} departmentID={department.syncGuid} />
            </div>
        </div>
    );
}