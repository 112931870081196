import { Store } from "../../../Model/Store";
import { GetStoreEmployees, GetStoreTaxesUsed, GetStoreTaxes } from "../../../Services/Requests";
import { TaxCard, TaxNew } from "../Components/TaxCard";
import { FindEmployeeName } from "../../../Helper/FinderHelper";

export const TaxPage: React.FC<{ store:Store, isOrgStore?:boolean }> = ({ store, isOrgStore = false }) => {

    const { isLoading, data } = GetStoreTaxes(store.id);
    const { isLoading:isLoadingUsed, data:dataUsed } = GetStoreTaxesUsed(store.id);
    const { isLoading:isLoadingUsers, data:userData } = GetStoreEmployees(store.id);

    return(
        <div className="flex justify-evenly pt-6 px-2 flex-row h-screen space-x-2">
            <div className="bg-offWhite shadow-xl rounded-3xl px-5 pb-8 mb-4 flex flex-col">
                <div className="mb-4 mt-4">
                    <div className="flex justify-between items-center">
                        <h1 className='font-bold text-xl'>Taxes:</h1>
                        {/* <button onClick={() => setSearchVisible(true)}>
                            <FaSearch />
                        </button> */}
                    </div>
                    Taxes that have been used in a transaction cannot be deleted.
                </div>
                <div className="bg-slate-400 rounded-lg overflow-y-auto space-y-7 p-3">

                    { !isLoading && !isLoadingUsed && !isLoadingUsers ?
                        data && dataUsed && userData && data.map((tax) => {
                            return <TaxCard tax={tax} storeID={store.id} key={tax.syncGuid} modifyUser={FindEmployeeName(tax.modifyUser, userData)} deleteable={!dataUsed.includes(tax.syncGuid)} isOrgStore={isOrgStore}/>
                        })
                        :
                        <div>Loading...</div>
                    }

                    <TaxNew storeID={store.id} />
                </div>
            </div>            
        </div>
    );
}