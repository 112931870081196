import background from '../logo.svg';
import {useNavigate} from "react-router-dom";
import {useFusionAuth} from "@fusionauth/react-sdk";
import {useEffect} from "react";
import { CheckRegister } from '../Services/Requests';

function Home(){
  const navigate = useNavigate();

  const {isLoggedIn, isFetchingUserInfo} = useFusionAuth();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/');
    }
    else{
      navigate('/mystores');
    }
  }, [isLoggedIn, navigate]);

  const { isLoading: isLoadingCA } = CheckRegister();

  if (!isLoggedIn || isFetchingUserInfo || isLoadingCA) {
    return null;
  }

  return (
    <div className="column-container pl-2">
      <div className="content-container">
        <div style={{marginBottom: '100px'}}>
          <h1 className="text-3xl font-bold underline">Welcome to 1Cloud Home Page</h1>
        </div>
      </div>

      <div style={{flex: 0}}>
        <img src={background} style={{maxWidth: '800px'}} alt=""/>
      </div>
    </div>
  )
}
  
export default Home;
  