import { FaPen, FaPerson, FaPersonCircleMinus } from "react-icons/fa6";
import { useQueryClient } from "react-query";
import { Role } from "../Model/Roles";
import { ChangeRole, OrgMember } from "../Model/Transactional";
import { ChangeMemberRole, GetOrgVirtualStores, RemoveMember } from "../Services/Requests";
import { ConvertRole } from "../Helper/EnumToString";
import { useEffect, useState } from "react";
import { IoArrowBack } from "react-icons/io5";
import Select, { MultiValue } from 'react-select';
import { VirtualStore } from "../Model/VirtualStore";

export const LoadingOrgMemberCard: React.FC<{}> = () => {

    // const queryClient = useQueryClient();
    // const { mutateAsync:ChangeRoleAsync, isLoading:isChanging } = ChangeMemberRole(queryClient, orgID ?? -1)
    // const { mutateAsync:DeleteAsync, isLoading:isDeleting } = RemoveMember(queryClient, orgID ?? -1)

    // const onChange = async (e:any) => {
    //     if(!orgID || !orgMember)
    //         return;

    //     try {
    //         await ChangeRoleAsync({
    //             userID: orgMember.userID,
    //             orgID: orgID,
    //             role: Number(e.target.value), //It is nessessary to cast/parse to number b/c it will get sent as a string even though its an enum
    //             assignedVirtualStores: orgMember.assignedVirtualStores
    //         });
    //     } catch (e) {
    //         console.error(e);
    //     }
    // }

    // const onDelete = async (e:any) => {
    //     if(!orgID || !orgMember)
    //         return;

    //     try {
    //         await DeleteAsync({
    //             memberID: orgMember.userID,
    //             orgID: orgID
    //         });
    //     } catch (e) {
    //         console.error(e);
    //     }
    // }

    return(
        <div className={`bg-slate-300 bg-opacity-75 rounded-2xl px-4 py-2`}>
            <div className="animate-pulse space-y-8">
                <div className="h-2 bg-slate-700 rounded"></div>
                <div className="h-2 bg-slate-700 rounded"></div>
                <div className="h-2 bg-slate-700 rounded"></div>
                <div className="h-2 bg-slate-700 rounded"></div>
            </div>
        </div>
    );

    // if(!orgMember)
    //     return(
    //         <div className={`bg-slate-300 bg-opacity-75 rounded-2xl px-4 py-2`}>
    //             <div className="animate-pulse space-y-8">
    //                 <div className="h-2 bg-slate-700 rounded"></div>
    //                 <div className="h-2 bg-slate-700 rounded"></div>
    //                 <div className="h-2 bg-slate-700 rounded"></div>
    //                 <div className="h-2 bg-slate-700 rounded"></div>
    //             </div>
    //         </div>
    //     )
    // else
    //     return(
    //         <div className={`bg-slate-300 bg-opacity-75 rounded-2xl px-4 py-2`}>
    //                 <div>
    //                     <div className="flex justify-between">
    //                         <h2 className="font-bold">{orgMember.name}</h2>
    //                         <FaPerson size="28" className="items-center relative flex justify-center mt-1"/>
    //                     </div>
    //                     <p className="text-sm text-gray-700">{orgMember.email}</p>
    //                     <p className="text-sm text-gray-700">{orgMember.phone}</p>
    //                     { orgID &&
    //                         <div>
    //                             { (clientRole === Role.Admin || clientRole === Role.Owner) ?
    //                                 <div className="flex justify-between items-center">
    //                                     <select name="accountType" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
    //                                     focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
    //                                     onChange={onChange} value={orgMember.role} disabled={isChanging || (clientRole === Role.Admin && orgMember.role === Role.Owner)}
    //                                     >
    //                                         <option value={Role.StoreOwner}>{ConvertRole(Role.StoreOwner)}</option>
    //                                         {/* <option value={Role.Accountant}>Accountant</option> */}
    //                                         <option value={Role.Admin}>{ConvertRole(Role.Admin)}</option>
    //                                         <option value={Role.Owner} disabled={clientRole === Role.Admin}>{ConvertRole(Role.Owner)}</option>
    //                                     </select>
    //                                     { orgMember.role !== Role.Owner && <div className="flex flex-col items-center">
    //                                         <p className="text-sm">Remove Member</p>
    //                                         <FaPersonCircleMinus size="28" className="items-center relative flex justify-center
    //                                         hover:text-red-600 transition-all duration-300 ease-linear cursor-pointer"
    //                                         onClick={onDelete}/>
    //                                     </div>}
    //                                 </div>
    //                                 :
    //                                 <p className="text-sm font-semibold text-gray-700">{ConvertRole(orgMember.role)}</p>
    //                             }
                                
    //                         </div>
    //                     }
    //                 </div>
    //         </div>
    //     )
}

interface CardProps {
    orgMember: OrgMember; clientRole?: Role; orgID: number
}
export const OrgMemberCard: React.FC<CardProps> = ({ orgMember, clientRole, orgID }) => {

    const [editMode, setEditMode] = useState(false);
    const {data} = GetOrgVirtualStores(orgID);

    return(
        <div className={`bg-slate-300 rounded-2xl px-4 pt-2 pb-4 transition-all duration-300 ease-linear border-black`}>
            {   !editMode &&
                <div className="flex justify-between items-center">
                    <div className="flex justify-between items-center space-x-2">
                        <h2 className="font-bold text-lg">{orgMember.name}</h2>
                        { (clientRole === Role.Owner || clientRole === Role.Admin) &&
                            <FaPen size="18" className="items-center relative flex justify-center
                        hover:text-orange-500 transition-all duration-300 ease-linear cursor-pointer"
                        onClick={() => setEditMode(true)}/>
                        }
                    </div>
                    
                    <div className="flex justify-between items-center space-x-2">
                        {/* <p className={`items-center relative flex justify-center
                        ${ category.active ? "bg-green-500" : "bg-red-500"}
                        rounded-md text-white p-1 h-8`} >{category.active ? "Enabled" : "Disabled"}</p> */}
                        
                        <FaPerson size="28" className="items-center relative flex justify-center mt-1"/>
                    </div>
                </div>
            }
            

            { (editMode && data) ?
                <OrgMemberEdit orgMember={orgMember} setEditMode={setEditMode} orgID={orgID} clientRole={clientRole} virtualStores={data} />
                :
                <OrgMemberInfo orgMember={orgMember} />
            }
        </div>
    );
}


interface FormProps {
    handleSubmit(e: any): void,
    onChange(e: any): void,
    onCheckChange(e: any): void,
    setEditMode(state: boolean): void,
    handleDelete(): void,
    setCopy(copy: ChangeRole): void,
    copy: ChangeRole,
    orgMem: OrgMember,
    isLoading: boolean,
    isError: boolean,
    clientRole?: Role,
    virtualStores: VirtualStore[]
}

interface StoreOption {
    value: number;
    label: string;
}

const OrgMemberForm: React.FC<FormProps> = ({handleSubmit, onChange, onCheckChange, setEditMode, handleDelete, setCopy, copy, isLoading, isError, orgMem, clientRole, virtualStores}) => {

    const [stores, setStores] = useState<StoreOption[]>();
    useEffect(() => {
        let selectedStores = virtualStores.filter((store) => copy.assignedVirtualStores.includes(store.id));

        setStores(selectedStores.map((store) => ({
            value: store.id,
            label: store.name,
        })));
    }, [copy.assignedVirtualStores]);

    const clerkOptions: StoreOption[] = virtualStores.map((store) => ({
        value: store.id,
        label: store.name,
    }));
    const handleClerkChange = (options: MultiValue<StoreOption>) => {
        setCopy({ ...copy, assignedVirtualStores: options.map((value) => value.value) });
    };

    return (
        <form onSubmit={handleSubmit}>         
            <label className="block text-gray-700 text-sm font-bold mb-2">Role:</label>   
            <select name="role" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
            focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            onChange={onChange} value={copy.role}
            >
                <option value={Role.StoreOwner}>{ConvertRole(Role.StoreOwner)}</option>
                {/* <option value={Role.Accountant}>Accountant</option> */}
                <option value={Role.Admin}>{ConvertRole(Role.Admin)}</option>
                <option value={Role.Owner} disabled={clientRole === Role.Admin}>{ConvertRole(Role.Owner)}</option>
            </select>

            <div className="mb-2">
                <label className="block text-gray-700 text-sm font-bold mb-2">Assigned Virtual Stores:</label>
                <Select
                isMulti
                options={clerkOptions}
                value={stores}
                onChange={handleClerkChange}
                />
            </div>

            <div className="flex justify-between">
                <IoArrowBack size="28" className="items-center relative flex justify-center mt-1
                    hover:text-white transition-all duration-300 ease-linear cursor-pointer"
                    onClick={() => setEditMode(false)}/>


                { orgMem.role !== Role.Owner && <div className="flex flex-col items-center">
                    <p className="text-sm">Remove Member</p>
                    <FaPersonCircleMinus size="28" className="items-center relative flex justify-center
                    hover:text-red-600 transition-all duration-300 ease-linear cursor-pointer"
                    onClick={handleDelete}/>
                </div>}

                {
                    isLoading ?
                    <button className="bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" disabled>
                        Processing...
                    </button>
                    :
                    isError ?
                    <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                        Error
                    </button>
                    :
                    <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                    Save
                    </button>
                }
            </div>
        </form>
    );
}

const OrgMemberInfo: React.FC<{ orgMember: OrgMember }> = ({ orgMember }) => {
    return(
        <div>
            <p className="text-sm text-gray-700">{orgMember.email}</p>
            <p className="text-sm text-gray-700">{orgMember.phone}</p>
            <p className="text-sm font-semibold text-gray-700">{ConvertRole(orgMember.role)}</p>
        </div>
    );
}

interface EditProps {
    orgMember: OrgMember,
    orgID: number,
    clientRole?: Role,
    setEditMode: (state: boolean) => void,
    virtualStores: VirtualStore[]
}

const OrgMemberEdit: React.FC<EditProps> = ({ orgMember, orgID, setEditMode, clientRole, virtualStores }) => {

    const queryClient = useQueryClient();
    const { mutateAsync, isLoading, isError } = ChangeMemberRole(queryClient, orgID)
    const { mutateAsync:deleteAsync } = RemoveMember(queryClient, orgID)
    const [copy, setCopy] = useState<ChangeRole>({
        userID: orgMember.userID,
        orgID: orgID,
        role: Number(orgMember.role), //It is nessessary to cast/parse to number b/c it will get sent as a string even though its an enum
        assignedVirtualStores: [...orgMember.attachedVirtualStores]
    });

    const onChange = (e:any) => {
        setCopy({...copy, [e.target.name]: e.target.value})
    }
    const onCheckChange = (e:any) => {
        setCopy({...copy, [e.target.name]: e.target.checked})
        console.log(copy);
    }
    const handleSubmit = async (event: any) => {
        event.preventDefault();

        try {
            let result = await mutateAsync({
                userID: orgMember.userID,
                orgID: orgID,
                role: Number(copy.role), //It is nessessary to cast/parse to number b/c it will get sent as a string even though its an enum
                assignedVirtualStores: [...copy.assignedVirtualStores]
            });

            if(result){
                setEditMode(false);
            }

        } catch (e) {
            console.error(e);
        }
    }
    const handleDelete = async () => {
        try {
            let result = await deleteAsync({
                orgID: orgID,
                memberID: copy.userID
            });

            // if(result){
            //     setEditMode(false);
            // }

        } catch (e) {
            console.error(e);
        }
    }

    return(
        <OrgMemberForm handleSubmit={handleSubmit} onChange={onChange} onCheckChange={onCheckChange} setEditMode={setEditMode} handleDelete={handleDelete} setCopy={setCopy} copy={copy} isLoading={isLoading} isError={isError} orgMem={orgMember} clientRole={clientRole} virtualStores={virtualStores} />
    );
}