import background from '../logo.svg';
import {useNavigate} from "react-router-dom";
import {useFusionAuth} from "@fusionauth/react-sdk";
import {useEffect} from "react";
import { useQuery } from 'react-query';
import {ActiveMachines} from '../Services/Requests'
import { isOnline, timeAgo } from '../Helper/StatusHelper';

function ActivePOS(){
  const {isLoggedIn, isFetchingUserInfo} = useFusionAuth()

  if (!isLoggedIn || isFetchingUserInfo) {
    return null;
  }

  const { isLoading: isLoadingAM, error, data } = ActiveMachines();

  if (isLoadingAM) return null;
  if (error) console.log('An error occurred while fetching the user data ', error);

  return (
    <div className="column-container px-2">
      <div className="content-container">
        <div style={{marginBottom: '100px'}}>
          <h1 className="font-bold text-white">POS machines recorded</h1>
          <table className="w-full table-fixed border border-collapse border-black border-spacing-y-1">
            <thead className="bg-slate-400">
              <tr>
                <th>Hardware ID</th>
                <th>Store Name</th>
                <th>POS Version</th>
                <th>Store Address</th>
                <th>Status</th>
                <th>Last Seen</th>
              </tr>
            </thead>
            <tbody>
              {
                data && data.map((pos) => {
                  return (<tr key={pos.hardwareID} className="even:bg-offWhite odd:bg-slate-300 border-b border-gray-700">
                    <td>
                      <div className="flex justify-center">
                        {pos.hardwareID.substring(pos.hardwareID.length - 8)}
                      </div>
                    </td>
                    <td>
                      <div className="flex justify-center">
                        {pos.storeName}
                      </div>
                    </td>
                    <td>
                      <div className="flex justify-center">
                        {pos.posVersion}
                      </div>
                    </td>
                    <td>
                      <div className="flex justify-center">
                        {pos.storeAddress}
                      </div>
                    </td>
                    <td>
                      <div className="flex justify-center">
                        { isOnline(new Date(pos.lastCheckIn)) ?
                        <div className="flex justify-start items-center space-x-2">
                        <p className="text-sm -my-1">Online</p>
                        <span className="relative flex h-3 w-3">
                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
                            <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
                        </span>
                        </div>
                        :
                        <div className="flex justify-start items-center space-x-2 -my-1">
                            <p className="text-sm">Offline</p>
                            <span className="relative flex h-3 w-3">
                                <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                            </span>
                        </div>
                        }
                      </div>
                    </td>
                    <td>
                      <div className="flex justify-center">
                        {timeAgo(new Date(pos.lastCheckIn))}
                      </div>
                    </td>
                  </tr>)
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default ActivePOS;
  