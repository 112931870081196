import { RequireAuth } from "@fusionauth/react-sdk";
import {
    BsDatabase,
} from "react-icons/bs";
import { FaBriefcase, FaExchangeAlt, FaStore } from "react-icons/fa";
import { FaPeopleGroup, FaCashRegister } from "react-icons/fa6";
import { GiOrganigram } from "react-icons/gi";
import {NavLink} from "react-router-dom";

const SideBar = () => {
    return(
        <div className="fixed left-0 h-screen w-16 m-0
        flex flex-col bg-tertiaryBlue text-white shadow-lg">
            <SideBarIcon icon={<FaStore size="28" />} link="/mystores" text="My Stores 🏪"/>
            <SideBarIcon icon={<FaCashRegister  size="28" />} link="/managestore" text="Manage Store 🛒"/>
            <SideBarIcon icon={<FaExchangeAlt size="28" />} link="/viewstoretransactions" text="Transactions 🧾"/>
            <SideBarIcon icon={<FaPeopleGroup size="28" />} link="/myorgs" text="My Orgs 👥"/>
            <SideBarIcon icon={<FaBriefcase size="28" />} link="/manageorgstore" text="Manage Org Virtual Store 👔"/>
            <RequireAuth withRole={["SuperUser", "Reseller"]}>
                <SideBarIcon icon={<GiOrganigram size="28" />} link="/manageclients" text="Manage Merchants 🔄"/>
            </RequireAuth>
            <RequireAuth withRole={["SuperUser"]}>
                <SideBarIcon icon={<BsDatabase size="28" />} link="/activepos" text="Active POS 👁️"/>
                {/* <SideBarIcon icon={<BsDatabaseFillGear size="28" />} link="/"/>
                <SideBarIcon icon={<BsBuilding size="28" />} link="/"/>
                <SideBarIcon icon={<BsBank2 size="28" />} link="/postsignup" /> */}
            </RequireAuth>
        </div>
    );
};

const SideBarIcon = ({ icon, text = 'tooltip', link}) => (
    <NavLink className="sidebar-icon group" to={link}>
        {icon}

        <span className="sidebar-tooltip group-hover:scale-100">
            {text}
        </span>
    </NavLink>
);

export default SideBar;