export type ItemPreview = {
    syncGuid: string,
    itemNumber: string,
    description: string,
    price: number
}

export type ItemFilter = {
    chunkSize: number;
    page: number;
    amountFrom: number;
    amountTo: number;
    property: string;
    departments: string[];
    categories: string[];
    suppliers: string[];
    sold: number;
  };

export type ItemView = {
    syncGuid: string;
    isCompanyData: boolean;
    barcode: string;
    description: string;
    department: string;
    category: string;
    available: number;
    price: number;
    lastUpdated: Date;
};

export type ItemPackage = {
    totalCount: number;
    items: ItemView[];
};

export enum ItemSold{
    AllItems = 0,
    NotSold = 1,
    Sold = 2
};

export type Item = {
    departmentID: string;
    categoryID: string;
    messageID: string;
    taxId: string;
    taxId2: string;
    itemNumber: string;
    barcodeNumber: string;
    description: string;
    extendedDescription: string;
    notes: string;
    subDescription1: string;
    subDescription2: string;
    subDescription3: string;
    pictureID: number;
    active: boolean;
    itemType: number;
    price: number;
    priceA: number;
    priceB: number;
    priceC: number;
    salePrice: number;
    saleStartDate: Date;
    saleEndDate: Date;
    cost: number;
    promptMargin: number;
    quantity: number;
    supplierID: string;
    modifyUser: string;
    modifyDatetime: Date;
    lastReceived: Date;
    lastSold: Date;
    lastCounted: Date;
    dateCreated: Date;
    msrp: number;
    binLocation: string;
    reorderPoint: number;
    restockLevel: number;
    buydownPrice: number;
    buydownQuantity: number;
    commissionAmount: number;
    commissionMaximum: number;
    commissionMode: number;
    commissionPercentProfit: number;
    commissionPercentSale: number;
    foodStampable: boolean;
    itemNotDiscountable: boolean;
    quantityCommitted: number;
    serialNumberCount: number;
    tareWeightPercent: number;
    quantityDiscountID: string;
    tagAlongItem: number;
    tagAlongQuantity: number;
    parentItem: number;
    parentQuantity: number;
    barcodeType: number;
    barcodeFormat: number;
    barcodePriceEmbeddedFormat: string;
    priceLowerBound: number;
    priceUpperBound: number;
    pictureName: string;
    unitOfMeasure: string;
    subCategoryID: number;
    quantityEntryNotAllowed: boolean;
    priceMustBeEntered: boolean;
    blockSalesReason: string;
    blockSalesAfterDate: Date;
    weight: number;
    taxable: boolean;
    blockSalesBeforeDate: Date;
    lastCost: number;
    replacementCost: number;
    blockSalesType: number;
    blockSalesScheduleID: number;
    saleType: number;
    salesScheduleID: number;
    consignment: boolean;
    doNotOrder: boolean;
    usuallyShip: string;
    weighed: boolean;
    useKitComponentCost: boolean;
    useKitComponentPrice: boolean;
    salesStartDate: Date;
    salesEndDate: Date;
    syncGuid: string;
    isCompanyData: boolean;
    useNegativePrice: boolean;
    deposit: number;
    minimumStock: number;
    warningMinStock: boolean;
    inhibitBelowMinStock: boolean;
    weightBasis: number;
    amountLimit: number;
    quantityDiscountBasketNo: number;
    priceEditable: boolean;
  };  

export const ItemTemplate:Item = {
    departmentID: "00000000-0000-0000-0000-000000000000",
    categoryID: "00000000-0000-0000-0000-000000000000",
    messageID: "00000000-0000-0000-0000-000000000000",
    taxId: "00000000-0000-0000-0000-000000000000",
    taxId2: "00000000-0000-0000-0000-000000000000",
    itemNumber: "",
    barcodeNumber: "",
    description: "",
    extendedDescription: "",
    notes: "",
    subDescription1: "",
    subDescription2: "",
    subDescription3: "",
    pictureID: 0,
    active: true,
    itemType: 0,
    price: 0,
    priceA: 0,
    priceB: 0,
    priceC: 0,
    salePrice: 0,
    saleStartDate: new Date,
    saleEndDate: new Date,
    cost: 0,
    promptMargin: 0,
    quantity: 0,
    supplierID: "00000000-0000-0000-0000-000000000000",
    modifyUser: "00000000-0000-0000-0000-000000000000",
    modifyDatetime: new Date,
    lastReceived: new Date,
    lastSold: new Date,
    lastCounted: new Date,
    dateCreated: new Date,
    msrp: 0,
    binLocation: "",
    reorderPoint: 0,
    restockLevel: 0,
    buydownPrice: 0,
    buydownQuantity: 0,
    commissionAmount: 0,
    commissionMaximum: 0,
    commissionMode: 0,
    commissionPercentProfit: 0,
    commissionPercentSale: 0,
    foodStampable: false,
    itemNotDiscountable: false,
    quantityCommitted: 0,
    serialNumberCount: 0,
    tareWeightPercent: 0,
    quantityDiscountID: "00000000-0000-0000-0000-000000000000",
    tagAlongItem: 0,
    tagAlongQuantity: 0,
    parentItem: 0,
    parentQuantity: 0,
    barcodeType: 0,
    barcodeFormat: 0,
    barcodePriceEmbeddedFormat: "",
    priceLowerBound: 0,
    priceUpperBound: 0,
    pictureName: "",
    unitOfMeasure: "",
    subCategoryID: 0,
    quantityEntryNotAllowed: false,
    priceMustBeEntered: false,
    blockSalesReason: "",
    blockSalesAfterDate: new Date,
    weight: 0,
    taxable: true,
    blockSalesBeforeDate: new Date,
    lastCost: 0,
    replacementCost: 0,
    blockSalesType: 0,
    blockSalesScheduleID: 0,
    saleType: 0,
    salesScheduleID: 0,
    consignment: false,
    doNotOrder: false,
    usuallyShip: "",
    weighed: false,
    useKitComponentCost: false,
    useKitComponentPrice: false,
    salesStartDate: new Date,
    salesEndDate: new Date,
    syncGuid: "00000000-0000-0000-0000-000000000000",
    isCompanyData: false,
    useNegativePrice: false,
    deposit: 0,
    minimumStock: 0,
    warningMinStock: false,
    inhibitBelowMinStock: false,
    weightBasis: 0,
    amountLimit: 0,
    quantityDiscountBasketNo: 0,
    priceEditable: true,
  };  

export const ItemViewTemplate:ItemView = {
    syncGuid: "00000000-0000-0000-0000-000000000000",
    isCompanyData: false,
    barcode: "",
    description: "",
    department: "",
    category: "",
    available: 0,
    price: 0,
    lastUpdated: new Date,
};