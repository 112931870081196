import { useEffect, useState } from "react";
import { Store } from "../../../Model/Store";
import { CreateStoreQuantityDiscount, DeleteStoreQuantityDiscount, GetStoreQuantityDiscount, GetStoreQuantityDiscountBaskets, GetStoreQuantityDiscountView, UpdateStoreQuantityDiscount } from "../../../Services/Requests";
import { ReadableDate, ReadableMoney, ReadableTime } from "../../../Helper/ConversionHelper";
import FormInput from "../../../Components/FormInput";
import { FaPen, FaTimes, FaTrashAlt } from "react-icons/fa";
import PageNavigator from "../../../Components/PageNavigator";
import QuantityDiscount, { Basket, QuantityDiscountFilter, QuantityDiscountInjestPackage, QuantityDiscountType, QuantityDiscountView, Template } from "../../../Model/POSModel/QuantityDiscount";
import { ConvertDiscountType } from "../../../Helper/EnumToString";
import FormCheckbox from "../../../Components/FormCheckbox";
import { BasketEditWindow, EditQuantityDiscountWindow, ItemSearchWindow } from "../Components/EditQuantityDiscount";
import { ItemPreview } from "../../../Model/POSModel/Item";
import { useQueryClient } from "react-query";

export const QuantityDiscountPage: React.FC<{ store:Store, isOrgStore:boolean }> = ({ store, isOrgStore = false }) => {

    const template = {
        chunkSize: 100,
        page: 1,
        search: ""
    };
    const [discountFilter, setDiscountFilter] = useState<QuantityDiscountFilter>(template);
    const [selectedDiscount, setSelectedDiscount] = useState<QuantityDiscountView | undefined>();
    const [editingDiscount, setEditingDiscount] = useState<QuantityDiscount | undefined>();
    const [editingBasket, setEditingBasket] = useState<Basket[] | undefined>();
    const [selectedBasketIndex, setSelectedBasketIndex] = useState(0);

    // const { isLoading:isLoadingRegisters, data:registerData } = GetStoreRegisters(store.id);
    // const { isLoading:isLoadingUsers, data:userData } = GetStoreEmployees(store.id);
    const { isLoading, data } = GetStoreQuantityDiscountView(store.id, discountFilter);

    const HandleSetPageInfo = (page:number, chunkSize: number) => {
        setDiscountFilter({...discountFilter, page: page, chunkSize: chunkSize});
    }

    const HandleWindowExit = () => {
        if(editingBasket){
            setEditingDiscount(undefined);
            setEditingBasket(undefined);
            setSelectedBasketIndex(0);
        }else{
            setSelectedDiscount(undefined);
        }
    }

    const HandleSetFilter = (filter: QuantityDiscountFilter) => {
        setDiscountFilter({...filter, page: 1, chunkSize: discountFilter.chunkSize});
    }

    const HandleNewDiscount = () => {
        handleEditClick(Template, []);
    }

    const handleEditClick = (discount: QuantityDiscount, baskets: Basket[]) => {
        setEditingDiscount(discount);

        var newBaskets = baskets;

        if(baskets.length < 3){
            let length = baskets.length;
            for (let i = 0; i < (3 - length); i++)
                newBaskets.push({
                    number: (length + i),
                    items: []
                })
        }

        setEditingBasket(newBaskets);
    }

    const HandleRemoveItemFromBasket = (itemIndex: number) => {
        if(editingBasket){
            setEditingBasket(editingBasket.map((innerList, i) =>
                i === selectedBasketIndex ?
                {...innerList, items: innerList.items.filter((_, j) => j !== itemIndex)}
                :
                innerList
            ))
        }
    }
    const HandleAddItemToBasket = (item: ItemPreview) => {
        if(editingBasket){
            setEditingBasket(editingBasket.map((innerList, i) =>
                i === selectedBasketIndex ?
                {...innerList, items: [...innerList.items, item]}
                :
                innerList
            ))
        }
    }

    useEffect(() => {
        if(selectedDiscount && data){
            const discount = data.discounts.find((x) => x.syncGuid === selectedDiscount.syncGuid);
            if(discount){
                setSelectedDiscount(discount);
            }
        }
    }, [data, selectedDiscount]);
    useEffect(() => {
        if(editingDiscount && editingDiscount.type < QuantityDiscountType.MMPriceBasket){
            setSelectedBasketIndex(0);
        }
    }, [editingDiscount]);

    return(<div>
        <div className="flex justify-evenly pt-6 px-2 flex-row h-screen space-x-2">
            {   editingDiscount && editingBasket ?
                <div className="flex justify-evenly flex-row h-screen space-x-2 pb-10 w-3/4">
                    <ItemSearchWindow storeID={store.id} discount={editingDiscount} baskets={editingBasket} selectedBasketIndex={selectedBasketIndex} setEditingBasket={setEditingBasket} addItemToBasket={HandleAddItemToBasket}/>
                    <BasketEditWindow storeID={store.id} discount={editingDiscount} baskets={editingBasket} selectedBasketIndex={selectedBasketIndex} setSelecedBasket={setSelectedBasketIndex} removeItemFromBasket={HandleRemoveItemFromBasket}/>
                </div>
                :
                <div className="bg-offWhite shadow-xl rounded-3xl px-5 pb-8 pt-4 mb-4 flex flex-col w-full">
                    <div className="flex justify-between items-center">
                            <h1 className='font-bold text-xl'>Discount - Item</h1>
                            {/* <button onClick={() => setSearchVisible(true)}>
                                <FaSearch />
                            </button> */}
                    </div>

                    <FilterComponent filter={discountFilter} setFilter={HandleSetFilter} handleNewDiscount={HandleNewDiscount}/>

                    <div className="flex flex-row justify-between my-2">
                        <p className='text-sm'>Click on a discount to view its details.</p>

                        <PageNavigator totalCount={data?.totalCount} currentPage={discountFilter.page} chunkSize={discountFilter.chunkSize} setPageInfo={HandleSetPageInfo}/>
                    </div>
                    
                    <div className="relative overflow-y-auto h-full flex flex-col items-center">
                    <table className="w-full table-auto">
                    <thead className="bg-slate-400 sticky top-0">
                        <tr>
                        <th>Type</th>
                        <th>Description</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Last Updated</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                        !isLoading && data && data.discounts.map((discount) => {
                            return (<tr key={discount.syncGuid} className={`${selectedDiscount && selectedDiscount.syncGuid === discount.syncGuid ? "bg-slate-500" : "even:bg-offWhite odd:bg-slate-300"}
                                    border-b border-gray-700 ${"hover:bg-slate-600 cursor-pointer"}`}
                                    onClick={() => setSelectedDiscount(discount)}
                                    >
                            <td>
                                <div className="flex justify-center">
                                {ConvertDiscountType(discount.type)}
                                </div>
                            </td>
                            <td>
                                <div className="flex justify-center">
                                {discount.description}
                                </div>
                            </td>
                            <td>
                                <div className="flex justify-center">
                                {ReadableDate(discount.startDate)}
                                </div>
                            </td>
                            <td>
                                <div className="flex justify-center">
                                {ReadableDate(discount.endDate)}
                                </div>
                            </td>
                            <td>
                                <div className="flex justify-center">
                                {ReadableTime(discount.lastUpdated)}
                                </div>
                            </td>
                            </tr>)
                        })
                        }
                    </tbody>
                    </table>
                    {isLoading && <span className="mt-2">Loading...</span>}
                    </div>
                </div>
            }

            { editingDiscount ?
                editingBasket && <EditQuantityDiscountWindow storeID={store.id} editingDiscount={editingDiscount} editingBasket={editingBasket} onWindowExit={HandleWindowExit} setEditingDiscount={setEditingDiscount}/>
                :
                selectedDiscount && <DiscountDetails selectedDiscount={selectedDiscount} storeID={store.id} onWindowExit={HandleWindowExit} isOrgStore={isOrgStore} setEditDiscount={setEditingDiscount} editingDiscount={editingDiscount} editingBasket={editingBasket} setEditingBasket={setEditingBasket} handleEditClick={handleEditClick}/>
            }
        </div>
      </div>
    );
}

const FilterComponent: React.FC<{ filter:QuantityDiscountFilter, setFilter(filter:QuantityDiscountFilter):void, handleNewDiscount():void }> = ({ filter, setFilter, handleNewDiscount }) => {

    const [copy, setCopy] = useState(filter);

    const onChange = (e:any) => {
        setCopy({...copy, [e.target.name]: e.target.value})
        //console.log(copy);
    }

    const onSubmit = (e:any) => {
        e.preventDefault();
        setFilter(copy);
    }

    return (
        <div>
            <form onSubmit={onSubmit} className="space-y-2">
                <div className="grid grid-cols-2 gap-4">
                    <FormInput label="Description" placeholder="Coke 2L 2 for $4"
                    type="text" required={false} pattern="^(?! *$)[A-Za-z0-9\$\*\- .]+$"
                    name="search" value={copy.search} onChange={onChange}/>
                </div>

                <div className="flex justify-between">
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                        Filter
                    </button>

                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={handleNewDiscount}>
                        New Discount
                    </button>
                </div>
            </form>
        </div>
    );
}

interface DiscountDetailsProps{
    selectedDiscount: QuantityDiscountView,
    storeID: number,
    onWindowExit():void,
    isOrgStore:boolean,
    setEditDiscount(discount?: QuantityDiscount):void,
    editingDiscount?:QuantityDiscount
    setEditingBasket(baskets?: Basket[]):void
    editingBasket?: Basket[],
    handleEditClick(discount: QuantityDiscount, baskets: Basket[]):void
};

const DiscountDetails: React.FC<DiscountDetailsProps> = ({selectedDiscount, storeID, onWindowExit, isOrgStore, setEditDiscount, editingDiscount: copy, handleEditClick, editingBasket}) => {

    const queryClient = useQueryClient();
    const { mutateAsync: createAsync, isLoading: isCreateLoading, isError:isCreateError } = CreateStoreQuantityDiscount(queryClient, storeID);
    const { mutateAsync: updateAsync, isLoading: isUpdateLoading, isError:isUpdateError } = UpdateStoreQuantityDiscount(queryClient, storeID);
    const { mutateAsync: deleteAsync, isLoading: isDeleting } = DeleteStoreQuantityDiscount(queryClient, storeID);
    const {isLoading, data} = GetStoreQuantityDiscount(storeID, selectedDiscount.syncGuid);
    const {isLoading: isLoadingBaskets, data:basketData} = GetStoreQuantityDiscountBaskets(storeID, selectedDiscount.syncGuid);

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    

    let [isModifyLoading, setIsModifyLoading] = useState(false);
    let [isError, setIsError] = useState(false);

    useEffect(() => {
        setIsModifyLoading(isCreateLoading || isUpdateLoading);
        setIsError(isCreateError || isUpdateError);
    }, [isCreateLoading, isUpdateLoading, isCreateError, isUpdateError])

    useEffect(() => {
        if(copy){
            let startDate = copy.startDate ? new Date(copy.startDate).toISOString().split('T')[0] : "";
            setStartDate(startDate);

            let endDate = copy.endDate ? new Date(copy.endDate).toISOString().split('T')[0] : "";
            setEndDate(endDate);
        }
    }, [copy])

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        if(!copy || !editingBasket)
            return;     

        let discountPackage: QuantityDiscountInjestPackage = {
            quantityDiscount: copy,
            baskets: editingBasket
        };

        try {
            let result = copy.syncGuid === "00000000-0000-0000-0000-000000000000" ? await createAsync(discountPackage) : await updateAsync(discountPackage);

            if(result){
                onWindowExit();
            }

        } catch (e) {
            console.error(e);
        }
    }

    const handleDelete = async () => {
        try {
            if(!copy)
                return;

            let result = await deleteAsync(copy);

            if(result){
                onWindowExit();
            }

        } catch (e) {
            console.error(e);
        }
    }

    const onChange = (e:any) => {
        copy && setEditDiscount({...copy, [e.target.name]: e.target.value})
    }
    const onChangeNumber = (e:any) => {
        copy && setEditDiscount({...copy, [e.target.name]: Number(e.target.value)})
    }
    const onCheckChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.type === "radio") {
            setEditDiscount({
                ...copy!,
                [e.target.name]: e.target.value === "true"
            });
        } else {
            setEditDiscount({
                ...copy!,
                [e.target.name]: e.target.checked
            });
        }
    };

    //console.log(basketData);
    console.log(copy);
    return (
        <div className="bg-offWhite shadow-xl rounded-3xl px-5 pb-8 mb-4 flex flex-col w-1/2">
            <div className="mb-4 mt-4">
                <div className="flex justify-between items-center">
                    <div className="flex justify-between items-center space-x-2">
                        <h1 className='font-bold text-xl'>{copy ? "Edit Discount" : "Discount Details"}</h1>
                        {   data && basketData && isOrgStore === data.isCompanyData && !copy &&
                            <button onClick={() => handleEditClick(data, basketData)}>
                            <FaPen size="20" className="items-center relative flex justify-center
                            hover:text-orange-500 transition-all duration-300 ease-linear cursor-pointer"/>
                        </button>}
                    </div>
                    <button onClick={onWindowExit}>
                        <FaTimes size="28" className="items-center relative flex justify-center
                        hover:text-red-600 transition-all duration-300 ease-linear cursor-pointer"/>
                    </button>
                </div>
            </div>

            {
                <div className="overflow-y-auto">
                    <label className="block text-gray-700 text-lg font-bold -mb-1">Description</label>
                    <p className="mb-2 text-lg">{selectedDiscount.description}</p>
                    {/* <label className="block text-gray-700 text-sm font-bold -mb-1">Receipt No.</label>
                    <span>{selectedTrans.receiptNumber}</span> */}
                    <div className="grid grid-cols-2 gap-x-4">
                        <div>
                            <label className="block text-gray-700 text-sm font-bold -mb-1">Type</label>
                            <span className="w-full">{ConvertDiscountType(selectedDiscount.type)}</span>
                        </div>
                        <div>
                            <label className="block text-gray-700 text-sm font-bold -mb-1">Last Updated</label>
                            {ReadableTime(selectedDiscount.lastUpdated)}
                        </div>
                        <div>
                            <label className="block text-gray-700 text-sm font-bold -mb-1">Start Date</label>
                            {ReadableDate(selectedDiscount.startDate)}
                        </div>
                        <div>
                            <label className="block text-gray-700 text-sm font-bold -mb-1">End Date</label>
                            {ReadableDate(selectedDiscount.endDate)}
                        </div>
                    </div>

                    {isLoading ?
                        <span>Loading...</span>
                        :
                        data && <div>
                            <div className="grid grid-cols-2 gap-x-4">
                                <div>
                                    <label className="block text-gray-700 text-sm font-bold -mb-1">Show Discount Message</label>
                                    {data.showDiscountMessage ? "Yes" : "No"}
                                </div>
                                <div>
                                    <label className="block text-gray-700 text-sm font-bold -mb-1">Ignore Other Discounts</label>
                                    {data.ignoreOtherDiscount ? "Yes" : "No"}
                                </div>
                            </div>

                            {data.type === QuantityDiscountType.BuyXGetY && <div>
                                <div>
                                    <label className="block text-gray-700 text-sm font-bold -mb-1">Quantity to buy at full price</label>
                                    {data.quantity1}
                                </div>
                                <div>
                                    <label className="block text-gray-700 text-sm font-bold -mb-1">Quantity to get at discount price</label>
                                    {data.quantity2}
                                </div>
                                <div>
                                    <label className="block text-gray-700 text-sm font-bold -mb-1">Discount price</label>
                                    {ReadableMoney(data.price2)}
                                </div>
                            </div>}

                            {(data.type === QuantityDiscountType.MMPriceBasket || data.type === QuantityDiscountType.MMPercentOffBasket) && <div>
                                <div className="flex items-center space-x-2">
                                    <label className="block text-gray-700 text-base font-bold">Quantity at basket 1:</label>
                                    <span>{data.quantity1}</span>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <label className="block text-gray-700 text-base font-bold">Quantity at basket 2:</label>
                                    <span>{data.quantity2}</span>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <label className="block text-gray-700 text-base font-bold">Quantity at basket 3:</label>
                                    <span>{data.quantity3}</span>
                                </div>
                            </div>}

                            {   (data.type === QuantityDiscountType.MMPrice || data.type === QuantityDiscountType.MMPriceBasket) && <div className="flex space-x-2 items-center">
                                    <label className="block text-gray-700 text-base font-bold">Prices in this table are:</label>
                                    <span className="font-bold">{data.extendedPriceDiscount ? "Extended Prices" : "Unit Prices"}</span>
                                </div>}

                            {(data.type === QuantityDiscountType.MMPrice || data.type === QuantityDiscountType.MMPercentOff) && <div>
                                <label className="block text-gray-700 text-sm font-bold mb-1">Prices</label>
                                <div className="bg-slate-400">
                                    <table className="w-full table-auto">
                                        <thead className="bg-slate-400 sticky top-0">
                                            <tr>
                                            <th>Quantity</th>
                                            <th>Reg. Price</th>
                                            <th>Price A</th>
                                            <th>Price B</th>
                                            <th>Price C</th>
                                            </tr>
                                        </thead>
                                        { data.type === QuantityDiscountType.MMPrice &&
                                            <tbody>
                                            <tr className={"even:bg-offWhite odd:bg-slate-300 border-b border-gray-700"}>
                                                <td>
                                                    <div className="flex justify-center">
                                                    {data.quantity1}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="flex justify-center">
                                                    {ReadableMoney(data.price1)}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="flex justify-center">
                                                    {ReadableMoney(data.price1A)}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="flex justify-center">
                                                    {ReadableMoney(data.price1B)}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="flex justify-center">
                                                    {ReadableMoney(data.price1C)}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className={"even:bg-offWhite odd:bg-slate-300 border-b border-gray-700"}>
                                                <td>
                                                    <div className="flex justify-center">
                                                    {data.quantity2}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="flex justify-center">
                                                    {ReadableMoney(data.price2)}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="flex justify-center">
                                                    {ReadableMoney(data.price2A)}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="flex justify-center">
                                                    {ReadableMoney(data.price2B)}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="flex justify-center">
                                                    {ReadableMoney(data.price2C)}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className={"even:bg-offWhite odd:bg-slate-300 border-b border-gray-700"}>
                                                <td>
                                                    <div className="flex justify-center">
                                                    {data.quantity3}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="flex justify-center">
                                                    {ReadableMoney(data.price3)}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="flex justify-center">
                                                    {ReadableMoney(data.price3A)}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="flex justify-center">
                                                    {ReadableMoney(data.price3B)}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="flex justify-center">
                                                    {ReadableMoney(data.price3C)}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className={"even:bg-offWhite odd:bg-slate-300 border-b border-gray-700"}>
                                                <td>
                                                    <div className="flex justify-center">
                                                    {data.quantity4}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="flex justify-center">
                                                    {ReadableMoney(data.price4)}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="flex justify-center">
                                                    {ReadableMoney(data.price4A)}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="flex justify-center">
                                                    {ReadableMoney(data.price4B)}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="flex justify-center">
                                                    {ReadableMoney(data.price4C)}
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        }
                                        { data.type === QuantityDiscountType.MMPercentOff &&
                                            <tbody>
                                            <tr className={"even:bg-offWhite odd:bg-slate-300 border-b border-gray-700"}>
                                                <td>
                                                    <div className="flex justify-center">
                                                    {data.quantity1}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="flex justify-center">
                                                    {data.percentOffRegularPrice1 + '%'}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="flex justify-center">
                                                    {data.percentOffPrice1A + '%'}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="flex justify-center">
                                                    {data.percentOffPrice1B + '%'}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="flex justify-center">
                                                    {data.percentOffPrice1C + '%'}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className={"even:bg-offWhite odd:bg-slate-300 border-b border-gray-700"}>
                                            <td>
                                                    <div className="flex justify-center">
                                                    {data.quantity2}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="flex justify-center">
                                                    {data.percentOffRegularPrice2 + '%'}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="flex justify-center">
                                                    {data.percentOffPrice2A + '%'}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="flex justify-center">
                                                    {data.percentOffPrice2B + '%'}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="flex justify-center">
                                                    {data.percentOffPrice2C + '%'}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className={"even:bg-offWhite odd:bg-slate-300 border-b border-gray-700"}>
                                            <td>
                                                    <div className="flex justify-center">
                                                    {data.quantity3}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="flex justify-center">
                                                    {data.percentOffRegularPrice3 + '%'}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="flex justify-center">
                                                    {data.percentOffPrice3A + '%'}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="flex justify-center">
                                                    {data.percentOffPrice3B + '%'}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="flex justify-center">
                                                    {data.percentOffPrice3C + '%'}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className={"even:bg-offWhite odd:bg-slate-300 border-b border-gray-700"}>
                                            <td>
                                                    <div className="flex justify-center">
                                                    {data.quantity4}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="flex justify-center">
                                                    {data.percentOffRegularPrice4 + '%'}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="flex justify-center">
                                                    {data.percentOffPrice4A + '%'}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="flex justify-center">
                                                    {data.percentOffPrice4B + '%'}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="flex justify-center">
                                                    {data.percentOffPrice4C + '%'}
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        }
                                        
                                    </table>
                                </div>
                                <div className="flex space-x-2 items-center">
                                    <label className="block text-gray-700 text-sm font-bold">Discount Odd items:</label>
                                    <span>{data.discountOddItems ? "Yes" : "No"}</span>
                                </div>
                            </div>}

                            {(data.type === QuantityDiscountType.MMPriceBasket || data.type === QuantityDiscountType.MMPercentOffBasket) && <div className="grid grid-cols-4 gap-x-4">
                                <div>
                                    <label className="block text-gray-700 text-sm font-bold -mb-1">Reg. Price</label>
                                    {data.type === QuantityDiscountType.MMPriceBasket ? ReadableMoney(data.price1) : data.percentOffRegularPrice1 + '%'}
                                </div>
                                <div>
                                    <label className="block text-gray-700 text-sm font-bold -mb-1">Price A</label>
                                    {data.type === QuantityDiscountType.MMPriceBasket ? ReadableMoney(data.price1A) : data.percentOffPrice1A + '%'}
                                </div>
                                <div>
                                    <label className="block text-gray-700 text-sm font-bold -mb-1">Price B</label>
                                    {data.type === QuantityDiscountType.MMPriceBasket ? ReadableMoney(data.price1B) : data.percentOffPrice1B + '%'}
                                </div>
                                <div>
                                    <label className="block text-gray-700 text-sm font-bold -mb-1">Price C</label>
                                    {data.type === QuantityDiscountType.MMPriceBasket ? ReadableMoney(data.price1C) : data.percentOffPrice1C + '%'}
                                </div>
                            </div>}
                        </div>
                    }

                    {(!isLoading && !isLoadingBaskets && data && basketData) && <div className="">
                        <label className="block text-gray-800 text-base font-bold mt-4 mb-1">Items</label>
                        {   basketData.map((basket) => {
                            return <div key={basket.number}>
                                        {basketData.length > 1 && <label className="block text-gray-700 text-sm font-bold mt-4 mb-1">Basket {basket.number + 1}</label>}
                                        <div className="bg-slate-400">
                                            <table className="w-full table-auto">
                                                <thead className="bg-slate-400 sticky top-0">
                                                    <tr>
                                                    <th>Item #</th>
                                                    <th>Description</th>
                                                    <th>Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {basket.items.map((item) => {
                                                        return (<tr key={item.syncGuid} className={"even:bg-offWhite odd:bg-slate-300 border-b border-gray-700"}>
                                                        <td>
                                                            <div className="flex justify-center">
                                                            {item.itemNumber}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="flex justify-center">
                                                            {item.description}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="flex justify-center">
                                                            {ReadableMoney(item.price)}
                                                            </div>
                                                        </td>
                                                        </tr>)
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                            })
                        }
                    </div>}
                </div>
            }
        </div>
    );
}