enum PaymentMethods {
    Cash = 1,
    Cheque = 2,
    CreditCard = 3,
    DebitCard = 4,
    Voucher = 5,
    Account = 6,
    Points = 7,
    Other = 8,
    CreditOrDebitCard = 9,
    //GiftCard = 10,
};

export default PaymentMethods;