import {useNavigate} from "react-router-dom";
import {useFusionAuth, RequireAuth } from "@fusionauth/react-sdk";
import {useEffect, useRef, useState} from "react";
import { GetMerchantsStoresRegisters, GetOrgs } from "../Services/Requests";
import { POSSystem } from "../Model/POSSystem";
import { POSCard, NewPOSCard } from "../Components/POSCards";
import { StoreCard, NewStoreCard } from "../Components/StoreCards";
import { OrgRole, StoreRegisters } from "../Model/Transactional";
import { MerchantCard } from "../Components/MerchantCards";
import { OrgType } from "../Model/Roles";
import { Store, StoreStatus } from "../Model/Store";
import SearchOverlay, { SearchResultItem, SearchType } from "../Components/SearchOverlay";
import { FaSearch } from "react-icons/fa";
import { Account } from "../Model/Account";
import { HiddenText } from "../Components/HiddenText";


function ManageClients(){
    const navigate = useNavigate();

    const { isLoading: isLoadingSR, data } = GetMerchantsStoresRegisters();
    const { isLoading: isLoadingOrg, data: orgData } = GetOrgs();

    const [selectedMerchant, setSelectedMerchant] = useState("");
    const [stores, setStores] = useState<StoreRegisters[] | undefined>();
    const [selectedStore, setSelectedStore] = useState(-1);
    const [posMachines, setPOSMachines] = useState<POSSystem[] | undefined>();
    const [filter, setFilter] = useState<StoreStatus | number>(-1);

    const [isSearchVisible, setSearchVisible] = useState<boolean>(false);
    const merchantRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
    const storeRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});

    const handleSelectMerchant = (merchantID: string) => {
        setSelectedMerchant(merchantID);
    };

    useEffect(() => {
        if(selectedMerchant !== "" && data){
            let merchant = data.find(i => i.account.fusionAuthID == selectedMerchant);
            //setSelectedStore(-1);
            if(merchant){
                if(filter === -1){
                    setStores(merchant.stores);
                }else{
                    setStores(merchant.stores.filter(store => store.store.status === filter));
                }
            }
        }
    }, [selectedMerchant, data, filter])

    const handleSelectStore = (storeID: number) => {
        setSelectedStore(storeID);
    };
    useEffect(() => {
        if(selectedStore !== -1 && stores){
            let store = stores.find(i => i.store.id === selectedStore);
            store && setPOSMachines(store.posSystems);
        }
    }, [selectedStore, stores])

    const handleSearchSelect = (item: SearchResultItem) => {
        //setSelectedItem(item);
        // Update state with the selected item
        if(!data)
            return;

        switch(item.type)
        {
            case SearchType.Store:
                let store = (item.data as Store);
                let merchantWithData = data.find(x => x.stores.some(i => i.store.id === store.id));
                if(merchantWithData){
                    setSelectedMerchant(merchantWithData.account.fusionAuthID);
                    setSelectedStore(store.id);
                    if (merchantRefs.current[merchantWithData.account.fusionAuthID]) {
                        merchantRefs.current[merchantWithData.account.fusionAuthID]!.scrollIntoView({ behavior: 'smooth' });
                    }
                    if (storeRefs.current[store.id]) {
                        storeRefs.current[store.id]!.scrollIntoView({ behavior: 'smooth' });
                    }
                }
                break;

            case SearchType.MerchantEmail:
            case SearchType.MerchantName:
                setSelectedMerchant((item.data as Account).fusionAuthID);
                setSelectedStore(-1);
                if (merchantRefs.current[(item.data as Account).fusionAuthID]) {
                    merchantRefs.current[(item.data as Account).fusionAuthID]!.scrollIntoView({ behavior: 'smooth' });
                }
                break;
        }
    };

    if (isLoadingSR || isLoadingOrg) {
        return null;
    }

    return (
        <RequireAuth>
            <div className="h-full">
                <SearchOverlay isVisible={isSearchVisible} onClose={() => setSearchVisible(false)} onSelect={handleSearchSelect} input={data}/>
                <div className="flex justify-evenly pt-6 px-2 flex-row h-full space-x-2">
                    <div className="bg-offWhite shadow-xl rounded-3xl px-5 pb-8 mb-4 flex flex-col">
                        <div className="mb-4 mt-4">
                            <div className="flex justify-between items-center">
                                <h1 className='font-bold text-xl'>My Merchants:</h1>
                                <button onClick={() => setSearchVisible(true)}>
                                    <FaSearch />
                                </button>
                            </div>
                            
                            <p className='text-sm'>To get started, select one of your merchants from the list.</p>
                            { orgData &&
                                <p>Reseller code: <HiddenText className="font-normal" input={GetResellerCode(orgData)} /></p>
                            }
                        </div>
                        <div className="bg-slate-400 rounded-lg overflow-y-auto space-y-7 p-3 h-full">

                            {
                                data && data.map((merchStore) => {
                                    return <MerchantCard 
                                    merchantData={merchStore} onSelectMerchant={handleSelectMerchant} 
                                    isSelected={selectedMerchant === merchStore.account.fusionAuthID} 
                                    key={merchStore.account.fusionAuthID}
                                    ref={el => merchantRefs.current[merchStore.account.fusionAuthID] = el}
                                    />
                                })
                            }

                            {/* <NewStoreCard /> */}
                        </div>
                    </div>

                    { selectedMerchant !== "" &&
                        <div className="bg-offWhite shadow-xl rounded-3xl px-5 pb-8 mb-4 flex flex-col">
                            <div className="mb-2 mt-4">
                                <h1 className='font-bold text-xl'>Merchant's Stores:</h1>
                                <p className='text-sm mb-2'>To get started, select one of the merchant's stores from the list or create a new store.</p>
                                <label className="font-bold">Filter:</label>
                                <select name="orgType" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
                                focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                onChange={(e) => setFilter(Number(e.target.value))} value={filter}
                                >
                                    <option value={-1}>None</option>
                                    <option value={StoreStatus.Requested}>Requested</option>
                                    <option value={StoreStatus.Deactivated}>Deactivated</option>
                                    <option value={StoreStatus.Active}>Active</option>
                                </select>
                            </div>
                            <div className="bg-slate-400 rounded-lg overflow-y-auto space-y-7 p-3">

                                {
                                    stores && stores.map((storeReg) => {
                                        return <StoreCard
                                            storeData={storeReg}
                                            onSelectStore={handleSelectStore}
                                            isSelected={selectedStore === storeReg.store.id}
                                            key={storeReg.store.id}
                                            ref={el => storeRefs.current[storeReg.store.id] = el}
                                            />
                                    })
                                }

                                <NewStoreCard merchantID={selectedMerchant} />
                            </div>
                        </div>
                    }
                    

                    { selectedStore !== -1 &&
                        <div className="bg-offWhite shadow-xl rounded-3xl px-5 pb-8 mb-4 flex flex-col">
                            <div className="mb-4 mt-4">
                                <h1 className='font-bold text-xl'>POS Machines:</h1>
                                <p className='text-sm'>View the status of pos machines linked to the currently selected store.</p>
                            </div>
                            
                            <div className="bg-slate-400 rounded-lg overflow-y-auto space-y-7 p-3">

                                {
                                    posMachines && posMachines.map((machine) => {
                                        return <POSCard  key={machine.id} posData={machine} />
                                    })
                                }

                                { posMachines && posMachines.length < 3 &&
                                    <NewPOSCard storeID={selectedStore}/>
                                }
                            </div>
                            { posMachines &&
                                <p>{posMachines.length} POS Machines (Max 3)</p>
                            }
                        </div>
                    }
                </div>
            </div>
        </RequireAuth>
    )
}

function GetResellerCode(orgData: OrgRole[]){
    let org = orgData.find(i => i.org.orgType === OrgType.Reseller);
    if(org && org.org.linkCode){
        return org.org.linkCode;
    }
    else
        return"Unavailable";
}

export default ManageClients;