import React, { useEffect, useState } from 'react';
import FormInput from './FormInput';
import { AccountStoresRegisters } from '../Model/Transactional';
import { Account, AccountExt } from '../Model/Account';
import { Store } from '../Model/Store';

export type SearchResultItem = {
    type: SearchType,
    data: Account|Store
}

type SearchOverlayProps = {
    isVisible: boolean,
    onClose: () => void,
    onSelect: (item: SearchResultItem) => void,
    input: AccountStoresRegisters[]|undefined,
}

export enum SearchType{
    MerchantName,
    MerchantEmail,
    Store,
}

const SearchOverlay: React.FC<SearchOverlayProps> = ({input, isVisible, onClose, onSelect}) => {
    const [searchType, setSearchType] = useState<SearchType>(SearchType.MerchantName);
    const [searchTerm, setSearchTerm] = useState('');
    const [results, SetResults] = useState<SearchResultItem[]>([]);

    useEffect(() => {
        if(!input)
            return;

        //Search
        let allMerchants = input.flatMap(obj => obj.account);
        let allStores = input.flatMap(obj => obj.stores.flatMap(storeReg => storeReg.store));

        switch(searchType){
            case SearchType.Store:
                if(!searchTerm)
                    SetResults(allStores.map(store => ({
                        type: SearchType.Store,
                        data: store
                    })));
                else
                    SetResults(allStores.filter(x => x.name.toLowerCase().includes(searchTerm.toLowerCase()))
                    .map(store => ({
                        type: SearchType.Store,
                        data: store
                    })));
                break;

            case SearchType.MerchantEmail:
                if(!searchTerm)
                    SetResults(allMerchants.map(merchant => ({
                        type: SearchType.MerchantEmail,
                        data: merchant
                    })));
                else
                    SetResults(allMerchants.filter(x => x.email.toLowerCase().includes(searchTerm.toLowerCase()))
                    .map(merchant => ({
                        type: SearchType.MerchantEmail,
                        data: merchant
                    })));
                break;

            case SearchType.MerchantName:
                if(!searchTerm)
                    SetResults(allMerchants.map(merchant => ({
                        type: SearchType.MerchantName,
                        data: merchant
                    })));
                else
                    SetResults(allMerchants.filter(x => x.name.toLowerCase().includes(searchTerm.toLowerCase()))
                    .map(merchant => ({
                        type: SearchType.MerchantName,
                        data: merchant
                    })));
                break;

            default:
                SetResults([]);
                break;
        }
    }, [input, searchTerm, searchType])

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    }

    const handleSelectItem = (item: SearchResultItem) => {
        onSelect(item);
        onClose();
    }

    if(!isVisible) return null;

    return (
        <div className='fixed inset-0 bg-gray-900 bg-opacity-50 z-50 flex justify-center items-center py-8'>
            <div className='bg-white p-4 rounded-lg flex flex-col h-full'>
                <div className='flex items-end'>
                    <FormInput type="text" value={searchTerm} onChange={handleSearchChange} placeholder="Search" label="Search"/>
                    <select name="orgType" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
                    focus:border-blue-500 block px-2.5 h-10 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    onChange={(e) => setSearchType(Number(e.target.value))} value={searchType}
                    >
                        <option value={SearchType.MerchantName}>Merchant (Name)</option>
                        <option value={SearchType.MerchantEmail}>Merchant (Email)</option>
                        <option value={SearchType.Store}>Store (Name)</option>
                    </select>
                </div>
                
                <ul className='bg-slate-400 rounded-lg overflow-y-auto mt-2 h-full'>
                    {results.map(item => (
                        <li className='p-2 cursor-pointer hover:bg-gray-200'
                        onClick={() => handleSelectItem(item)}
                        >
                            <SearchResult data={item}/>
                        </li>
                    ))}
                </ul>
                <button className='mt-2 p-2 border border-gray-300 rounded' onClick={onClose}>
                    Close
                </button>
            </div>
        </div>
    )
}

type SearchResultProps = {
    data: SearchResultItem,
}

const SearchResult: React.FC<SearchResultProps> = ({data}) => {

    if(data.type === SearchType.Store){
        return (
            <div className="bg-slate-300 rounded-lg px-4 py-2">
                {(data.data as Store).name}
            </div>
        )
    }else{
        return (
            <div className="bg-slate-300 rounded-lg px-4 py-2">
                <p>{(data.data as Account).name}</p>
                <p className="text-sm text-gray-700">{(data.data as AccountExt).email}</p>
            </div>
        )
    }
}

export default SearchOverlay;