export function ReadableTime(dateTime: Date): string {
    const localTime = new Date(dateTime);
    return localTime.toLocaleDateString() + " " + localTime.toLocaleTimeString();
}

export function ReadableDate(dateTime: Date): string {
  const localTime = new Date(dateTime);
  return localTime.toLocaleDateString();
}

export function ReadableMoney(amount: number): string {
    const formatter = new Intl.NumberFormat('en-CA', {
        style: 'currency',
        currency: 'CAD',
      
        minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

    return formatter.format(amount);
}

export function toUTCDate(localDate: Date): string {
    // Get the timezone offset in minutes and convert it to milliseconds
    const timeZoneOffset = localDate.getTimezoneOffset() * 60000; // Offset is in minutes
    // Subtract the timezone offset from the local date's time
    const utcDate = new Date(localDate.getTime() + timeZoneOffset);
    console.log(utcDate.toISOString())
    return utcDate.toISOString();
  }