export enum Role
{
    Owner,
    Admin,
    StoreOwner,
    Accountant,
}

export enum OrgType
{
    Reseller,
    Group,
}