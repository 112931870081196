import {useFusionAuth} from "@fusionauth/react-sdk";
import {NavLink} from "react-router-dom";
import logo from '../SplashImage2.png'

export default function MenuBar() {
  const {isLoggedIn, userInfo, startLogin, startLogout, startRegister} = useFusionAuth();

  return (
    <div id="menu-bar" className="menu-bar top-0 h-16 w-screen m-0
    flex flex-row bg-tertiaryBlue text-white shadow-lg">
      <NavLink to="/"><img className="h-16 py-2 pl-2 w-36" src={logo}/></NavLink>
      <div className="flex flex-row w-full justify-end">
        {
          isLoggedIn && userInfo ? (
            <>
              <MenuBarLink text={userInfo.email} link="/account"/>
              <a className="menubar-link" style={{cursor: "pointer"}} onClick={() => startLogout()}>
                Logout
              </a>
            </>
          ) : (
            <>
              <MenuBarLink text="Sample Text" link="/account"/>
              <a className="menubar-link" style={{cursor: "pointer"}} onClick={() => startLogin()}>
                Login
              </a>
              <a className="menubar-link" style={{cursor: "pointer"}} onClick={() => startRegister()}>
                Register
              </a>
            </>
          )
        }
      </div>
    </div>
  )
}

const MenuBarLink = ({text, link}) => (
  <NavLink to={link} className="menu-link menubar-link" activeClassName="active">{text}</NavLink>
);
