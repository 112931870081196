import { FaPen, FaPeopleGroup } from "react-icons/fa6";
import { useQueryClient } from "react-query";
import { OrgType, Role } from "../Model/Roles";
import { OrgRole } from "../Model/Transactional";
import { CreateOrg, DeleteOrg, LeaveOrg, UpdateOrg } from "../Services/Requests";
import { Organization } from "../Model/Organization";
import { useState } from "react";
import { IoAddCircleOutline, IoArrowBack } from "react-icons/io5";
import FormInput from "./FormInput";
import { FaTrashAlt } from "react-icons/fa";
import { RequireAuth } from "@fusionauth/react-sdk";

export const OrgCard: React.FC<{ orgData: OrgRole; onSelectOrg: (orgID: number) => void; isSelected: boolean; }> = ({ orgData, onSelectOrg, isSelected, }) => {

    const queryClient = useQueryClient();

    const { mutateAsync, isLoading, isError } = UpdateOrg(queryClient)
    const { mutateAsync: deleteAsync, isLoading: isDeleting } = DeleteOrg(queryClient)
    const { isLoading: isleaving, mutateAsync: leaveAsync } = LeaveOrg(queryClient);

    const [copy, setCopy] = useState(orgData.org);
    const [editMode, setEditMode] = useState(false);

    const onChange = (e:any) => {
        setCopy({...copy, [e.target.name]: e.target.value})
    }
    const handleLeave = async (event: any) => {
        event.preventDefault();

        try {
            await leaveAsync(orgData.org.id);

        } catch (e) {
            console.error(e);
        }
    };

    const onEditClick = (state: boolean) => {
        setCopy(orgData.org)
        setEditMode(state)
    }
    const handleSubmit = async (event: any) => {
        event.preventDefault();

        try {
            let result = await mutateAsync({
                id: copy.id,
                linkCode: copy.linkCode,
                orgType: Number(copy.orgType),
                name: copy.name
            });

            if(result){
                setEditMode(false);
            }

        } catch (e) {
            console.error(e);
        }
    }
    const handleDelete = async () => {
        try {
            let result = await deleteAsync(copy.id);

             if(result){
                 //setEditMode(false);
             }

        } catch (e) {
            console.error(e);
        }
    }

    return(
        <div className={`bg-slate-300 rounded-2xl px-4 py-2 ${isSelected ? "border-2 bg-opacity-100" : "cursor-pointer bg-opacity-75 hover:bg-opacity-100"} transition-all duration-300 ease-linear border-black`}
            onClick={() => {
                onSelectOrg(orgData.org.id)
            }}
        >
            { !editMode ?
                <div>
                    <div className="flex justify-between items-center">
                        <div className="flex justify-between items-center space-x-2">
                            <h2 className="font-bold">{orgData.org.name}</h2>
                            { (orgData.role === Role.Admin || orgData.role === Role.Owner) &&
                                <FaPen size="18" className="items-center relative flex justify-center
                                hover:text-orange-500 transition-all duration-300 ease-linear cursor-pointer"
                                onClick={() => onEditClick(true)}/>
                            }
                        </div>
                        <div className="flex justify-right">
                            <FaPeopleGroup size="28" className="items-center relative flex justify-center"/>
                        </div>
                    </div>
                    <div className="flex justify-between">
                        <p className="text-sm font-semibold text-gray-700">{orgData.org.orgType === OrgType.Reseller ? "Reseller":"Group"}</p>
                        { orgData.role !== Role.Owner &&
                            <div>
                            { isleaving ?
                                <button className="shadow px-4 bg-red-700 text-white font-bold rounded focus:outline-none focus:shadow-outline" disabled>
                                Leaving
                                </button>
                                :
                                <button className="shadow px-4 bg-red-500 hover:bg-red-700 text-white font-bold rounded focus:outline-none focus:shadow-outline" onClick={handleLeave}>
                                Leave
                                </button>
                            }
                            </div>
                        }
                    </div>
                </div>
                :
                <form onSubmit={handleSubmit}>
                    <FormInput className="mb-4" label="Organization Name"
                    type="text" required={true} pattern="^(?! *$)[A-Za-z0-9\- .]+$" errorMessage="An organization name is required. (No special characters)" placeholder="My organization name"
                    name="name" value={copy.name} onChange={onChange}/>

                    <RequireAuth withRole={["SuperUser"]}>
                        <select name="orgType" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
                        focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        onChange={onChange} value={copy.orgType}
                        >
                            <option value={OrgType.Group}>Group</option>
                            <option value={OrgType.Reseller}>Reseller</option>
                        </select>
                    </RequireAuth>
                    
                    <div className="flex justify-between items-end">
                        <IoArrowBack size="28" className="items-center relative flex justify-center mt-1
                        hover:text-white transition-all duration-300 ease-linear cursor-pointer"
                        onClick={() => onEditClick(false)}/>
                        { orgData.role === Role.Owner &&
                            <div className="flex flex-col items-center">
                                <p className="text-sm">Delete Organization</p>
                                <FaTrashAlt size="28" className="items-center relative flex justify-center
                                hover:text-red-600 transition-all duration-300 ease-linear cursor-pointer"
                                onClick={() => handleDelete()}/>
                            </div>
                        }
                        {
                            isLoading ?
                            <button className="bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" disabled>
                              Processing...
                            </button>
                            :
                            isError ?
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                              Error
                            </button>
                            :
                            <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                            Save
                            </button>
                        }
                    </div>
                </form>
            }
        </div>
    )
}

export const NewOrgCard: React.FC<{  }> = () => {

    const queryClient = useQueryClient();
    const { mutateAsync, isLoading, isError } = CreateOrg(queryClient)
    const [editMode, setEditMode] = useState(false);
    let original:Organization = {
        id: -1,
        name: "",
        orgType: OrgType.Group
    };
    const [copy, setCopy] = useState<Organization>(original)

    const onChange = (e:any) => {
        setCopy({...copy, [e.target.name]: e.target.value})
    }

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        try {
            let result = await mutateAsync(copy);

            if(result){
                setEditMode(false);
                setCopy(original);
            }

        } catch (e) {
            console.error(e);
        }
    }

    const handleReset = (event: any) => {
        event.preventDefault();
        setEditMode(false);
        setCopy(original);
    }

    if(!editMode)
        return (
            <div className="relative flex rounded-2xl px-4 py-2 justify-center bg-slate-300 hover:bg-slate-600 hover:text-white
                transition-all duration-300 ease-linear cursor-pointer"
                onClick={() => setEditMode(true)}>
                <IoAddCircleOutline size="28"/>
            </div>
        );
    else
        return(
            <div className="bg-slate-300 rounded-2xl px-4 py-2">
                <form onSubmit={handleSubmit} onReset={handleReset}>
                    <FormInput className="mb-4" label="Organization Name"
                    type="text" required={true} pattern="^(?! *$)[A-Za-z0-9\- .]+$" errorMessage="An organization name is required. (No special characters)" placeholder="My organization name"
                    name="name" value={copy.name} onChange={onChange}/>
                    
                    <div className="flex justify-between">
                        <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="reset">
                            Cancel
                        </button>
                        {
                            isLoading ?
                            <button className="bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" disabled>
                              Processing...
                            </button>
                            :
                            isError ?
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                              Error
                            </button>
                            :
                            <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                            Save
                            </button>
                        }
                    </div>
                </form>
            </div>
        );
}