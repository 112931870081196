import { useState } from "react";

const FormInput = (props) => {
    const [focused, setFocused] = useState(false);
    const { label, errorMessage, onChange, ...inputProps} = props;

    const handleFocus = (e) => {
        setFocused(true);
    }

    return (
        <div className={props.className}>
            <label className="block text-gray-700 text-sm font-bold mb-2">{label}</label>
            <input {...inputProps} onChange={onChange} onBlur={handleFocus}
            className={`peer shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline group ${focused && "invalid:border-red-500"} `}
            />
            <p className={`text-red-500 invisible ${focused && "peer-invalid:visible"}`}>{errorMessage}</p>
        </div>
    )
}

export default FormInput