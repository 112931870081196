import { useMemo, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";

export const HiddenText: React.FC<{ input:string; className?:string }> = ({ input, className }) => {

    const [enabled, setEnabled] = useState(false);

    const GenerateNonsense = (input: string) => {
        return Array.from(input).map(char => {
            if (char === ' ' || char === '-') return char;
            return String.fromCharCode(Math.floor(Math.random() * 26) + 97);
          }).join('');
    }

    const nonsenseValue = useMemo(() => GenerateNonsense(input), [input])

    return (
        <div className={className + " flex"}>
            { enabled ?
                <FaEyeSlash size={23} className="items-center relative flex justify-center mt-1 cursor-pointer" onClick={() => setEnabled(false)}/>
                :
                <FaEye size={23} className="items-center relative flex justify-center mt-1 cursor-pointer" onClick={() => setEnabled(true)}/>
            }
            <span className={`${!enabled && "blur-sm"} ml-1`}>{enabled ? input : nonsenseValue}</span>
        </div>
    )
}