import { useEffect, useState } from "react";
import { Store } from "../../../Model/Store";
import { GetStoreCategories, GetStoreDepartments, GetStoreEmployees, GetStoreItems, GetStoreSuppliers, GetStoreTaxes } from "../../../Services/Requests";
import { ReadableMoney, ReadableTime } from "../../../Helper/ConversionHelper";
import FormInput from "../../../Components/FormInput";
import Select, { MultiValue } from 'react-select';
import PageNavigator from "../../../Components/PageNavigator";
import { ItemFilter, ItemSold, ItemView, ItemViewTemplate } from "../../../Model/POSModel/Item";
import { Department } from "../../../Model/POSModel/Department";
import { Category } from "../../../Model/POSModel/Category";
import { Supplier } from "../../../Model/POSModel/Supplier";
import ItemDetails from "../Components/ItemDetails";

export const ItemPage: React.FC<{ store:Store, isOrgStore?:boolean }> = ({ store, isOrgStore = false }) => {

    const template = {
        chunkSize: 100,
        page: 1,
        amountFrom: 0,
        amountTo: 0,
        property: "",
        departments: [],
        categories: [],
        suppliers: [],
        sold: ItemSold.AllItems
    };
    const [itemFilter, setItemFilter] = useState<ItemFilter>(template);
    const [selectedItem, setSelectedItem] = useState<ItemView | undefined>();

    const { isLoading:isLoadingSuppliers, data:supplierData } = GetStoreSuppliers(store.id);
    const { isLoading:isLoadingDepartments, data:departmentData } = GetStoreDepartments(store.id);
    const { isLoading:isLoadingCategories, data:categoryData } = GetStoreCategories(store.id);
    const { isLoading:isLoadingTaxes, data:taxData } = GetStoreTaxes(store.id);
    const { isLoading:isLoadingUsers, data:userData } = GetStoreEmployees(store.id);
    const { isLoading, data } = GetStoreItems(store.id, itemFilter);

    const HandleSetPageInfo = (page:number, chunkSize: number) => {
        setItemFilter({...itemFilter, ["page"]: page, ["chunkSize"]: chunkSize});
    }

    const HandleWindowExit = () => {
        setSelectedItem(undefined);
    }

    const HandleSetFilter = (filter: ItemFilter) => {
        setItemFilter({...filter, ["page"]: 1, ["chunkSize"]: itemFilter.chunkSize});
    }

    const NewItemHandlier = () => {
        setSelectedItem(ItemViewTemplate);
    }

    return(<div className="">
        <div className="flex justify-evenly pt-6 px-2 flex-row h-screen space-x-2">
        { isLoadingDepartments || isLoadingUsers || isLoadingSuppliers || isLoadingCategories || isLoadingTaxes ?
                <div>
                    Loading...
                </div>
                :
                departmentData && userData && supplierData && categoryData &&
                <div className="bg-offWhite shadow-xl rounded-3xl px-5 pb-8 pt-4 mb-4 flex flex-col w-full">
                <div className="flex justify-between items-center">
                        <h1 className='font-bold text-xl'>Items</h1>
                        {/* <button onClick={() => setSearchVisible(true)}>
                            <FaSearch />
                        </button> */}
                </div>
                <FilterComponent filter={itemFilter} setFilter={HandleSetFilter} departments={departmentData} categories={categoryData} suppliers={supplierData} newItemHandlier={NewItemHandlier}/>

                <div className="flex flex-row justify-between my-2">
                    <p className='text-sm'>Click on an item to view it's details.</p>

                    {data && <span>{data.totalCount} items</span>}
                    <PageNavigator totalCount={data?.totalCount} currentPage={itemFilter.page} chunkSize={itemFilter.chunkSize} setPageInfo={HandleSetPageInfo}/>
                </div>
                
                <div className="relative overflow-y-auto h-full flex flex-col items-center">
                <table className="w-full table-auto">
                <thead className="bg-slate-400 sticky top-0">
                    <tr>
                    <th>Company Managed</th>
                    <th>Barcode</th>
                    <th>Description</th>
                    <th>Department</th>
                    <th>Category</th>
                    <th>Available</th>
                    <th>Price</th>
                    <th>Last Updated</th>
                    </tr>
                </thead>
                <tbody>
                    {
                    !isLoading && data && departmentData && userData && data.items.map((item) => {
                        return (<tr key={item.syncGuid} className={`${selectedItem && selectedItem.syncGuid === item.syncGuid ? "bg-slate-500" : "even:bg-offWhite odd:bg-slate-300"}
                                border-b border-gray-700 hover:bg-slate-600 cursor-pointer`}
                                onClick={() => setSelectedItem(item)}
                                >
                        <td>
                            <div className="flex justify-center">
                            {item.isCompanyData ? "Yes" : "No"}
                            </div>
                        </td>
                        <td>
                            <div className="flex justify-center">
                            {item.barcode}
                            </div>
                        </td>
                        <td>
                            <div className="flex justify-center">
                            {item.description}
                            </div>
                        </td>
                        <td>
                            <div className="flex justify-center">
                            {item.department}
                            </div>
                        </td>
                        <td>
                            <div className="flex justify-center">
                            {item.category}
                            </div>
                        </td>
                        <td>
                            <div className="flex justify-center">
                            {item.available}
                            </div>
                        </td>
                        <td>
                            <div className="flex justify-center">
                            {ReadableMoney(item.price)}
                            </div>
                        </td>
                        <td>
                            <div className="flex justify-center">
                            {ReadableTime(item.lastUpdated)}
                            </div>
                        </td>
                        </tr>)
                    })
                    }
                </tbody>
                </table>
                {isLoading && <span className="mt-2">Loading...</span>}
                </div>
            </div>
            }

            {selectedItem && departmentData && categoryData && supplierData && userData && taxData && <ItemDetails selectedItem={selectedItem} storeID={store.id} onWindowExit={HandleWindowExit} isOrgStore={isOrgStore} departments={departmentData} categories={categoryData} suppliers={supplierData} taxes={taxData}/>}
            </div>
      </div>
    );
}

interface RegisterOption {
    value: string;
    label: string;
}

const FilterComponent: React.FC<{ filter:ItemFilter, setFilter(filter:ItemFilter):void, departments:Department[], categories:Category[], suppliers:Supplier[], newItemHandlier():void }> = ({ filter, setFilter, departments, categories, suppliers, newItemHandlier }) => {

    const [copy, setCopy] = useState(filter);

    const onChange = (e:any) => {
        setCopy({...copy, [e.target.name]: e.target.value})
    }
    const onChangeNumber = (e:any) => {
        copy && setCopy({...copy, [e.target.name]: Number(e.target.value)})
    }

    const onSubmit = (e:any) => {
        e.preventDefault();
        setFilter(copy);
    }

    const departmentOptions: RegisterOption[] = departments.map((department) => ({
        value: department.syncGuid,
        label: department.name,
    }));
    const handleDepartmentChange = (options: MultiValue<RegisterOption>) => {
        setCopy({ ...copy, departments: options.map((value) => value.value) });
        //console.log(copy);
    };

    const [categoryOptions, setCategoryOptions] = useState<RegisterOption[]>([]);
    const [selectedCats, setSelectedCats] = useState<RegisterOption[]>([]);
    useEffect(() =>{
        let filteredCats = categories.filter((cat) => copy.departments.some(x => x === cat.departmentID))

        setCopy({ ...copy, categories: [] });
        setSelectedCats([]);
        setCategoryOptions(filteredCats.map((category) => ({
            value: category.syncGuid,
            label: category.name,
        })));
    }, [copy.departments]);
    
    const handleCategoryChange = (options: MultiValue<RegisterOption>) => {
        setCopy({ ...copy, categories: options.map((value) => value.value) });
        setSelectedCats(options.flat());
        //console.log(copy);
    };

    const supplierOptions: RegisterOption[] = suppliers.map((supplier) => ({
        value: supplier.syncGuid,
        label: supplier.code,
    }));
    const handleSupplierChange = (options: MultiValue<RegisterOption>) => {
        setCopy({ ...copy, suppliers: options.map((value) => value.value) });
        //console.log(copy);
    };

    return (
        <div>
            <form onSubmit={onSubmit}>
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <label className="block text-gray-700 text-sm font-bold mb-2">Department</label>
                        <Select
                        isMulti
                        options={departmentOptions}
                        //value={copy.registers}
                        onChange={handleDepartmentChange}
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700 text-sm font-bold mb-2">Category</label>
                        <Select
                        isMulti
                        options={categoryOptions}
                        value={selectedCats}
                        onChange={handleCategoryChange}
                        />
                    </div>

                    <div>
                        <label className="block text-gray-700 text-sm font-bold mb-2">Supplier</label>
                        <Select
                        isMulti
                        options={supplierOptions}
                        //value={copy.registers}
                        onChange={handleSupplierChange}
                        />
                    </div>

                    <div>
                        <label className="block text-gray-700 text-sm font-bold mb-2">Sold</label>
                        <select name="sold" value={copy.sold} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
                            focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            onChange={onChangeNumber} required
                            >
                            <option value={ItemSold.AllItems}>All Items</option>
                            <option value={ItemSold.NotSold}>Not Sold</option>
                            <option value={ItemSold.Sold}>Sold</option>
                        </select>
                    </div>

                    <div>
                        <FormInput className="mb-4" label="Property"
                        type="text" pattern="^(?! *$)[A-Za-z0-9\- .]+$" placeholder="Barcode, Description, Item Number"
                        name="property" value={copy.property} onChange={onChange}/>
                    </div>
                    
                    <div>
                        <label className="block text-gray-700 text-sm font-bold">Amount ($)</label>
                        <div className="flex flex-row space-x-2">
                            <FormInput
                            type="number" min="0.00" step="0.01"
                            name="amountFrom" value={copy.amountFrom} onChange={onChangeNumber}/>
                            <label className="text-gray-700 text-sm font-bold mt-4">~</label>
                            <FormInput
                            type="number" min="0.00" step="0.01"
                            name="amountTo" value={copy.amountTo} onChange={onChangeNumber}/>
                        </div>
                    </div>
                </div>

                <div className="flex justify-between">
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                        Filter
                    </button>

                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={newItemHandlier}>
                        New Item
                    </button>
                </div>
            </form>
        </div>
    );
}