import React, { useState } from 'react';
import { FiSun, FiMoon } from 'react-icons/fi';

const ToggleSwitch = ({ size = 1, initialState = false, onChange }) => {
  const [enabled, setEnabled] = useState(initialState);

  // Base size for the switch and icons
  const baseSize = 24; // This is an arbitrary base size for the icons
  const switchWidth = `${size * 3.5}rem`; // Adjust the width based on the size prop
  const switchHeight = `${size * 1.75}rem`; // Adjust the height based on the size prop
  const iconSize = size * baseSize; // Adjust icon size
  const dotSize = `calc(${switchHeight} - 0.5rem)`; // Adjust the dot size based on the switch height
  const translateX = `calc(${switchWidth} - ${dotSize} - 0.5rem)`; // Calculate the translation distance

  const onToggle = () =>{
    onChange(enabled);
    setEnabled(!enabled);
  };

  return (
    <label className="flex items-center cursor-pointer group" style={{ fontSize: `${size}rem` }}>
      <div className="relative" style={{ width: switchWidth, height: switchHeight }}>
        {/* Toggle */}
        <input type="checkbox" id="toggle" className="sr-only" checked={enabled} onChange={onToggle} />
        {/* Line */}
        <div className={`block ${enabled ? "bg-green-600 group-hover:bg-green-800" : "bg-gray-600 group-hover:bg-gray-800"} transition-all duration-300 ease-linear w-full h-full rounded-full`}></div>
        {/* Dot */}
        <div className="dot absolute left-1 top-1 bg-white rounded-full transition-transform" style={{
          width: dotSize,
          height: dotSize,
          transform: enabled ? `translateX(${translateX})` : 'none',
          transition: 'transform 0.2s ease-in-out'
        }}></div>
      </div>
      {/* Icon */}
      {/* <div className="ml-3 text-gray-700 font-medium" style={{ fontSize: `${iconSize}px` }}>
        {enabled ? <FiSun className="text-yellow-500" /> : <FiMoon className="text-blue-500" />}
      </div> */}
    </label>
  );
};

export default ToggleSwitch;
