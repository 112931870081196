import { Store, StoreStatus } from "../Model/Store";
import FormInput from "../Components/FormInput";
import { StoreRegisters } from "../Model/Transactional";
import {forwardRef, useState} from "react";
import { IoAddCircleOutline, IoArrowBack } from "react-icons/io5";
import { FaStore, FaStoreSlash, FaPen } from "react-icons/fa";
import { CreateStore, DeleteStore, DisableStore, EnableStore, UpdateStore } from "../Services/Requests";

import { useQueryClient } from 'react-query';
import ToggleSwitch from "./ToggleSwitch";
import { RequireAuth } from "@fusionauth/react-sdk";

type StoreCardProps = {
    storeData: StoreRegisters,
    onSelectStore: (storeID: number) => void,
    isSelected: boolean
}

export const StoreCard = forwardRef<HTMLDivElement, StoreCardProps>(({ storeData, onSelectStore, isSelected }, ref) => {

    const queryClient = useQueryClient();
    const { mutateAsync, isLoading, isError } = UpdateStore(queryClient)
    const { mutateAsync: deleteAsync, isLoading: isDeleting } = DeleteStore(queryClient)
    const { mutateAsync: enableAsync, isLoading: isEnabling } = EnableStore(queryClient)
    const { mutateAsync: disableAsync, isLoading: isDisabling } = DisableStore(queryClient)
    const [editMode, setEditMode] = useState(false);
    const [copy, setCopy] = useState(storeData.store)

    const onChange = (e:any) => {
        setCopy({...copy, [e.target.name]: e.target.value})
    }
    const onToggle = async (state: boolean) => {
        if(state){
            let result = await disableAsync(copy.id);
        }else{
            let result = await enableAsync(copy.id);
        }
    }
    const onEditClick = (state: boolean) => {
        setCopy(storeData.store)
        setEditMode(state)
    }
    const handleSubmit = async (event: any) => {
        event.preventDefault();

        try {
            let result = await mutateAsync(copy);

            if(result){
                setEditMode(false);
            }

        } catch (e) {
            console.error(e);
        }
    }
    const handleDelete = async () => {
        try {
            let result = await deleteAsync(copy.id);

             if(result){
                 //setEditMode(false);
             }

        } catch (e) {
            console.error(e);
        }
    }

        return (
            <div className={`bg-slate-300 rounded-2xl px-4 py-2 ${isSelected ? "border-2 bg-opacity-100" : "cursor-pointer bg-opacity-75 hover:bg-opacity-100"} transition-all duration-300 ease-linear border-black`}
                onClick={() => {
                    onSelectStore(storeData.store.id)
                }}
            >
                { !editMode ?

                    <div ref={ref}>
                        <div className="flex justify-between items-center">
                            <div className="flex justify-between items-center space-x-2">
                                <h2 className="font-bold text-lg">{storeData.store.name}</h2>
                                <FaPen size="18" className="items-center relative flex justify-center
                                hover:text-orange-500 transition-all duration-300 ease-linear cursor-pointer"
                                onClick={() => onEditClick(true)}/>
                                <RequireAuth withRole={["SuperUser", "Reseller"]}>
                                    <ToggleSwitch size={0.75} initialState={storeData.store.status === StoreStatus.Active} onChange={onToggle}/>
                                </RequireAuth>
                            </div>
                            
                            <div className="flex justify-between items-center space-x-2">
                                <p className={`items-center relative flex justify-center
                                ${ storeData.store.status === StoreStatus.Active ? "bg-green-500" : storeData.store.status === StoreStatus.Requested ? "bg-yellow-500" : "bg-red-500"}
                                rounded-md text-white p-1 h-8`} >{storeData.store.status === StoreStatus.Active ? "Active" : storeData.store.status === StoreStatus.Requested ? "Requested" : "Deactivated"}</p>
                                
                                <FaStore size="45" className="items-center relative flex justify-center"/>
                            </div>
                        </div>
                        <p className="text-sm text-gray-700 -my-1">{storeData.store.address}</p>
                        <p className="text-sm text-gray-700 -my-1">{storeData.store.city}</p>
                        <p className="text-sm text-gray-700 -my-1">{storeData.store.province}</p>
                        <p className="text-sm text-gray-700 -my-1">{storeData.store.postalCode}</p>
                        <div className="flex justify-between mt-2 items-end">
                            <p className="text-sm text-gray-700">POS Machines: {storeData.posSystems.length}</p>
                            { storeData.store.status !== StoreStatus.Active &&
                            <div className="flex flex-col items-center">
                                <p className="text-sm">Delete Store</p>
                                <FaStoreSlash size="28" className="items-center relative flex justify-center
                                hover:text-red-600 transition-all duration-300 ease-linear cursor-pointer"
                                onClick={() => handleDelete()}/>
                            </div>
                            }
                        </div>
                    </div>
                    :
                    <form onSubmit={handleSubmit}>
                        <FormInput className="mb-4" label="Store Name"
                        type="text" required={true} pattern="^(?! *$)[A-Za-z0-9\- .]+$" errorMessage="An store name is required. (No special characters)" placeholder="My gift shop, convenience, etc."
                        name="name" value={copy.name} onChange={onChange}/>
                        <FormInput className="mb-4" label="Address"
                        type="text" required={true} pattern="^(?! *$)[A-Za-z0-9\- .]+$" errorMessage="An address is required. (No special characters)" placeholder="7-35 Goderich Rd"
                        name="address" value={copy.address} onChange={onChange}/>
                        <FormInput className="mb-4" label="City"
                        type="text" required={true} pattern="^(?! *$)[A-Za-z0-9\- .]+$" errorMessage="A city is required. (No special characters)" placeholder="Hamilton"
                        name="city" value={copy.city} onChange={onChange}/>

                        <div className="mb-8">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Province</label>
                            <select name="province" value={copy.province} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
                                focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                onChange={onChange} required
                            >
                                <option value="">Select an option</option>
                                <option value="Ontario">Ontario</option>
                                <option value="British Columbia">British Columbia</option>
                            </select>
                        </div>

                        <FormInput className="mb-4" label="Postal Code"
                        type="text" required={true} pattern="^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z] [0-9][ABCEGHJ-NPRSTV-Z][0-9]$" errorMessage="A valid postal code is required. (Ex. L6E 1M6)" placeholder="L8E 4P2"
                        name="postalCode" value={copy.postalCode} onChange={onChange}/>
                        <div className="flex justify-between">
                            <IoArrowBack size="28" className="items-center relative flex justify-center mt-1
                            hover:text-white transition-all duration-300 ease-linear cursor-pointer"
                            onClick={() => onEditClick(false)}/>

                            {
                                isLoading ?
                                <button className="bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" disabled>
                                  Processing...
                                </button>
                                :
                                isError ?
                                <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                                  Error
                                </button>
                                :
                                <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                                Save
                                </button>
                            }
                        </div>
                    </form>
                }
                
            </div>
        );
});

export const NewStoreCard: React.FC<{ merchantID?: string }> = ({ merchantID }) => {

    const queryClient = useQueryClient();
    const { mutateAsync, isLoading, isError } = CreateStore(queryClient, merchantID)
    const [editMode, setEditMode] = useState(false);
    let original:Store = {
        id: -1,
        name: "",
        province: "",
        address: "",
        city: "",
        postalCode: "",
        status: StoreStatus.Requested
    };
    const [copy, setCopy] = useState<Store>(original)

    const onChange = (e:any) => {
        setCopy({...copy, [e.target.name]: e.target.value})
    }

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        try {
            let result = await mutateAsync(copy);

            if(result){
                setEditMode(false);
                setCopy(original);
            }

        } catch (e) {
            console.error(e);
        }
    }

    const handleReset = (event: any) => {
        event.preventDefault();
        setEditMode(false);
        setCopy(original);
    }

    if(!editMode)
        return (
            <div className="relative flex rounded-2xl px-4 py-2 justify-center bg-slate-300 hover:bg-slate-600 hover:text-white
                transition-all duration-300 ease-linear cursor-pointer"
                onClick={() => setEditMode(true)}>
                <IoAddCircleOutline size="28"/>
            </div>
        );
    else
        return(
            <div className={`bg-slate-300 rounded-2xl px-4 py-2
            transition-all duration-300 ease-linear`}
                
            >
                <form onSubmit={handleSubmit} onReset={handleReset}>
                    <FormInput className="mb-4" label="Store Name"
                    type="text" required={true} pattern="^(?! *$)[A-Za-z0-9\- .]+$" errorMessage="An store name is required. (No special characters)" placeholder="My gift shop, convenience, etc."
                    name="name" value={copy.name} onChange={onChange}/>
                    <FormInput className="mb-4" label="Address"
                    type="text" required={true} pattern="^(?! *$)[A-Za-z0-9\- .]+$" errorMessage="An address is required. (No special characters)" placeholder="7-35 Goderich Rd"
                    name="address" value={copy.address} onChange={onChange}/>
                    <FormInput className="mb-4" label="City"
                    type="text" required={true} pattern="^(?! *$)[A-Za-z0-9\- .]+$" errorMessage="A city is required. (No special characters)" placeholder="Hamilton"
                    name="city" value={copy.city} onChange={onChange}/>

                    <div className="mb-8">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Province</label>
                        <select name="province" value={copy.province} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
                            focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            onChange={onChange} required
                        >
                            <option value="">Select an option</option>
                            <option value="Ontario">Ontario</option>
                            <option value="British Columbia">British Columbia</option>
                        </select>
                    </div>

                    <FormInput className="mb-4" label="Postal Code"
                    type="text" required={true} pattern="^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z] [0-9][ABCEGHJ-NPRSTV-Z][0-9]$" errorMessage="A valid postal code is required. (Ex. L6E 1M6)" placeholder="L8E 4P2"
                    name="postalCode" value={copy.postalCode} onChange={onChange}/>
                    <div className="flex justify-between">
                        <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="reset">
                            Cancel
                        </button>
                        {
                            isLoading ?
                            <button className="bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" disabled>
                              Processing...
                            </button>
                            :
                            isError ?
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                              Error
                            </button>
                            :
                            <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                            Save
                            </button>
                        }
                    </div>
                </form>
            </div>
        );
}