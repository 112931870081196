import { IoAddCircleOutline, IoArrowBack } from "react-icons/io5";
import FormCheckbox from "../../../Components/FormCheckbox";
import FormInput from "../../../Components/FormInput";
import { ReadableTime } from "../../../Helper/ConversionHelper";
import { useQueryClient } from "react-query";
import { useState } from "react";
import { FaEnvelope, FaPen, FaTrashAlt } from "react-icons/fa";
import { ItemMessage } from "../../../Model/POSModel/ItemMessage";
import { CreateStoreItemMessage, DeleteStoreItemMessage, UpdateStoreItemMessage } from "../../../Services/Requests";

export const ItemMessageCard: React.FC<{ itemMessage: ItemMessage, storeID:number, isOrgStore?:boolean }> = ({ itemMessage, storeID, isOrgStore = false }) => {

    const [editMode, setEditMode] = useState(false);

    return(
        <div className={`bg-slate-300 rounded-2xl px-4 pt-2 pb-4 transition-all duration-300 ease-linear border-black`}>
            {   !editMode &&
                <div className="flex justify-between items-center">
                    <div className="flex justify-between items-center space-x-2">
                        <h2 className="font-bold text-lg">{itemMessage.title}</h2>
                        {isOrgStore === itemMessage.isCompanyData && <FaPen size="18" className="items-center relative flex justify-center
                        hover:text-orange-500 transition-all duration-300 ease-linear cursor-pointer"
                        onClick={() => setEditMode(true)}/>}
                    </div>
                    
                    <div className="flex justify-between items-center space-x-2">
                        <p className={`items-center relative flex justify-center
                        ${ itemMessage.active ? "bg-green-500" : "bg-red-500"}
                        rounded-md text-white p-1 h-8`} >{itemMessage.active ? "Enabled" : "Disabled"}</p>
                        
                        <FaEnvelope size="45" className="items-center relative flex justify-center"/>
                    </div>
                </div>
            }
            

            { editMode ?
                <ItemMessageEdit itemMessage={itemMessage} setEditMode={setEditMode} storeID={storeID}/>
                :
                <ItemMessageInfo itemMessage={itemMessage} />
            }
        </div>
    );
}


interface FormProps {
    handleSubmit(e: any): void,
    onChange(e: any): void,
    onCheckChange(e: any): void,
    setEditMode(state: boolean): void,
    handleDelete?(): void,
    copy: ItemMessage,
    isLoading: boolean,
    isError: boolean
}

const ItemMessageForm: React.FC<FormProps> = ({handleSubmit, onChange, onCheckChange, setEditMode, handleDelete, copy, isLoading, isError}) => {


    return (
        <form onSubmit={handleSubmit}>
            <FormInput className="mb-4" label="Name"
            type="text" required={true} pattern="^(?! *$)[A-Za-z0-9\- .]+$" errorMessage="An title is required. (No special characters)" placeholder="Alcohol age limit"
            name="title" value={copy.title} onChange={onChange}/>

            <FormInput className="mb-4" label="Age Limit"
            type="number" required={true} pattern="^(?! *$)[0-9\- .]+$" errorMessage="" placeholder="D1"
            name="ageLimit" value={copy.ageLimit} onChange={onChange} min={0}/>

            <FormCheckbox className="mb-4 flex space-x-2" label="Active"
            name="active" checked={copy.active} onChange={onCheckChange} />

            <FormInput className="mb-4" label="Message"
            type="text" required={true} pattern="^(?! *$)[A-Za-z0-9\-\[\] .]+$" errorMessage="A message is required. (No special characters)" placeholder="Customer must be a minimum age of 19."
            name="message" value={copy.message} onChange={onChange}/>

            <FormInput className="mb-4" label="Display Time (s)"
            type="number" required={true} pattern="^(?! *$)[0-9\- .]+$" errorMessage="Display time must be greater than 0." placeholder="1"
            name="displayTime" value={copy.displayTime} onChange={onChange} min={1}/>

            <div className="flex justify-between">
                <IoArrowBack size="28" className="items-center relative flex justify-center mt-1
                    hover:text-white transition-all duration-300 ease-linear cursor-pointer"
                    onClick={() => setEditMode(false)}/>

                { handleDelete &&
                    <div className="flex flex-col items-center">
                        <p className="text-sm">Delete</p>
                        <FaTrashAlt size="28" className="items-center relative flex justify-center
                        hover:text-red-600 transition-all duration-300 ease-linear cursor-pointer"
                        onClick={() => handleDelete()}/>
                    </div>
                }

                {
                    isLoading ?
                    <button className="bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" disabled>
                        Processing...
                    </button>
                    :
                    isError ?
                    <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                        Error
                    </button>
                    :
                    <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                    Save
                    </button>
                }
            </div>
        </form>
    );
}

const ItemMessageInfo: React.FC<{ itemMessage: ItemMessage }> = ({ itemMessage }) => {


    return(
        <div>
            <p className="text-sm text-gray-700 -my-1">
                <span className="font-bold">Message: </span>
                <p>{itemMessage.message}</p>
            </p>
            <p className="text-sm text-gray-700 -my-1">
                <span className="font-bold">Age Limit: </span>
                {itemMessage.ageLimit}
            </p>
            <p className="text-sm text-gray-700 -my-1">
                <span className="font-bold">Company managed: </span>
                {itemMessage.isCompanyData ? "Yes" : "No"}
            </p>
            <p className="text-sm text-gray-700 -my-1">
                <span className="font-bold">Display time: </span>
                {itemMessage.displayTime}(s)
            </p>
            <p className="text-sm text-gray-700 -my-1">
                <span className="font-bold">Last modified: </span>
                {ReadableTime(itemMessage.modifyDatetime)}
            </p>
        </div>
    );
}


const ItemMessageEdit: React.FC<{ itemMessage: ItemMessage, storeID: number, setEditMode: (state: boolean) => void }> = ({ itemMessage, setEditMode, storeID }) => {

    const queryClient = useQueryClient();
    const { mutateAsync, isLoading, isError } = UpdateStoreItemMessage(queryClient, storeID);
    const { mutateAsync: deleteAsync, isLoading: isDeleting } = DeleteStoreItemMessage(queryClient, storeID)
    const [copy, setCopy] = useState(itemMessage);

    const onChange = (e:any) => {
        setCopy({...copy, [e.target.name]: e.target.value})
    }
    const onCheckChange = (e:any) => {
        setCopy({...copy, [e.target.name]: e.target.checked})
        console.log(copy);
    }
    const handleSubmit = async (event: any) => {
        event.preventDefault();

        try {
            let result = await mutateAsync(copy);

            if(result){
                setEditMode(false);
            }

        } catch (e) {
            console.error(e);
        }
    }
    const handleDelete = async () => {
        try {
            let result = await deleteAsync(copy);

            // if(result){
            //     setEditMode(false);
            // }

        } catch (e) {
            console.error(e);
        }
    }

    return(
        <ItemMessageForm handleSubmit={handleSubmit} onChange={onChange} onCheckChange={onCheckChange} setEditMode={setEditMode} handleDelete={handleDelete} copy={copy} isLoading={isLoading} isError={isError} />
    );
}

export const ItemMessageNew: React.FC<{ storeID: number }> = ({ storeID }) => {
    const [editMode, setEditMode] = useState(false);

    const queryClient = useQueryClient();
    const { mutateAsync, isLoading, isError } = CreateStoreItemMessage(queryClient, storeID);

    const template = {
        title: "",
        message: "",
        active: true,
        modifyUser: "00000000-0000-0000-0000-000000000000",
        modifyDatetime: new Date,
        syncGuid: "00000000-0000-0000-0000-000000000000", //Placeholder - replaced by server
        isCompanyData: false,
        soundFileId: 0,
        displayTime: 1,
        ageLimit: 0
    };

    const [copy, setCopy] = useState<ItemMessage>(template);

    const resetEditMode = (state: boolean) => {
        setEditMode(state);
        setCopy(template);
    }

    const onChange = (e:any) => {
        setCopy({...copy, [e.target.name]: e.target.value})
        console.log(copy);
    }
    const onCheckChange = (e:any) => {
        setCopy({...copy, [e.target.name]: e.target.checked})
        console.log(copy);
    }


    const handleSubmit = async (event: any) => {
        event.preventDefault();

        try {
            let result = await mutateAsync(copy);

            if(result){
                resetEditMode(false);
            }

        } catch (e) {
            console.error(e);
        }
    }

    return (
        <div className={`relative flex rounded-2xl px-4 py-2 justify-center bg-slate-300
                transition-all duration-300 ease-linear ${editMode ? "" : "cursor-pointer hover:bg-slate-600 hover:text-white" }`}
                onClick={() => !editMode && setEditMode(true)}>


                { !editMode ?
                    <IoAddCircleOutline size="28"/>
                    :
                    <ItemMessageForm handleSubmit={handleSubmit} onChange={onChange} onCheckChange={onCheckChange} setEditMode={resetEditMode} copy={copy} isLoading={isLoading} isError={isError} />
                }
                
            </div>
    );
}