import {
  Navigate,
  Routes,
  Route,
} from "react-router-dom";
import {useFusionAuth} from "@fusionauth/react-sdk";
import Home from "./Pages/Home"
import Account from './Pages/Account';
import MenuBar from "./Components/MenuBar";
import SideBar from './Components/SideBar';
import ActivePOS from './Pages/ActivePOS';
import Landing from './Pages/Landing';
import MyStore from './Pages/MyStores';
import ManageClients from './Pages/ManageClients';
import MyOrgs from './Pages/MyOrgs';
import ManageStore from './Pages/ManageStore/ManageStore';
import ManageOrgStore from './Pages/ManageOrgStore/ManageOrgStore';
import ViewStoreTransactions from './Pages/Transactions/ViewStoreTransactions';

function App() {
  const {isFetchingUserInfo, isLoggedIn} = useFusionAuth();

  if (isFetchingUserInfo) {
    return null;
  }

    return (
      <div id="page-container" className="flex flex-col h-screen bg-secondaryBlue">
      {isLoggedIn && <div id="page-header" className="w-full">
        {/* <LogoHeader/> */}
        <MenuBar/>
      </div>}

      <div className='flex flex-1 min-h-0'>
        {isLoggedIn && <div className="sidebar w-16 overflow-auto">
          <SideBar />
        </div> }
        <div className='content flex-1 overflow-auto'>
          <Routes>
            <Route path="/" element={<Landing/>}/>
            <Route path="/home" element={<Home/>}/>
            <Route path="/account" element={<Account/>}/>
            <Route path="/activepos" element={<ActivePOS/>}/>
            <Route path="/mystores" element={<MyStore/>}/>
            <Route path="/managestore" element={<ManageStore/>}/>
            <Route path="/manageclients" element={<ManageClients/>}/>
            <Route path="/manageorgstore" element={<ManageOrgStore/>}/>
            <Route path="/viewstoretransactions" element={<ViewStoreTransactions/>}/>
            <Route path="/myorgs" element={<MyOrgs/>}/>
            <Route path="*" element={<Navigate to="/"/>}/>
          </Routes>
        </div>
        
      </div>
    </div>
    )
  }
  
  export default App