import FormInput from "../Components/FormInput";
import { AccountStoresRegisters } from "../Model/Transactional";
import {forwardRef, useState} from "react";
import { IoSettingsSharp } from "react-icons/io5";
import { FaStore } from "react-icons/fa";
import { DeleteStore, UpdateStore } from "../Services/Requests";
import { useQueryClient } from 'react-query';

type MerchantCardProps = {
    merchantData: AccountStoresRegisters,
    onSelectMerchant: (merchantID: string) => void,
    isSelected: boolean
}

export const MerchantCard = forwardRef<HTMLDivElement, MerchantCardProps>(({ merchantData, onSelectMerchant, isSelected }, ref) => {

    const queryClient = useQueryClient();
    const { mutateAsync, isLoading } = UpdateStore(queryClient)
    const { mutateAsync: deleteAsync, isLoading: isDeleting } = DeleteStore(queryClient)
    const [editMode, setEditMode] = useState(false);
    const [copy, setCopy] = useState(merchantData.account)

    const onChange = (e:any) => {
        setCopy({...copy, [e.target.name]: e.target.value})
    }
    const onEditClick = (state: boolean) => {
        setCopy(merchantData.account)
        setEditMode(state)
    }
    const handleSubmit = async (event: any) => {
        // event.preventDefault();

        // try {
        //     let result = await mutateAsync(copy);

        //     if(result){
        //         setEditMode(false);
        //     }

        // } catch (e) {
        //     console.error(e);
        // }
    }
    // const handleDelete = async () => {
    //     try {
    //         let result = await deleteAsync(copy.id);

    //          if(result){
    //              //setEditMode(false);
    //          }

    //     } catch (e) {
    //         console.error(e);
    //     }
    // }

    return (
        <div ref={ref} className={`bg-slate-300 rounded-2xl px-4 py-2 ${isSelected ? "border-2 bg-opacity-100" : "cursor-pointer bg-opacity-75 hover:bg-opacity-100"} transition-all duration-300 ease-linear border-black`}
            onClick={() => {
                onSelectMerchant(merchantData.account.fusionAuthID)
            }}
        >
            { !editMode ?
                <div>
                    <div className="flex justify-between">
                        <h2 className="font-bold">{merchantData.account.name}</h2>
                        <FaStore size="28" className="items-center relative flex justify-center mt-1"/>
                    </div>
                    <p className="text-sm text-gray-700">{merchantData.account.phone}</p>
                    <p className="text-sm text-gray-700">{merchantData.account.email}</p>
                    <div className="flex justify-between mt-2 items-end">
                        <p className="text-sm text-gray-700">Stores: {merchantData.stores.length}</p>
                        {/* <IoSettingsSharp size="28" className="items-center relative flex justify-center mt-1
                        hover:rotate-45 transition-all duration-300 ease-linear cursor-pointer"
                        onClick={() => onEditClick(true)}/> */}
                        {/* <FaStoreSlash size="28" className="items-center relative flex justify-center mt-1
                        hover:text-red-600 transition-all duration-300 ease-linear cursor-pointer"
                        onClick={() => handleDelete()}/> */}
                    </div>
                </div>
                :
                <form onSubmit={handleSubmit}>{/* Unimplemented */}
                    <FormInput className="mb-4" label="Merchant Name"
                    type="text" required={true} pattern="^(?! *$)[A-Za-z0-9\- .]+$" errorMessage="An store name is required. (No special characters)" placeholder="My gift shop, convenience, etc."
                    name="name" value={copy.name} onChange={onChange}/>
                    <div className="flex justify-between">
                        {
                            !isLoading ?
                            <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                            Save
                            </button>
                            :
                            <button className="bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" disabled>
                            {/* <svg className="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24">
                                
                            </svg> */}
                                Processing...
                            </button>
                        }
                        
                        <IoSettingsSharp size="28" className="items-center relative flex justify-center mt-1
                        hover:rotate-45 transition-all duration-300 ease-linear cursor-pointer"
                        onClick={() => onEditClick(false)}/>
                    </div>
                </form>
            }
        </div>
    );
});

// export const NewMerchantCard = () => {

//     const queryClient = useQueryClient();
//     const { mutateAsync, isLoading } = CreateStore(queryClient)
//     const [editMode, setEditMode] = useState(false);
//     let original:Store = {
//         id: -1,
//         name: "",
//         address: "",
//         city: "",
//         postalCode: "",
//         enabled: false
//     };
//     const [copy, setCopy] = useState<Store>(original)

//     const onChange = (e:any) => {
//         setCopy({...copy, [e.target.name]: e.target.value})
//     }

//     const handleSubmit = async (event: any) => {
//         event.preventDefault();

//         try {
//             let result = await mutateAsync(copy);

//             if(result){
//                 setEditMode(false);
//                 setCopy(original);
//             }

//         } catch (e) {
//             console.error(e);
//         }
//     }

//     const handleReset = (event: any) => {
//         event.preventDefault();
//         setEditMode(false);
//         setCopy(original);
//     }

//     if(!editMode)
//         return (
//             <div className="relative flex m-4 justify-center rounded bg-slate-300 hover:bg-slate-600 hover:text-white
//                 transition-all duration-300 ease-linear cursor-pointer border border-black"
//                 onClick={() => setEditMode(true)}>
//                 <IoAddCircleOutline size="28"/>
//             </div>
//         );
//     else
//         return(
//             <div className={`bg-slate-200 rounded m-4 border border-black px-2 pb-2
//             transition-all duration-300 ease-linear`}
                
//             >
//                 <form onSubmit={handleSubmit} onReset={handleReset}>
//                     <FormInput className="mb-4" label="Store Name"
//                     type="text" required={true} pattern="^(?! *$)[A-Za-z0-9\- .]+$" errorMessage="An store name is required." placeholder="My gift shop, convenience, etc."
//                     name="name" value={copy.name} onChange={onChange}/>
//                     <FormInput className="mb-4" label="Address"
//                     type="text" required={true} pattern="^(?! *$)[A-Za-z0-9\- .]+$" errorMessage="An address is required." placeholder="7-35 Goderich Rd."
//                     name="address" value={copy.address} onChange={onChange}/>
//                     <FormInput className="mb-4" label="City"
//                     type="text" required={true} pattern="^(?! *$)[A-Za-z0-9\- .]+$" errorMessage="A city is required." placeholder="7-35 Goderich Rd."
//                     name="city" value={copy.city} onChange={onChange}/>
//                     <FormInput className="mb-4" label="Postal Code"
//                     type="text" required={true} pattern="^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z] [0-9][ABCEGHJ-NPRSTV-Z][0-9]$" errorMessage="A valid postal code is required." placeholder="L8E 4P2"
//                     name="postalCode" value={copy.postalCode} onChange={onChange}/>
//                     <div className="flex justify-between">
//                         {
//                             !isLoading ?
//                             <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
//                             Save
//                             </button>
//                             :
//                             <button className="bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" disabled>
//                             {/* <svg className="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24">
                                
//                             </svg> */}
//                                 Processing...
//                             </button>
//                         }
//                         <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="reset">
//                             Cancel
//                         </button>
//                     </div>
//                 </form>
//             </div>
//         );
// }