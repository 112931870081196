import { ItemPreview } from "./Item";

type QuantityDiscount = {
    description: string;
    discountOddItems: boolean;
    quantity1: number;
    price1: number;
    price1A: number;
    price1B: number;
    price1C: number;
    quantity2: number;
    price2: number;
    price2A: number;
    price2B: number;
    price2C: number;
    quantity3: number;
    price3: number;
    price3A: number;
    price3B: number;
    price3C: number;
    quantity4: number;
    price4: number;
    price4A: number;
    price4B: number;
    price4C: number;
    type: number;
    percentOffRegularPrice1: number;
    percentOffRegularPrice2: number;
    percentOffRegularPrice3: number;
    percentOffRegularPrice4: number;
    percentOffPrice1A: number;
    percentOffPrice2A: number;
    percentOffPrice3A: number;
    percentOffPrice4A: number;
    percentOffPrice1B: number;
    percentOffPrice2B: number;
    percentOffPrice3B: number;
    percentOffPrice4B: number;
    percentOffPrice1C: number;
    percentOffPrice2C: number;
    percentOffPrice3C: number;
    percentOffPrice4C: number;
    lastUpdated: Date;
    active: boolean;
    extendedPriceDiscount: boolean;
    modifyUser: string;
    syncGuid: string;
    isCompanyData: boolean;
    startDate: Date;
    endDate: Date;
    ignoreOtherDiscount: boolean;
    showDiscountMessage: boolean;
    modifyDatetime: Date;
  };  
  
export default QuantityDiscount;

export type QuantityDiscountFilter = {
  chunkSize: number,
  page: number,
  search: string
}

export type QuantityDiscountPackage = {
  totalCount: number,
  discounts: QuantityDiscountView[]
}

export type QuantityDiscountView = {
  syncGuid: string,
  type: QuantityDiscountType,
  description: string,
  startDate: Date,
  endDate: Date,
  lastUpdated: Date
}

export enum QuantityDiscountType{

  /** Mix and Match: Price */
  MMPrice = 1,

  /** Mix and Match: Percent off */
  MMPercentOff = 3,

  /** Buy X, Get Y */
  BuyXGetY = 2,

  /** Mix and Match: Price(Basket) */
  MMPriceBasket = 4,

  /** Mix and Match: Percent off(Basket) */
  MMPercentOffBasket = 5,
}

export type Basket = {
  number: number,
  items: ItemPreview[]
}

export type QuantityDiscountInjestPackage = {
  quantityDiscount: QuantityDiscount,
  baskets: Basket[]
}

export const Template: QuantityDiscount = {
  description: "",
  discountOddItems: false,
  quantity1: 1,
  price1: 0.00,
  price1A: 0.00,
  price1B: 0.00,
  price1C: 0.00,
  quantity2: 0,
  price2: 0,
  price2A: 0.00,
  price2B: 0.00,
  price2C: 0.00,
  quantity3: 0,
  price3: 0.00,
  price3A: 0.00,
  price3B: 0.00,
  price3C: 0.00,
  quantity4: 0,
  price4: 0.00,
  price4A: 0.00,
  price4B: 0.00,
  price4C: 0.00,
  type: QuantityDiscountType.MMPrice,
  percentOffRegularPrice1: 0.00,
  percentOffRegularPrice2: 0.00,
  percentOffRegularPrice3: 0.00,
  percentOffRegularPrice4: 0.00,
  percentOffPrice1A: 0.00,
  percentOffPrice2A: 0.00,
  percentOffPrice3A: 0.00,
  percentOffPrice4A: 0.00,
  percentOffPrice1B: 0.00,
  percentOffPrice2B: 0.00,
  percentOffPrice3B: 0.00,
  percentOffPrice4B: 0.00,
  percentOffPrice1C: 0.00,
  percentOffPrice2C: 0.00,
  percentOffPrice3C: 0.00,
  percentOffPrice4C: 0.00,
  lastUpdated: new Date(),
  active: true,
  extendedPriceDiscount: true,
  modifyUser: "00000000-0000-0000-0000-000000000000",
  syncGuid: "00000000-0000-0000-0000-000000000000",
  isCompanyData: false,
  startDate: new Date(),
  endDate: new Date(),
  ignoreOtherDiscount: true,
  showDiscountMessage: true,
  modifyDatetime: new Date(),
}