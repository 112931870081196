import { useState } from "react";
import { Store } from "../../../Model/Store";
import { GetStoreEmployees, GetStoreJournal, GetStoreRegisters, GetStoreTenders, GetTransactionPackage } from "../../../Services/Requests";
import { ReadableMoney, ReadableTime } from "../../../Helper/ConversionHelper";
import { FindEmployeeName, FindRegisterName } from "../../../Helper/FinderHelper";
import { JournalView, TransactionType } from "../../../Model/POSModel/TransactionTypes";
import { JournalFilter } from "../../../Model/Transactional";
import { Register } from "../../../Model/POSModel/Register";
import { PosUser } from "../../../Model/POSModel/PosUser";
import FormInput from "../../../Components/FormInput";
import FormCurrency from "../../../Components/FormCurrency";
import Select, { MultiValue } from 'react-select';
import Tender from "../../../Model/POSModel/Tender";
import { FaTimes } from "react-icons/fa";
import PageNavigator from "../../../Components/PageNavigator";

export const JournalPage: React.FC<{ store:Store }> = ({ store }) => {

    const template = {
        chunkSize: 100,
        page: 1,
        amountFrom: 0,
        amountTo: 0,
        registers: [],
        cashiers: [],
        tenders: []
    };
    const [journalFilter, setJournalFilter] = useState<JournalFilter>(template);
    const [selectedTrans, setSelectedTrans] = useState<JournalView | undefined>();

    const { isLoading:isLoadingTenders, data:tenderData } = GetStoreTenders(store.id);
    const { isLoading:isLoadingRegisters, data:registerData } = GetStoreRegisters(store.id);
    const { isLoading:isLoadingUsers, data:userData } = GetStoreEmployees(store.id);
    const { isLoading, data } = GetStoreJournal(store.id, journalFilter);

    const HandleSetPageInfo = (page:number, chunkSize: number) => {
        setJournalFilter({...journalFilter, ["page"]: page, ["chunkSize"]: chunkSize});
    }

    const GetTransType = (type: number): string => {
        switch(type){
            case TransactionType.AbortedTransaction: return "Aborted Transaction";
            case TransactionType.BlindClose: return "Blind Close";
            case TransactionType.CashDrop: return "Cash Drop";
            case TransactionType.NoSale: return "No Sale";
            case TransactionType.Sale: return "Sale";
            case TransactionType.Payout: return "Payout";
            case TransactionType.XReport: return "X Report";
            case TransactionType.ZReport: return "Z Report";
            case TransactionType.ZZReport: return "ZZ Report";

            default: return "Unknown";
        }
    }

    const HandleWindowExit = () => {
        setSelectedTrans(undefined);
    }

    const HandleSetFilter = (filter: JournalFilter) => {
        setJournalFilter({...filter, ["page"]: 1, ["chunkSize"]: journalFilter.chunkSize});
    }

    return(<div className="">
        <div className="flex justify-evenly pt-6 px-2 flex-row h-screen space-x-2">
        { isLoadingRegisters || isLoadingUsers || isLoadingTenders ?
                <div>
                    Loading...
                </div>
                :
                registerData && userData && tenderData &&
                <div className="bg-offWhite shadow-xl rounded-3xl px-5 pb-8 pt-4 mb-4 flex flex-col w-full">
                <div className="flex justify-between items-center">
                        <h1 className='font-bold text-xl'>Journal</h1>
                        {/* <button onClick={() => setSearchVisible(true)}>
                            <FaSearch />
                        </button> */}
                </div>
                <FilterComponent filter={journalFilter} setFilter={HandleSetFilter} registers={registerData} posUsers={userData} tenders={tenderData} />

                <div className="flex flex-row justify-between my-2">
                    <p className='text-sm'>Click on a transaction to view it's details.</p>

                    <PageNavigator totalCount={data?.totalCount} currentPage={journalFilter.page} chunkSize={journalFilter.chunkSize} setPageInfo={HandleSetPageInfo}/>
                </div>
                
                <div className="relative overflow-y-auto h-full flex flex-col items-center">
                <table className="w-full table-auto">
                <thead className="bg-slate-400 sticky top-0">
                    <tr>
                    <th>Batch #</th>
                    <th>Register</th>
                    <th>Type</th>
                    <th>Clerk</th>
                    <th>Time</th>
                    <th>Tender</th>
                    <th>Total Paid</th>
                    </tr>
                </thead>
                <tbody>
                    {
                    !isLoading && data && registerData && userData && data.transactions.map((journal) => {
                        return (<tr key={journal.transactionNumber} className={`${selectedTrans && selectedTrans.transactionNumber === journal.transactionNumber ? "bg-slate-500" : "even:bg-offWhite odd:bg-slate-300"}
                                border-b border-gray-700 ${journal.transactionType === TransactionType.Sale && "hover:bg-slate-600 cursor-pointer"}`}
                                onClick={() => journal.transactionType === TransactionType.Sale && setSelectedTrans(journal)}
                                >
                        <td>
                            <div className="flex justify-center">
                            {journal.batchID}
                            </div>
                        </td>
                        <td>
                            <div className="flex justify-center">
                            {FindRegisterName(journal.registerNumber, registerData)}
                            </div>
                        </td>
                        <td>
                            <div className="flex justify-center">
                            {GetTransType(journal.transactionType)}
                            </div>
                        </td>
                        <td>
                            <div className="flex justify-center">
                            {FindEmployeeName(journal.cashierID, userData)}
                            </div>
                        </td>
                        <td>
                            <div className="flex justify-center">
                            {ReadableTime(journal.time)}
                            </div>
                        </td>
                        <td>
                            <div className="flex justify-center">
                            {journal.tenderName}
                            </div>
                        </td>
                        <td>
                            <div className="flex justify-center">
                            {ReadableMoney(journal.tenderTotal)}
                            </div>
                        </td>
                        </tr>)
                    })
                    }
                </tbody>
                </table>
                {isLoading && <span className="mt-2">Loading...</span>}
                </div>
            </div>
            }

            {selectedTrans && registerData && userData && <TransactionDetails selectedTrans={selectedTrans} storeID={store.id} registerData={registerData} userData={userData} onWindowExit={HandleWindowExit}/>}
            </div>
      </div>
    );
}

const TransactionDetails: React.FC<{selectedTrans: JournalView, storeID: number, registerData: Register[], userData:PosUser[], onWindowExit():void }> = ({selectedTrans, storeID, registerData, userData, onWindowExit}) => {

    const {isLoading, data} = GetTransactionPackage(storeID, selectedTrans.transactionNumber);

    return (
        <div className="bg-offWhite shadow-xl rounded-3xl px-5 pb-8 mb-4 flex flex-col w-1/2">
            <div className="mb-4 mt-4">
                <div className="flex justify-between items-center">
                    <h1 className='font-bold text-xl'>Transaction Details</h1>
                    <button onClick={onWindowExit}>
                        <FaTimes size="28" className="items-center relative flex justify-center
                        hover:text-red-600 transition-all duration-300 ease-linear cursor-pointer"/>
                    </button>
                </div>
            </div>
            <label className="block text-gray-700 text-sm font-bold -mb-1">Receipt No.</label>
            <span>{selectedTrans.receiptNumber}</span>
            <div className="grid grid-cols-2 gap-x-4">
                <div>
                    <label className="block text-gray-700 text-sm font-bold -mb-1">Time</label>
                    <span className="w-full">{ReadableTime(selectedTrans.time)}</span>
                </div>
                <div>
                    <label className="block text-gray-700 text-sm font-bold -mb-1">Total Paid</label>
                    {ReadableMoney(selectedTrans.tenderTotal)}
                </div>
                <div>
                    <label className="block text-gray-700 text-sm font-bold -mb-1">Clerk</label>
                    {FindEmployeeName(selectedTrans.cashierID, userData)}
                </div>
                <div>
                    <label className="block text-gray-700 text-sm font-bold -mb-1">Register</label>
                    {FindRegisterName(selectedTrans.registerNumber, registerData)}
                </div>
            </div>

            {isLoading && <span>Loading...</span>}
            {!isLoading && data && <div className="h-full">
                <label className="block text-gray-700 text-sm font-bold mt-4 mb-1">Items</label>
                <div className="bg-slate-400 rounded-lg overflow-y-auto space-y-7 h-1/3">
                    <table className="w-full table-auto">
                        <thead className="bg-slate-400 sticky top-0">
                            <tr>
                            <th>Barcode</th>
                            <th>Description</th>
                            <th>Qty</th>
                            <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                             data.items.map((item) => {
                                return (<tr key={item.transactionEntryID} className={"even:bg-offWhite odd:bg-slate-300 border-b border-gray-700"}>
                                <td>
                                    <div className="flex justify-center">
                                    {item.barcode}
                                    </div>
                                </td>
                                <td>
                                    <div className="flex justify-center">
                                    {item.description}
                                    </div>
                                </td>
                                <td>
                                    <div className="flex justify-center">
                                    {item.quantity}
                                    </div>
                                </td>
                                <td>
                                    <div className="flex justify-center">
                                    {ReadableMoney(item.amount)}
                                    </div>
                                </td>
                                </tr>)
                            })
                            }
                        </tbody>
                    </table>
                </div>
                <label className="block text-gray-700 text-sm font-bold mt-4 mb-1">Payments</label>
                <div className="bg-slate-400 rounded-lg overflow-y-auto space-y-7 h-1/3">
                    <table className="w-full table-auto">
                        <thead className="bg-slate-400 sticky top-0">
                            <tr>
                            <th>Tender</th>
                            <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                            data.payments.map((payment) => {
                                return (<tr key={payment.tenderEntryID} className={"even:bg-offWhite odd:bg-slate-300 border-b border-gray-700"}>
                                <td>
                                    <div className="flex justify-center">
                                    {payment.description}
                                    </div>
                                </td>
                                <td>
                                    <div className="flex justify-center">
                                    {ReadableMoney(payment.amount)}
                                    </div>
                                </td>
                                </tr>)
                            })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="space-y-1 mt-2">
                    <label className="block text-gray-700 text-sm font-bold">Sub Total: <span className=" font-normal text-base text-black">
                        { data.items.length === 0 ?
                        ReadableMoney(0)
                        :
                        ReadableMoney(data.items.reduce( function(a, b){
                        return a + (b.amount * b.quantity)
                        }, 0))
                        }
                        </span>
                    </label>
                    <label className="block text-gray-700 text-sm font-bold">Sales Tax: <span className=" font-normal text-base text-black">{ReadableMoney(data.transaction.salesTax)}</span></label>
                    <label className="block text-gray-700 text-sm font-bold">Total: <span className=" font-normal text-base text-black">{ReadableMoney(data.transaction.total)}</span></label>
                </div>
            </div>}
            
        </div>
    );
}

interface RegisterOption {
    value: string;
    label: string;
}

const FilterComponent: React.FC<{ filter:JournalFilter, setFilter(filter:JournalFilter):void, registers:Register[], posUsers:PosUser[], tenders:Tender[] }> = ({ filter, setFilter, registers, posUsers, tenders }) => {

    const [copy, setCopy] = useState(filter);

    const onChange = (e:any) => {
        setCopy({...copy, [e.target.name]: e.target.value})
        //console.log(copy);
    }

    const onSubmit = (e:any) => {
        e.preventDefault();
        setFilter(copy);
    }

    const registerOptions: RegisterOption[] = registers.map((register) => ({
        value: register.syncGuid,
        label: register.description,
    }));
    const handleRegisterChange = (options: MultiValue<RegisterOption>) => {
        setCopy({ ...copy, registers: options.map((value) => value.value) });
        //console.log(copy);
    };

    const clerkOptions: RegisterOption[] = posUsers.map((posUser) => ({
        value: posUser.syncGuid,
        label: posUser.firstName + " " + posUser.lastName,
    }));
    const handleClerkChange = (options: MultiValue<RegisterOption>) => {
        setCopy({ ...copy, cashiers: options.map((value) => value.value) });
        //console.log(copy);
    };

    const tenderOptions: RegisterOption[] = tenders.map((tender) => ({
        value: tender.syncGuid,
        label: tender.description,
    })).concat({
        value: "00000000-0000-0000-0000-000000000000",
        label: "Mixed",
    });
    const handleTenderChange = (options: MultiValue<RegisterOption>) => {
        setCopy({ ...copy, tenders: options.map((value) => value.value) });
        //console.log(copy);
    };

    return (
        <div>
            <form onSubmit={onSubmit}>
                <div className="grid grid-cols-2 gap-4">
                    <FormInput label="Date From"
                        type="date" required={false}
                        name="dateFrom" value={copy.dateFrom} onChange={onChange} />
                    <FormInput label="Date To"
                        type="date" required={false}
                        name="dateTo" value={copy.dateTo} onChange={onChange} />

                    <div>
                        <label className="block text-gray-700 text-sm font-bold mb-2">Register</label>
                        <Select
                        isMulti
                        options={registerOptions}
                        //value={copy.registers}
                        onChange={handleRegisterChange}
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700 text-sm font-bold mb-2">Clerk</label>
                        <Select
                        isMulti
                        options={clerkOptions}
                        //value={copy.registers}
                        onChange={handleClerkChange}
                        />
                    </div>
                    
                    <div>
                        <label className="block text-gray-700 text-sm font-bold mb-2">Amount</label>
                        <div className="flex flex-row space-x-2">
                            <FormCurrency
                            name="amountFrom"
                            value={copy.amountFrom}
                            onChange={onChange}
                            currency="$"/>
                            <label className="text-gray-700 text-sm font-bold mb-2">~</label>
                            <FormCurrency
                            name="amountTo"
                            value={copy.amountTo}
                            onChange={onChange}
                            currency="$"/>
                        </div>
                    </div>
                    
                    <div>
                        <label className="block text-gray-700 text-sm font-bold mb-2">Tender</label>
                        <Select
                        isMulti
                        options={tenderOptions}
                        //value={copy.registers}
                        onChange={handleTenderChange}
                        />
                    </div>
                    
                </div>

                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                    Filter
                </button>
            </form>
        </div>
    );
}