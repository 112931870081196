import { useEffect, useState } from "react";
import { RequireAuth } from "@fusionauth/react-sdk";
import { GetOrgStores, GetOrgsUserIsAdmin } from "../../Services/Requests";
import { Page } from "../ManageStore/Components/Pages";
import { OrgScrollBar } from "../ManageStore/Components/ScrollBar";
import { StorePage } from "../ManageStore/ManageStore";
import { Store } from "../../Model/Store";

function ManageOrgStore(){
    const { isLoading: isLoadingOrgs, data: orgData, isError: isErrorOrgs } = GetOrgsUserIsAdmin();
    
    const [selectedOrgID, setSelectedOrgID] = useState(-1);
    const [selectedStoreID, setSelectedStoreID] = useState(-1);

    const { isLoading: isLoadingStores, data: storeData, isError: isErrorStores } = GetOrgStores(selectedOrgID);

    const [selectedStore, setSelectedStore] = useState<Store|undefined>();

    const [selectedPage, setSelectedPage] = useState(Page.Department);

    useEffect(() => {
        if(selectedOrgID === -1){
            if(orgData && orgData.length !== 0){
                let org = orgData[0];
                setSelectedOrgID(org.id);
            }
        }
    }, [selectedOrgID, orgData]);

    useEffect(() => {
        if(selectedOrgID !== -1){
            //setSelectedStore(undefined);
            setSelectedStoreID(-1)
        }
    }, [selectedOrgID]);

    useEffect(() => {
        if(selectedStoreID !== -1 && storeData){
            let store = storeData.find(x => x.id === selectedStoreID);
            setSelectedStore(store);
        }
    }, [selectedStoreID, storeData]);

    useEffect(() => {
        if(selectedOrgID !== -1 && orgData && storeData && !selectedStore){
            if(storeData.length !== 0)
                setSelectedStore(storeData[0]);
        }
    }, [selectedOrgID, orgData, storeData, selectedStore]);

    return (
    <RequireAuth>
        <div className="h-full flex flex-col">
            <div>
                <div className="flex flex-row bg-black bg-opacity-50 px-5 space-x-2">
                    <div className="pb-2 bg-offWhite rounded-md p-2">
                        <label className="font-bold text-nowrap">Select an Organization:</label>
                        {
                            isLoadingOrgs ?
                            <p >Loading...</p>
                            :
                            <select name="storeSelection" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
                            focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            onChange={(e) => setSelectedOrgID(Number(e.target.value))} value={selectedOrgID}
                            >
                                {   
                                    isErrorOrgs ?
                                    <option value={-1}>Error</option>
                                    :
                                    orgData && orgData.map((org) => {
                                        return <option value={org.id} key={org.id}>{org.name}</option>
                                    })
                                }
                            </select>
                        }

                        <label className="font-bold text-nowrap">Select a Virtual Store:</label>
                        {
                            isLoadingStores ?
                            <p >Loading...</p>
                            :
                            <select name="storeSelection" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
                            focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            onChange={(e) => setSelectedStoreID(Number(e.target.value))} value={selectedStoreID}
                            >
                                {   
                                    isErrorStores ?
                                    <option value={undefined}>Error</option>
                                    :
                                    storeData && storeData.map((store) => {
                                        return <option value={store.id} key={store.id}>{store.name}</option>
                                    })
                                }
                            </select>
                        }
                    </div>

                    <OrgScrollBar selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
                </div>
                {   !isLoadingOrgs && !isLoadingStores ?
                    selectedStore ?
                    <StorePage store={selectedStore} selectedPage={selectedPage} isOrgStore={true} />
                    :
                    <div>
                        Nothing selected
                    </div>
                    :
                    <div>
                        Loading...
                    </div>
                }
            </div>
            
        </div>
    </RequireAuth>
    );
}

export default ManageOrgStore;