import { IoAddCircleOutline } from "react-icons/io5";
import { FaTrashAlt } from "react-icons/fa";
import { FaCashRegister } from "react-icons/fa6";
import { CreatePOS, DeletePOS } from "../Services/Requests";
import { POSSystem } from "../Model/POSSystem";
import { useQueryClient } from 'react-query';
import { HiddenText } from "./HiddenText";
import { isOnline, timeAgo } from "../Helper/StatusHelper";

export const POSCard: React.FC<{ posData: POSSystem }> = ({ posData }) => {

    let seenDate = new Date(posData.lastCheckIn);
    let online = isOnline(seenDate);
    const queryClient = useQueryClient();
    const { mutateAsync: deleteAsync, isLoading: isDeleting } = DeletePOS(queryClient)

    const handleDelete = async () => {
        try {
            let result = await deleteAsync(posData.id);

             if(result){
                 //setEditMode(false);
             }

        } catch (e) {
            console.error(e);
        }
    }

    return (
        <div className="bg-slate-300 rounded-2xl px-4 py-2">
            <div>
                <div className="flex justify-between items-center">
                    <h2 className="font-bold text-lg">{posData.name}</h2>
                    <FaCashRegister size={45} className="items-center relative flex justify-center"/>
                </div>
                <p className="text-sm text-gray-700 -my-1">{posData.systemName}</p>
                <p className="text-sm text-gray-700 -my-1">Master: <span>{posData.isMaster ? "Yes" : "No"}</span></p>
                { online ?
                
                <div className="flex justify-start items-center space-x-2">
                <p className="text-sm text-gray-700 -my-1">Status: Online</p>
                <span className="relative flex h-3 w-3">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
                </span>
                </div>
                :
    
                <div className="flex justify-start items-center space-x-2 -my-1">
                    <p className="text-sm text-gray-700">Status: Offline</p>
                    <span className="relative flex h-3 w-3">
                        {/* <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span> */}
                        <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                    </span>
                </div>
            
                }
                <p className="text-sm text-gray-700 -my-1">Last Seen: { timeAgo(new Date(posData.lastCheckIn)) }</p>
                <p className="text-sm text-gray-700 -my-1">API Key:</p>
                <HiddenText className="text-gray-700 -my-1" input={posData.authKey!} />

                <div className="flex justify-end">
                    <div className="flex flex-col items-center">
                        <p className="text-sm">Delete POS</p>
                        <FaTrashAlt size="28" className="items-center relative flex justify-center
                                hover:text-red-600 transition-all duration-300 ease-linear cursor-pointer"
                                onClick={() => handleDelete()}/>
                    </div>
                </div>
                
            </div>
        </div>
    );
};

export const NewPOSCard: React.FC<{ storeID: number }> = ({ storeID }) => {

    const queryClient = useQueryClient();
    const { mutateAsync, isLoading } = CreatePOS(queryClient)

    const handleSubmit = async () => {
        try {
            await mutateAsync(storeID);

        } catch (e) {
            console.error(e);
        }
    }

    return(
        <div className="relative flex rounded-2xl px-4 py-2 justify-center bg-slate-300 hover:bg-slate-600 hover:text-white
                transition-all duration-300 ease-linear cursor-pointer"
            onClick={handleSubmit}>
            <IoAddCircleOutline className={isLoading ? "animate-spin" : undefined} size="28"/>
        </div>
    );
}