import { useState } from "react";
import { Store } from "../../../Model/Store";
import { GetStoreBatches, GetStoreEmployees, GetStoreRegisters } from "../../../Services/Requests";
import { ReadableTime } from "../../../Helper/ConversionHelper";
import { FindEmployeeName, FindRegisterName } from "../../../Helper/FinderHelper";
import { Batch } from "../../../Model/POSModel/Batch";

export const BatchPage: React.FC<{ store:Store }> = ({ store }) => {

    const { isLoading, data } = GetStoreBatches(store.id);
    const { isLoading:isLoadingRegisters, data:registerData } = GetStoreRegisters(store.id);
    const { isLoading:isLoadingUsers, data:userData } = GetStoreEmployees(store.id);

    const GetLastUpdatedReadable = (batches: Batch[]): string => {
        return ReadableTime(batches.reduce(function(prev, current) {
            return (prev && prev.lastUpdated > current.lastUpdated) ? prev : current
          }).lastUpdated); //returns object
    }

    return(<div className="column-container px-2">
        <div className="content-container">
        { isLoading || isLoadingRegisters || isLoadingUsers ?
                <div>
                    Loading...
                </div>
                :
                data && registerData && userData &&
                <div style={{marginBottom: '100px'}}>
                <h1 className="font-bold text-white">Batches { data.length > 0 && <span>(as of: {GetLastUpdatedReadable(data)})</span>}</h1>
                <table className="w-full table-auto border border-collapse border-black border-spacing-y-1">
                <thead className="bg-slate-400">
                    <tr>
                    <th>Status</th>
                    <th>Batch #</th>
                    <th>Register</th>
                    <th>Clerk (open)</th>
                    <th>Opened</th>
                    <th>Clerk (close)</th>
                    <th>Closed</th>
                    </tr>
                </thead>
                <tbody>
                    {
                    data && registerData && userData && data.map((batch) => {
                        return (<tr key={batch.id} className="even:bg-offWhite odd:bg-slate-300 border-b border-gray-700">
                        <td>
                            <div className="flex justify-center">
                            {
                                batch.status === 2 ?
                                "Open"
                                :
                                batch.status === 3 ?
                                "Closed"
                                :
                                "Blind Close"
                            }
                            </div>
                        </td>
                        <td>
                            <div className="flex justify-center">
                            {batch.id}
                            </div>
                        </td>
                        <td>
                            <div className="flex justify-center">
                            {FindRegisterName(batch.registerID, registerData)}
                            </div>
                        </td>
                        <td>
                            <div className="flex justify-center">
                            {batch.openUser && FindEmployeeName(batch.openUser, userData)}
                            </div>
                        </td>
                        <td>
                            <div className="flex justify-center">
                            {ReadableTime(batch.openingTime)}
                            </div>
                        </td>
                        <td>
                            <div className="flex justify-center">
                            {batch.closeUser && FindEmployeeName(batch.closeUser, userData)}
                            </div>
                        </td>
                        <td>
                            <div className="flex justify-center">
                            {batch.closingTime && ReadableTime(batch.closingTime)}
                            </div>
                        </td>
                        </tr>)
                    })
                    }
                </tbody>
                </table>
            </div>
            }
            </div>
      </div>
    );
}