import { FaPen, FaTimes, FaTrashAlt } from "react-icons/fa";
import { Supplier, Template, SupplierView, SupplierInjestPackage } from "../../../Model/POSModel/Supplier";
import { CreateStoreSupplier, DeleteStoreSupplier, GetStoreCurrencies, GetSupplier, GetSupplierItems, SearchSupplierItems, UpdateStoreSupplier } from "../../../Services/Requests";
import FormCheckbox from "../../../Components/FormCheckbox";
import FormInput from "../../../Components/FormInput";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { ItemPreview } from "../../../Model/POSModel/Item";
import { ReadableMoney } from "../../../Helper/ConversionHelper";
import Currency from "../../../Model/POSModel/Currency";
import { FindCurrencyName } from "../../../Helper/FinderHelper";

interface SupplierDetailsProps{
    selectedSupplier: SupplierView,
    storeID: number,
    currencyData: Currency[],
    onWindowExit():void,
    isOrgStore:boolean,
    handleEditClick(discount: Supplier, basket: ItemPreview[]):void
};

const SupplierDetails: React.FC<SupplierDetailsProps> = ({selectedSupplier, storeID, currencyData, onWindowExit, isOrgStore = false, handleEditClick}) => {

    const {isLoading, data} = GetSupplier(storeID, selectedSupplier.syncGuid);
    const {isLoading: isLoadingBaskets, data:basketData} = GetSupplierItems(storeID, selectedSupplier.syncGuid);
    

    // useEffect(() => {
    //     setEditMode(false);
    //     if(selectedSupplier.syncGuid === "00000000-0000-0000-0000-000000000000"){
    //         setCopy(Template);
    //     }
    // }, [selectedSupplier]);

    // useEffect(() => {
    //     if(data)
    //         setCopy(data);
    // }, [data]);

    return (
        <div className="bg-offWhite shadow-xl rounded-3xl px-5 pb-8 mb-4 flex flex-col w-1/2">

            <div className="mb-4 mt-4">
                <div className="flex justify-between Suppliers-center">
                    <h1 className='font-bold text-xl'>Supplier Details</h1>
                    <button onClick={onWindowExit}>
                        <FaTimes size="28" className="Suppliers-center relative flex justify-center
                        hover:text-red-600 transition-all duration-300 ease-linear cursor-pointer"/>
                    </button>
                </div>
            </div>

            <div className="overflow-y-auto">
                {(isLoading || isLoadingBaskets) && <span>Loading...</span>}
                {!isLoading && data && basketData &&
                <div>

                    <div className="flex justify-start Suppliers-center space-x-2">
                        <h2 className="font-bold text-lg">{data.code}</h2>
                        {isOrgStore === data.isCompanyData && <FaPen size="18" className="Suppliers-center relative flex justify-center
                        hover:text-orange-500 transition-all duration-300 ease-linear cursor-pointer"
                        onClick={() => handleEditClick(data, basketData)}/>}
                    </div>

                    <div className="grid grid-cols-2 gap-x-4">
                        <div>
                            <label className="block text-gray-700 text-sm font-bold -mb-1 mt-4">Code</label>
                            <span className="w-full">{data.code}</span>
                        </div>
                        <div>
                            <label className="block text-gray-700 text-sm font-bold -mb-1 mt-4">Name</label>
                            <span className="w-full">{data.name}</span>
                        </div>
                        <div>
                            <label className="block text-gray-700 text-sm font-bold -mb-1 mt-4">Contact</label>
                            <span className="w-full">{data.contact}</span>
                        </div>
                        <div>
                            <label className="block text-gray-700 text-sm font-bold -mb-1 mt-4">Phone</label>
                            <span className="w-full">{data.phone}</span>
                        </div>
                        <div>
                            <label className="block text-gray-700 text-sm font-bold -mb-1 mt-4">Email</label>
                            <span className="w-full">{data.email}</span>
                        </div>
                        <div>
                            <label className="block text-gray-700 text-sm font-bold -mb-1 mt-4">Fax</label>
                            <span className="w-full">{data.fax}</span>
                        </div>
                        <div>
                            <label className="block text-gray-700 text-sm font-bold -mb-1 mt-4">Address 1</label>
                            <span className="w-full">{data.address1}</span>
                        </div>
                        <div>
                            <label className="block text-gray-700 text-sm font-bold -mb-1 mt-4">Address 2</label>
                            <span className="w-full">{data.address2}</span>
                        </div>
                        <div>
                            <label className="block text-gray-700 text-sm font-bold -mb-1 mt-4">Postal code</label>
                            <span className="w-full">{data.zip}</span>
                        </div>
                        <div>
                            <label className="block text-gray-700 text-sm font-bold -mb-1 mt-4">City</label>
                            <span className="w-full">{data.city}</span>
                        </div>
                        <div>
                            <label className="block text-gray-700 text-sm font-bold -mb-1 mt-4">Province/Territory</label>
                            <span className="w-full">{data.state}</span>
                        </div>
                        <div>
                            <label className="block text-gray-700 text-sm font-bold -mb-1 mt-4">Country</label>
                            <span className="w-full">{data.country}</span>
                        </div>
                        <div>
                            <label className="block text-gray-700 text-sm font-bold -mb-1 mt-4">Website</label>
                            <span className="w-full">{data.homePage}</span>
                        </div>
                        <div>
                            <label className="block text-gray-700 text-sm font-bold -mb-1 mt-4">Currency</label>
                            <span className="w-full">{data.currencyId && FindCurrencyName(data.currencyId, currencyData)}</span>
                        </div>
                        <div>
                            <label className="block text-gray-700 text-sm font-bold -mb-1 mt-4">Terms</label>
                            <span className="w-full">{data.terms}</span>
                        </div>
                        <div>
                            <label className="block text-gray-700 text-sm font-bold -mb-1 mt-4">Min. order amount</label>
                            <span className="w-full">{data.minOrderAmount}</span>
                        </div>
                    </div>
                    
                    <label className="block text-gray-800 text-base font-bold mt-4 mb-1">Items</label>
                        <div>
                                        <div className="bg-slate-400">
                                            <table className="w-full table-auto">
                                                <thead className="bg-slate-400 sticky top-0">
                                                    <tr>
                                                    <th>Item #</th>
                                                    <th>Description</th>
                                                    <th>Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {basketData.map((item) => {
                                                        return (<tr key={item.syncGuid} className={"even:bg-offWhite odd:bg-slate-300 border-b border-gray-700"}>
                                                        <td>
                                                            <div className="flex justify-center">
                                                            {item.itemNumber}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="flex justify-center">
                                                            {item.description}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="flex justify-center">
                                                            {ReadableMoney(item.price)}
                                                            </div>
                                                        </td>
                                                        </tr>)
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                </div>}
            </div>
        </div>
    );
}

export default SupplierDetails;

interface FormProps {
    handleSubmit(e: any): void,
    onChange(e: any): void,
    onCheckChange(e: any): void,
    onChangeNumber(e: any): void,
    handleDelete?(): void,
    copy: Supplier,
    currencyData: Currency[],
    isLoading: boolean,
    isError: boolean,
}

const SupplierForm: React.FC<FormProps> = ({handleSubmit, onChange, onCheckChange, onChangeNumber, handleDelete, copy, currencyData, isLoading, isError}) => {

    return (
        <form onSubmit={handleSubmit}>
            <FormCheckbox className="mb-4 flex space-x-2" label="Active"
            name="active" checked={copy.active} onChange={onCheckChange} />

            <FormInput className="mb-4" label="Name"
            type="text" required={true} pattern="^(?! *$)[A-Za-z0-9\- .]+$" errorMessage="A name is required."
            name="name" value={copy.name} onChange={onChange}/>

            <FormInput className="mb-4" label="Code"
            type="text" required={true} pattern="^(?! *$)[A-Za-z0-9\- .]+$" errorMessage="A code is required."
            name="code" value={copy.code} onChange={onChange}/>

            <FormInput className="mb-4" label="Address 1"
            type="text" required={false} pattern="^(?! *$)[A-Za-z0-9\- .]+$"
            name="address1" value={copy.address1} onChange={onChange}/>

            <FormInput className="mb-4" label="Address 2"
            type="text" required={false} pattern="^(?! *$)[A-Za-z0-9\- .]+$"
            name="address2" value={copy.address2} onChange={onChange}/>

            <FormInput className="mb-4" label="Postal code"
            type="text" required={false} pattern="^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z] [0-9][ABCEGHJ-NPRSTV-Z][0-9]$"
            errorMessage="A valid postal code is required. (Ex. L6E 1M6)" placeholder="L8E 4P2"
            name="zip" value={copy.zip} onChange={onChange}/>
            
            <FormInput className="mb-4" label="Country/Region"
            type="text" required={false} pattern="^(?! *$)[A-Za-z0-9\- .]+$"
            name="country" value={copy.country} onChange={onChange}/>

            
            <FormInput className="mb-4" label="Contact"
            type="text" required={false} pattern="^(?! *$)[A-Za-z0-9\- .]+$"
            name="contact" value={copy.contact} onChange={onChange}/>

            
            <FormInput className="mb-4" label="Phone"
            type="text" required={false} pattern="^(?! *$)[A-Za-z0-9\- .]+$"
            name="phone" value={copy.phone} onChange={onChange}/>

            
            <FormInput className="mb-4" label="Fax"
            type="text" required={false} pattern="^(?! *$)[A-Za-z0-9\- .]+$"
            name="fax" value={copy.fax} onChange={onChange}/>

            
            <FormInput className="mb-4" label="E-mail"
            type="email" required={false}
            name="email" value={copy.email} onChange={onChange}/>
            
            <FormInput className="mb-4" label="Web page"
            type="text" required={false} pattern="^(?! *$)[A-Za-z0-9\- .]+$"
            name="homePage" value={copy.homePage} onChange={onChange}/>

            <div className="mb-2">
                <label className="block text-gray-700 text-sm font-bold my-2">Currency</label>
                <select name="currencyId" value={copy.currencyId} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
                focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                onChange={onChange}
                >
                <option value="00000000-0000-0000-0000-000000000000">None (N/A)</option>
                { currencyData.map((currency) => {
                    return <option value={currency.syncGuid}>{currency.description} ({currency.code})</option>
                })}
                </select>
            </div>

            <FormInput className="mb-4" label="Terms"
            type="text" required={false} pattern="^(?! *$)[A-Za-z0-9\- .]+$"
            name="terms" value={copy.terms} onChange={onChange}/>

            <FormInput
            type="number" step="0.01" required={false} label="Min. order amount" className="mt-2"
            name="minOrderAmount" value={copy.minOrderAmount} onChange={onChangeNumber}/>

            <div className="flex justify-between mt-4">

                { handleDelete &&
                    <div className="flex flex-col Suppliers-center">
                        <p className="text-sm">Delete</p>
                        <FaTrashAlt size="28" className="Suppliers-center relative flex justify-center
                        hover:text-red-600 transition-all duration-300 ease-linear cursor-pointer"
                        onClick={() => handleDelete()}/>
                    </div>
                }

                {
                    isLoading ?
                    <button className="bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" disabled>
                        Processing...
                    </button>
                    :
                    isError ?
                    <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                        Error
                    </button>
                    :
                    <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                    Save
                    </button>
                }
            </div>
        </form>
    );
}

interface ItemSearchProps {
    storeID: number,
    discount: Supplier,
    basket: ItemPreview[],
    addItemToBasket(item: ItemPreview):void
};

export const ItemSearchWindow: React.FC<ItemSearchProps> = ({ storeID, discount, addItemToBasket, basket }) => {

    const [tempSearchTerm, setTempSearchTerm] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTempSearchTerm(event.target.value);
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        setSearchTerm(tempSearchTerm);
    }

    const handleSelectItem = (item: ItemPreview) => {
        //onSelect(item);
        //onClose();
    }

    const { data, isLoading } = SearchSupplierItems(storeID, discount.syncGuid, searchTerm);
    const [filteredData, setFilteredData] = useState<ItemPreview[]>([]);

    useEffect(() => {
        if(data){
            let flatItems = basket.flatMap(item => item.syncGuid);
            setFilteredData(data.filter(item => !flatItems.includes(item.syncGuid)));
        }
    }, [data, basket]);

    return (
        <div className="bg-offWhite shadow-xl rounded-3xl px-5 pb-8 flex flex-col w-1/2">
            <h1 className='font-bold text-xl my-4'>Search Items</h1>
            <p>Items used in other suppliers are excluded from the results.</p>
            <form onSubmit={handleSubmit}>
                <div className="flex space-x-2 items-end">
                    <FormInput type="text" value={tempSearchTerm} onChange={handleSearchChange} placeholder="2L Cola" className="w-full"/>
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                        Search
                    </button>
                </div>
            </form>
            <ul className='bg-slate-400 rounded-lg overflow-y-auto mt-2 h-full'>
                {isLoading && <span className="w-full flex justify-center">Loading...</span>}
                {filteredData.map(item => (
                    <li className='p-2 cursor-pointer hover:bg-gray-200' key={item.syncGuid}
                    onClick={() => addItemToBasket(item)}
                    >
                        <SearchResult data={item}/>
                    </li>
                ))}
            </ul>
        </div>
    );
}

type SearchResultProps = {
    data: ItemPreview,
}

const SearchResult: React.FC<SearchResultProps> = ({data}) => {
    return (
        <div className="bg-slate-300 rounded-lg px-2 py-2 grid grid-cols-3 gap-4">
            <p>{data.itemNumber}</p>
            <p>{data.description}</p>
            <p>{ReadableMoney(data.price)}</p>
        </div>
    );
}

interface BasketEditProps {
    storeID: number,
    discount: Supplier,
    basket: ItemPreview[],
    removeItemFromBasket(itemIndex: number):void
};

export const BasketEditWindow: React.FC<BasketEditProps> = ({ storeID, discount, basket, removeItemFromBasket }) => {

    return(
        <div className="bg-offWhite shadow-xl rounded-3xl px-5 pb-8 flex flex-col w-1/2">
            <h1 className='font-bold text-xl my-4'>Items assigned to Supplier</h1>

            <div className="overflow-y-auto">

                <div className="bg-slate-400">
                    <table className="w-full table-auto">
                        <thead className="bg-slate-400 sticky top-0">
                            <tr>
                            <th>Item #</th>
                            <th>Description</th>
                            <th>Amount</th>
                            <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {basket.map((item, index) => {
                                return (<tr key={item.syncGuid} className={"even:bg-offWhite odd:bg-slate-300 border-b border-gray-700"}>
                                <td>
                                    <div className="flex justify-center">
                                    {item.itemNumber}
                                    </div>
                                </td>
                                <td>
                                    <div className="flex justify-center">
                                    {item.description}
                                    </div>
                                </td>
                                <td>
                                    <div className="flex justify-center">
                                    {ReadableMoney(item.price)}
                                    </div>
                                </td>
                                <td>
                                    <div className="flex justify-center">
                                        <button onClick={() => removeItemFromBasket(index)}>
                                            <FaTimes size="22" className="items-center relative flex justify-center
                                            hover:text-red-600 transition-all duration-300 ease-linear cursor-pointer"/>
                                        </button>
                                    </div>
                                </td>
                                </tr>)
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

interface EditSupplierProps {
    editingDiscount:Supplier,
    storeID: number,
    editingBasket: ItemPreview[],
    currencyData: Currency[],
    onWindowExit(): void,
    setEditingDiscount(discount: Supplier): void
};

export const EditSupplierWindow: React.FC<EditSupplierProps> = ({editingDiscount:copy, storeID, currencyData, editingBasket, onWindowExit, setEditingDiscount:setCopy}) => {

    //const [copy, setCopy] = useState(editingDiscount);

    const queryClient = useQueryClient();
    const { mutateAsync: createAsync, isLoading: isCreateLoading, isError:isCreateError } = CreateStoreSupplier(queryClient, storeID);
    const { mutateAsync: updateAsync, isLoading: isUpdateLoading, isError:isUpdateError } = UpdateStoreSupplier(queryClient, storeID);
    const { mutateAsync: deleteAsync, isLoading: isDeleting } = DeleteStoreSupplier(queryClient, storeID);

    let [isModifyLoading, setIsModifyLoading] = useState(false);
    let [isError, setIsError] = useState(false);

    useEffect(() => {
        setIsModifyLoading(isCreateLoading || isUpdateLoading);
        setIsError(isCreateError || isUpdateError);
    }, [isCreateLoading, isUpdateLoading, isCreateError, isUpdateError])

    const handleSubmit = async (event: any) => {
        event.preventDefault(); 

        let discountPackage: SupplierInjestPackage = {
            supplier: copy,
            basket: editingBasket
        };

        try {
            let result = copy.syncGuid === "00000000-0000-0000-0000-000000000000" ? await createAsync(discountPackage) : await updateAsync(discountPackage);

            if(result){
                onWindowExit();
            }

        } catch (e) {
            console.error(e);
        }
    }

    const handleDelete = async () => {
        try {
            if(!copy)
                return;

            let result = await deleteAsync(copy);

            if(result){
                onWindowExit();
            }

        } catch (e) {
            console.error(e);
        }
    }

    const onChange = (e:any) => {
        copy && setCopy({...copy, [e.target.name]: e.target.value})
    }
    const onChangeNumber = (e:any) => {
        copy && setCopy({...copy, [e.target.name]: Number(e.target.value)})
    }
    const onCheckChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.type === "radio") {
            setCopy({
                ...copy!,
                [e.target.name]: e.target.value === "true"
            });
        } else {
            setCopy({
                ...copy!,
                [e.target.name]: e.target.checked
            });
        }
    };

    //console.log(basketData);
    console.log(copy);

    return (
        <div className="bg-offWhite shadow-xl rounded-3xl px-5 pb-8 mb-4 flex flex-col w-1/2">
            <div className="mb-4 mt-4">
                <div className="flex justify-between items-center">
                    <div className="flex justify-between items-center space-x-2">
                        <h1 className='font-bold text-xl'>{copy ? "Edit Discount" : "Discount Details"}</h1>
                    </div>
                    <button onClick={onWindowExit}>
                        <FaTimes size="28" className="items-center relative flex justify-center
                        hover:text-red-600 transition-all duration-300 ease-linear cursor-pointer"/>
                    </button>
                </div>
            </div>

            <div className="overflow-y-auto">
                <SupplierForm copy={copy} currencyData={currencyData} onChange={onChange} onChangeNumber={onChangeNumber} onCheckChange={onCheckChange} handleSubmit={handleSubmit} handleDelete={handleDelete} isLoading={isModifyLoading} isError={isError} />
            </div>
        </div>
    );
}