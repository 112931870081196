import { QueryClient, useMutation, useQuery } from 'react-query';
import { AccountIDStore, AccountStoresRegisters, ChangeRole, JoinGroup, JournalFilter, OrgInfo, OrgMemID, OrgRole, StoreRegisters, UpdateAccount } from '../Model/Transactional';
import { Store } from '../Model/Store';
import { Organization } from '../Model/Organization';
import { DetectedSystem } from '../Model/DetectedSystem';
import { Department } from '../Model/POSModel/Department';
import { Category } from '../Model/POSModel/Category';
import { ItemMessage } from '../Model/POSModel/ItemMessage';
import { Batch } from '../Model/POSModel/Batch';
import { Register } from '../Model/POSModel/Register';
import { PosUser } from '../Model/POSModel/PosUser';
import { JournalPackage } from '../Model/POSModel/TransactionTypes';
import Tender from '../Model/POSModel/Tender';
import TransactionPackage from '../Model/TransactionPackage';
import { toUTCDate } from '../Helper/ConversionHelper';
import Tax from '../Model/POSModel/Tax';
import Currency from '../Model/POSModel/Currency';
import QuantityDiscount, { Basket, QuantityDiscountFilter, QuantityDiscountInjestPackage, QuantityDiscountPackage } from '../Model/POSModel/QuantityDiscount';
import { Item, ItemFilter, ItemPackage, ItemPreview } from '../Model/POSModel/Item';
import { Supplier, SupplierFilter, SupplierInjestPackage, SupplierPackage, SupplierView } from '../Model/POSModel/Supplier';
import { VirtualStore } from '../Model/VirtualStore';

//const baseurl = ""; //Live
//const baseurl = "https://localhost:1738" //Debug
//const baseurl = "http://192.168.2.21:3400" //Server Debug
const baseurl = "https://api.1solutions.ca" //Server Prod

export function ActiveMachines() {
    return useQuery({
      queryFn: () =>
        fetch(`${baseurl}/${"DRM/detected"}`, {
          credentials: 'include'
        }).then((res) => {
          if (!res.ok) {
            throw new Error('Failed to fetch')
          }
          return res.json() as unknown as DetectedSystem[]
        }),
    })
  }

export function CheckRegister() {
    return useQuery({
      queryKey: "checkregister",
      queryFn: () =>
        fetch(`${baseurl}/${"Account/checkregister"}`, {
          credentials: 'include'
        }).then((res) => {
          return res.ok
        }),
    })
  }

export function SubmitRegister(client: QueryClient) {
  return useMutation({
    mutationFn: (data: any) =>
      fetch(`${baseurl}/${"Account/register"}`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        return res.ok
      }),

    onSuccess: () => {
      client.invalidateQueries("checkregister");
    }
  })
}

export function GetStores() {
  return useQuery({
    queryKey: "getStores",
    queryFn: () =>
      fetch(`${baseurl}/${"Store"}`, {
        credentials: 'include'
      }).then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch')
        }
        return res.json() as unknown as Store[]
      }),
  })
}

export function GetStoresRegisters() {
  return useQuery({
    queryKey: "getStoresRegisters",
    queryFn: () =>
      fetch(`${baseurl}/${"Store/storesRegisters"}`, {
        credentials: 'include'
      }).then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch')
        }
        return res.json() as unknown as StoreRegisters[]
      }),
  })
}

export function GetMerchantsStoresRegisters() {
  return useQuery({
    queryKey: "getMerchantsStoresRegisters",
    queryFn: () =>
      fetch(`${baseurl}/${"Store/merchantsStoresRegisters"}`, {
        credentials: 'include'
      }).then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch')
        }
        return res.json() as unknown as AccountStoresRegisters[]
      }),
  })
}

export function CreateStore(client: QueryClient, merchantID?: string){
  return useMutation({
    mutationFn: (data: Store) => {

      if(merchantID){
        let payload: AccountIDStore = {
          accountID: merchantID,
          store: data
        };

        return fetch(`${baseurl}/${"Store/createStoreForUser"}`, {
          credentials: 'include',
          method: 'POST',
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json",
          },
          }).then((res) => {
            return res.ok
          });
      }
      

      else
      return fetch(`${baseurl}/${"Store/createStore"}`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        }).then((res) => {
          return res.ok
        });
    },
      
    onSuccess: () => {
      client.invalidateQueries("getStoresRegisters");
      client.invalidateQueries("getMerchantsStoresRegisters");
    }
  })
}

export function UpdateStore(client: QueryClient){
  return useMutation({
    mutationFn: (data: Store) =>
      fetch(`${baseurl}/${"Store/updateStore"}`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        return res.ok
      }),

    onSuccess: () => {
      client.invalidateQueries("getStoresRegisters");
      client.invalidateQueries("getMerchantsStoresRegisters");
    }
  })
}

export function DeleteStore(client: QueryClient){
  return useMutation({
    mutationFn: (storeID: number) =>
      fetch(`${baseurl}/Store/deleteStore/${storeID}`, {
        credentials: 'include',
        method: 'DELETE',
      }).then((res) => {
        return res.ok
      }),

    onSuccess: () => {
      client.invalidateQueries("getStoresRegisters");
      client.invalidateQueries("getMerchantsStoresRegisters");
    }
  })
}

export function EnableStore(client: QueryClient){
  return useMutation({
    mutationFn: (storeID: number) =>
      fetch(`${baseurl}/Store/enableStore/${storeID}`, {
        credentials: 'include',
        method: 'GET',
      }).then((res) => {
        return res.ok
      }),

    onSuccess: () => {
      client.invalidateQueries("getStoresRegisters");
      client.invalidateQueries("getMerchantsStoresRegisters");
    }
  })
}

export function DisableStore(client: QueryClient){
  return useMutation({
    mutationFn: (storeID: number) =>
      fetch(`${baseurl}/Store/disableStore/${storeID}`, {
        credentials: 'include',
        method: 'GET',
      }).then((res) => {
        return res.ok
      }),

    onSuccess: () => {
      client.invalidateQueries("getStoresRegisters");
      client.invalidateQueries("getMerchantsStoresRegisters");
    }
  })
}

export function CreatePOS(client: QueryClient){
  return useMutation({
    mutationFn: (storeID: number) =>
      fetch(`${baseurl}/Store/addPOSSystem/${storeID}`, {
        credentials: 'include',
        method: 'GET',
      }).then((res) => {
        return res.ok
      }),

    onSuccess: () => {
      client.invalidateQueries("getStoresRegisters");
      client.invalidateQueries("getMerchantsStoresRegisters");
    }
  })
}

export function DeletePOS(client: QueryClient){
  return useMutation({
    mutationFn: (posID: number) =>
      fetch(`${baseurl}/Store/deletePOS/${posID}`, {
        credentials: 'include',
        method: 'DELETE',
      }).then((res) => {
        return res.ok
      }),

    onSuccess: () => {
      client.invalidateQueries("getStoresRegisters");
      client.invalidateQueries("getMerchantsStoresRegisters");
    }
  })
}

export function GetUser() {
  return useQuery({
    queryKey: "getUser",
    queryFn: () =>
      fetch(`${baseurl}/Account`, {
        credentials: 'include'
      }).then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch')
        }
        return res.json() as unknown as UpdateAccount
      }),
  })
}

export function UpdateUser(client: QueryClient){
  return useMutation({
    mutationFn: (data: UpdateAccount) =>
      fetch(`${baseurl}/Account`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        return res.ok
      }),

    onSuccess: () => {
      //client.invalidateQueries("getUser");
    }
  })
}

export function GetOrgs() {
  return useQuery({
    queryKey: "getOrgs",
    queryFn: () =>
      fetch(`${baseurl}/Organization`, {
        credentials: 'include'
      }).then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch')
        }
        return res.json() as unknown as OrgRole[]
      }),
  })
}

export function GetOrgInfo(id: number) {
  return useQuery({
    queryKey: ["getOrgInfo", id],
    queryFn: ({signal}) =>
      fetch(`${baseurl}/Organization/${id}`, {
        credentials: 'include',
        signal
      }).then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch')
        }
        return res.json() as unknown as OrgInfo
      }),
  })
}

export function RemoveMember(client: QueryClient, orgID: number){
  return useMutation({
    mutationFn: (orgMemID: OrgMemID) =>
      fetch(`${baseurl}/Organization/member`, {
        credentials: 'include',
        method: 'DELETE',
        body: JSON.stringify(orgMemID),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        return res.ok
      }),
    onSuccess: () => {
      client.invalidateQueries(["getOrgInfo", orgID]);
    }
  })
}

export function ChangeMemberRole(client: QueryClient, orgID: number){
  return useMutation({
    mutationFn: (data: ChangeRole) =>
      fetch(`${baseurl}/Organization/changeRole`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        return res.ok
      }),

    onSuccess: () => {
      client.invalidateQueries(["getOrgInfo", orgID]);
    }
  })
}

export function JoinOrg(client: QueryClient){
  return useMutation({
    mutationFn: (joinGroup: JoinGroup) =>
      fetch(`${baseurl}/Organization/join`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(joinGroup),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        return res.ok
      }),

    onSuccess: () => {
      client.invalidateQueries("getOrgs");
    }
  })
}

export function LeaveOrg(client: QueryClient){
  return useMutation({
    mutationFn: (id: number) =>
      fetch(`${baseurl}/Organization/leave/${id}`, {
        credentials: 'include',
      }).then((res) => {
        return res.ok
      }),

    onSuccess: () => {
      client.invalidateQueries("getOrgs");
    }
  })
}

export function CreateOrg(client: QueryClient){
  return useMutation({
    mutationFn: (org: Organization) =>
      fetch(`${baseurl}/Organization`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(org),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        return res.ok
      }),

    onSuccess: () => {
      client.invalidateQueries("getOrgs");
    }
  })
}

export function UpdateOrg(client: QueryClient){
  return useMutation({
    mutationFn: (org: Organization) =>
      fetch(`${baseurl}/Organization`, {
        credentials: 'include',
        method: 'PATCH',
        body: JSON.stringify(org),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        return res.ok
      }),

    onSuccess: () => {
      client.invalidateQueries("getOrgs");
    }
  })
}

export function DeleteOrg(client: QueryClient){
  return useMutation({
    mutationFn: (orgID: number) =>
      fetch(`${baseurl}/Organization/${orgID}`, {
        credentials: 'include',
        method: 'DELETE',
      }).then((res) => {
        return res.ok
      }),

    onSuccess: () => {
      client.invalidateQueries("getOrgs");
    }
  })
}

export function GetStoreDepartments(storeID: number) {
  return useQuery({
    queryKey: ["getStoreDepartments", storeID],
    queryFn: ({signal}) =>
      fetch(`${baseurl}/Store/departments/${storeID}`, {
        credentials: 'include',
        signal
      }).then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch')
        }
        return res.json() as unknown as Department[]
      }),
  })
}

export function CreateStoreDepartment(client: QueryClient, storeID: number){
  return useMutation({
    mutationFn: (dept: Department) =>
      fetch(`${baseurl}/Store/createDepartment/${storeID}`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(dept),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        return res.ok
      }),

    onSuccess: () => {
      client.invalidateQueries(["getStoreDepartments", storeID]);
    }
  })
}

export function UpdateStoreDepartment(client: QueryClient, storeID: number){
  return useMutation({
    mutationFn: (dept: Department) =>
      fetch(`${baseurl}/Store/updateDepartment/${storeID}`, {
        credentials: 'include',
        method: 'PATCH',
        body: JSON.stringify(dept),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        return res.ok
      }),

    onSuccess: () => {
      client.invalidateQueries(["getStoreDepartments", storeID]);
    }
  })
}

export function DeleteStoreDepartment(client: QueryClient, storeID: number){
  return useMutation({
    mutationFn: (dept: Department) =>
      fetch(`${baseurl}/Store/deleteDepartment/${storeID}`, {
        credentials: 'include',
        method: 'DELETE',
        body: JSON.stringify(dept),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        return res.ok
      }),

    onSuccess: () => {
      client.invalidateQueries(["getStoreDepartments", storeID]);
    }
  })
}

export function GetOrgStores(id: number) {
  return useQuery({
    queryKey: ["getOrgStore", id],
    queryFn: ({signal}) =>
      fetch(`${baseurl}/Organization/stores/${id}`, {
        credentials: 'include',
        signal
      }).then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch')
        }
        return res.json() as unknown as Store[]
      }),
  })
}

export function GetOrgsUserIsAdmin() {
  return useQuery({
    queryKey: "getOrgsUserIsAdmin",
    queryFn: ({signal}) =>
      fetch(`${baseurl}/Organization/allAdmin`, {
        credentials: 'include',
        signal
      }).then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch')
        }
        return res.json() as unknown as Organization[]
      }),
  })
}

export function GetStoreCategories(storeID: number) {
  return useQuery({
    queryKey: ["getStoreCategories", storeID],
    queryFn: ({signal}) =>
      fetch(`${baseurl}/Store/categories/${storeID}`, {
        credentials: 'include',
        signal
      }).then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch')
        }
        return res.json() as unknown as Category[]
      }),
  })
}

export function GetStoreDepartmentCategories(storeID: number, departmentID: string) {
  return useQuery({
    queryKey: ["getStoreDepartmentCategories", storeID, departmentID],
    queryFn: ({signal}) =>
      fetch(`${baseurl}/Store/categories/${storeID}/department/${departmentID}`, {
        credentials: 'include',
        signal
      }).then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch')
        }
        return res.json() as unknown as Category[]
      }),
  })
}

export function CreateStoreCategory(client: QueryClient, storeID: number){
  return useMutation({
    mutationFn: (category: Category) =>
      fetch(`${baseurl}/Store/createCategory/${storeID}`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(category),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        return res.ok
      }),

    onSuccess: (_, variables) => {
      client.invalidateQueries(["getStoreCategories", storeID]);
      client.invalidateQueries(["getStoreDepartmentCategories", storeID, variables.departmentID]);
    }
  })
}

export function UpdateStoreCategory(client: QueryClient, storeID: number){
  return useMutation({
    mutationFn: (category: Category) =>
      fetch(`${baseurl}/Store/updateCategory/${storeID}`, {
        credentials: 'include',
        method: 'PATCH',
        body: JSON.stringify(category),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        return res.ok
      }),

    onSuccess: (_, variables) => {
      client.invalidateQueries(["getStoreCategories", storeID]);
      client.invalidateQueries(["getStoreDepartmentCategories", storeID, variables.departmentID]);
    }
  })
}

export function DeleteStoreCategory(client: QueryClient, storeID: number){
  return useMutation({
    mutationFn: (category: Category) =>
      fetch(`${baseurl}/Store/deleteCategory/${storeID}`, {
        credentials: 'include',
        method: 'DELETE',
        body: JSON.stringify(category),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        return res.ok
      }),

    onSuccess: (_, variables) => {
      client.invalidateQueries(["getStoreCategories", storeID]);
      client.invalidateQueries(["getStoreDepartmentCategories", storeID, variables.departmentID]);
    }
  })
}

export function GetStoreItemMessages(storeID: number) {
  return useQuery({
    queryKey: ["getStoreItemMessages", storeID],
    queryFn: ({signal}) =>
      fetch(`${baseurl}/Store/itemMessages/${storeID}`, {
        credentials: 'include',
        signal
      }).then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch')
        }
        return res.json() as unknown as ItemMessage[]
      }),
  })
}

export function CreateStoreItemMessage(client: QueryClient, storeID: number){
  return useMutation({
    mutationFn: (itemMessage: ItemMessage) =>
      fetch(`${baseurl}/Store/createItemMessage/${storeID}`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(itemMessage),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        return res.ok
      }),

    onSuccess: () => {
      client.invalidateQueries(["getStoreItemMessages", storeID]);
    }
  })
}

export function UpdateStoreItemMessage(client: QueryClient, storeID: number){
  return useMutation({
    mutationFn: (itemMessage: ItemMessage) =>
      fetch(`${baseurl}/Store/updateItemMessage/${storeID}`, {
        credentials: 'include',
        method: 'PATCH',
        body: JSON.stringify(itemMessage),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        return res.ok
      }),

    onSuccess: () => {
      client.invalidateQueries(["getStoreItemMessages", storeID]);
    }
  })
}

export function DeleteStoreItemMessage(client: QueryClient, storeID: number){
  return useMutation({
    mutationFn: (itemMessage: ItemMessage) =>
      fetch(`${baseurl}/Store/deleteItemMessage/${storeID}`, {
        credentials: 'include',
        method: 'DELETE',
        body: JSON.stringify(itemMessage),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        return res.ok
      }),

    onSuccess: () => {
      client.invalidateQueries(["getStoreItemMessages", storeID]);
    }
  })
}

export function GetStoreBatches(storeID: number) {
  return useQuery({
    queryKey: ["getStoreBatches", storeID],
    queryFn: ({signal}) =>
      fetch(`${baseurl}/Store/batches/${storeID}`, {
        credentials: 'include',
        signal
      }).then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch')
        }
        return res.json() as unknown as Batch[]
      }),
  })
}

export function GetStoreRegisters(storeID: number) {
  return useQuery({
    queryKey: ["getStoreRegisters", storeID],
    queryFn: ({signal}) =>
      fetch(`${baseurl}/Store/registers/${storeID}`, {
        credentials: 'include',
        signal
      }).then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch')
        }
        return res.json() as unknown as Register[]
      }),
  })
}

export function GetStoreEmployees(storeID: number) {
  return useQuery({
    queryKey: ["getStoreEmployees", storeID],
    queryFn: ({signal}) =>
      fetch(`${baseurl}/Store/employees/${storeID}`, {
        credentials: 'include',
        signal
      }).then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch')
        }
        return res.json() as unknown as PosUser[]
      }),
  })
}

export function GetStoreJournal(storeID: number, filter: JournalFilter) {
  return useQuery({
    queryKey: ["getStoreJournal", storeID, filter],
    queryFn: ({signal}) =>
      {
        //console.log(filter);

        const updatedFilter = {
          ...filter,
          dateFrom: filter.dateFrom ? toUTCDate(new Date(filter.dateFrom)) : undefined,
          dateTo: filter.dateTo ? toUTCDate(new Date(filter.dateTo)) : undefined,
        };
        
        //console.log(updatedFilter);

        return fetch(`${baseurl}/Store/journal/${storeID}`, {
          credentials: 'include',
          signal,
          method: 'POST',
          body: JSON.stringify(updatedFilter),
          headers: {
            "Content-Type": "application/json",
          },
        }).then((res) => {
          if (!res.ok) {
            throw new Error('Failed to fetch')
          }
          return res.json() as unknown as JournalPackage
        });
    }
  })
}

export function GetTransactionPackage(storeID: number, transactionID: number) {
  return useQuery({
    queryKey: ["getTransactionPackage", storeID, transactionID],
    queryFn: ({signal}) =>
      fetch(`${baseurl}/Store/journal/${storeID}/transaction/${transactionID}`, {
        credentials: 'include',
        signal
      }).then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch')
        }
        return res.json() as unknown as TransactionPackage
      }),
  })
}

//#region Currency
export function GetStoreCurrencies(storeID: number) {
  return useQuery({
    queryKey: ["getStoreCurrencies", storeID],
    queryFn: ({signal}) =>
      fetch(`${baseurl}/Store/currency/${storeID}`, {
        credentials: 'include',
        signal
      }).then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch')
        }
        return res.json() as unknown as Currency[]
      }),
  })
}
//#endregion

//#region Tender
export function GetStoreTenders(storeID: number) {
  return useQuery({
    queryKey: ["getStoreTenders", storeID],
    queryFn: ({signal}) =>
      fetch(`${baseurl}/Store/tenders/${storeID}`, {
        credentials: 'include',
        signal
      }).then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch')
        }
        return res.json() as unknown as Tender[]
      }),
  })
}

export function GetStoreTendersUsed(storeID: number) {
  return useQuery({
    queryKey: ["getStoreTendersUsed", storeID],
    queryFn: ({signal}) =>
      fetch(`${baseurl}/Store/tendersUsed/${storeID}`, {
        credentials: 'include',
        signal
      }).then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch')
        }
        return res.json() as unknown as string[]
      }),
  })
}

export function CreateStoreTender(client: QueryClient, storeID: number){
  return useMutation({
    mutationFn: (tender: Tender) =>
      fetch(`${baseurl}/Store/createTender/${storeID}`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(tender),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        return res.ok
      }),

    onSuccess: () => {
      client.invalidateQueries(["getStoreTenders", storeID]);
      client.invalidateQueries(["getStoreTendersUsed", storeID]);
    }
  })
}

export function UpdateStoreTender(client: QueryClient, storeID: number){
  return useMutation({
    mutationFn: (tender: Tender) =>
      fetch(`${baseurl}/Store/updateTender/${storeID}`, {
        credentials: 'include',
        method: 'PATCH',
        body: JSON.stringify(tender),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        return res.ok
      }),

    onSuccess: () => {
      client.invalidateQueries(["getStoreTenders", storeID]);
      client.invalidateQueries(["getStoreTendersUsed", storeID]);
    }
  })
}

export function DeleteStoreTender(client: QueryClient, storeID: number){
  return useMutation({
    mutationFn: (tender: Tender) =>
      fetch(`${baseurl}/Store/deleteTender/${storeID}`, {
        credentials: 'include',
        method: 'DELETE',
        body: JSON.stringify(tender),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        return res.ok
      }),

    onSuccess: () => {
      client.invalidateQueries(["getStoreTenders", storeID]);
      client.invalidateQueries(["getStoreTendersUsed", storeID]);
    }
  })
}
//#endregion

export function GetStoreTaxes(storeID: number) {
  return useQuery({
    queryKey: ["getStoreTaxes", storeID],
    queryFn: ({signal}) =>
      fetch(`${baseurl}/Store/taxes/${storeID}`, {
        credentials: 'include',
        signal
      }).then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch')
        }
        return res.json() as unknown as Tax[]
      }),
  })
}

export function GetStoreTaxesUsed(storeID: number) {
  return useQuery({
    queryKey: ["getStoreTaxesUsed", storeID],
    queryFn: ({signal}) =>
      fetch(`${baseurl}/Store/taxesUsed/${storeID}`, {
        credentials: 'include',
        signal
      }).then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch')
        }
        return res.json() as unknown as string[]
      }),
  })
}

export function CreateStoreTax(client: QueryClient, storeID: number){
  return useMutation({
    mutationFn: (tax: Tax) =>
      fetch(`${baseurl}/Store/createTax/${storeID}`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(tax),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        return res.ok
      }),

    onSuccess: () => {
      client.invalidateQueries(["getStoreTaxes", storeID]);
      client.invalidateQueries(["getStoreTaxesUsed", storeID]);
    }
  })
}

export function UpdateStoreTax(client: QueryClient, storeID: number){
  return useMutation({
    mutationFn: (tax: Tax) =>
      fetch(`${baseurl}/Store/updateTax/${storeID}`, {
        credentials: 'include',
        method: 'PATCH',
        body: JSON.stringify(tax),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        return res.ok
      }),

    onSuccess: () => {
      client.invalidateQueries(["getStoreTaxes", storeID]);
      client.invalidateQueries(["getStoreTaxesUsed", storeID]);
    }
  })
}

export function DeleteStoreTax(client: QueryClient, storeID: number){
  return useMutation({
    mutationFn: (tax: Tax) =>
      fetch(`${baseurl}/Store/deleteTax/${storeID}`, {
        credentials: 'include',
        method: 'DELETE',
        body: JSON.stringify(tax),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        return res.ok
      }),

    onSuccess: () => {
      client.invalidateQueries(["getStoreTaxes", storeID]);
      client.invalidateQueries(["getStoreTaxesUsed", storeID]);
    }
  })
}

//#region QuantityDiscount
export function GetStoreQuantityDiscount(storeID: number, syncGuid: string) {
  return useQuery({
    queryKey: ["getStoreQuantityDiscount", storeID, syncGuid],
    queryFn: ({signal}) =>
      fetch(`${baseurl}/Store/quantityDiscount/${storeID}/${syncGuid}`, {
        credentials: 'include',
        signal
      }).then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch')
        }
        return res.json() as unknown as QuantityDiscount
      }),
  })
}

export function GetStoreQuantityDiscountView(storeID: number, filter: QuantityDiscountFilter) {
  return useQuery({
    queryKey: ["getStoreQuantityDiscountView", storeID, filter],
    queryFn: ({signal}) =>
      fetch(`${baseurl}/Store/quantityDiscountsView/${storeID}`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(filter),
        headers: {
          "Content-Type": "application/json",
        },
        signal
      }).then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch')
        }
        return res.json() as unknown as QuantityDiscountPackage
      }),
  })
}

export function GetStoreQuantityDiscountBaskets(storeID: number, syncGuid: string) {
  return useQuery({
    queryKey: ["getStoreQuantityDiscountBaskets", storeID, syncGuid],
    queryFn: ({signal}) =>
      fetch(`${baseurl}/Store/quantityDiscount/${storeID}/${syncGuid}/items`, {
        credentials: 'include',
        signal
      }).then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch')
        }
        return res.json() as unknown as Basket[]
      }),
  })
}

export function SearchStoreItemsNotInBaskets(storeID: number, discountSyncGuid: string, searchTerm: string) {
  return useQuery({
    queryKey: ["searchStoreItemsNotInBaskets", storeID, discountSyncGuid, searchTerm],
    queryFn: ({signal}) =>
    {
      if(!searchTerm)
        return [];

      return fetch(`${baseurl}/Store/quantityDiscount/searchStoreItemsNotInBaskets`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify({
          storeID,
          discountSyncGuid,
          searchTerm
        }),
        headers: {
          "Content-Type": "application/json",
        },
        signal
      }).then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch')
        }
        return res.json() as unknown as ItemPreview[]
      });
    }
  })
}

export function CreateStoreQuantityDiscount(client: QueryClient, storeID: number){
  return useMutation({
    mutationFn: (discount: QuantityDiscountInjestPackage) =>
      fetch(`${baseurl}/Store/createQuantityDiscount/${storeID}`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify({
          ...discount,
          quantityDiscount: {
            ...discount.quantityDiscount,
            startDate: toUTCDate(new Date(discount.quantityDiscount.startDate)),
            endDate: toUTCDate(new Date(discount.quantityDiscount.endDate))
          }
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        return res.ok
      }),

    onSuccess: (_, discount) => {
      client.invalidateQueries(["getStoreQuantityDiscountView", storeID]);
      client.invalidateQueries(["getStoreQuantityDiscountBaskets", storeID, discount.quantityDiscount.syncGuid]);
      client.invalidateQueries(["getStoreQuantityDiscount", storeID, discount.quantityDiscount.syncGuid]);
    }
  })
}

export function UpdateStoreQuantityDiscount(client: QueryClient, storeID: number){
  return useMutation({
    mutationFn: (discount: QuantityDiscountInjestPackage) =>
      fetch(`${baseurl}/Store/updateQuantityDiscount/${storeID}`, {
        credentials: 'include',
        method: 'PATCH',
        body: JSON.stringify({
          ...discount,
          quantityDiscount: {
            ...discount.quantityDiscount,
            startDate: toUTCDate(new Date(discount.quantityDiscount.startDate)),
            endDate: toUTCDate(new Date(discount.quantityDiscount.endDate))
          }
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        return res.ok
      }),
    onSuccess: (_, discount) => {
      client.invalidateQueries(["getStoreQuantityDiscountView", storeID]);
      client.invalidateQueries(["getStoreQuantityDiscountBaskets", storeID, discount.quantityDiscount.syncGuid]);
      client.invalidateQueries(["getStoreQuantityDiscount", storeID, discount.quantityDiscount.syncGuid]);
    }
  })
}

export function DeleteStoreQuantityDiscount(client: QueryClient, storeID: number){
  return useMutation({
    mutationFn: (discount: QuantityDiscount) =>
      fetch(`${baseurl}/Store/deleteQuantityDiscount/${storeID}`, {
        credentials: 'include',
        method: 'DELETE',
        body: JSON.stringify(discount),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        return res.ok
      }),

    onSuccess: (_, discount) => {
      client.invalidateQueries(["getStoreQuantityDiscountView", storeID]);
      client.invalidateQueries(["getStoreQuantityDiscountBaskets", storeID, discount.syncGuid]);
      client.invalidateQueries(["getStoreQuantityDiscount", storeID, discount.syncGuid]);
    }
  })
}
//#endregion


//#region Item
export function GetStoreItems(storeID: number, filter: ItemFilter) {
  return useQuery({
    queryKey: ["getStoreItems", storeID, filter],
    queryFn: ({signal}) =>
      {
        return fetch(`${baseurl}/Store/itemView/${storeID}`, {
          credentials: 'include',
          signal,
          method: 'POST',
          body: JSON.stringify(filter),
          headers: {
            "Content-Type": "application/json",
          },
        }).then((res) => {
          if (!res.ok) {
            throw new Error('Failed to fetch')
          }
          return res.json() as unknown as ItemPackage
        });
    }
  })
}

export function GetItem(storeID: number, itemID: string) {
  return useQuery({
    queryKey: ["getItem", storeID, itemID],
    queryFn: ({signal}) =>
      fetch(`${baseurl}/Store/item/${storeID}/${itemID}`, {
        credentials: 'include',
        signal
      }).then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch')
        }
        return res.json() as unknown as Item
      }),
  })
}

export function CreateStoreItem(client: QueryClient, storeID: number){
  return useMutation({
    mutationFn: (item: Item) =>
      fetch(`${baseurl}/Store/createItem/${storeID}`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(item),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        return res.ok
      }),

    onSuccess: (_, variables) => {
      client.invalidateQueries(["getStoreItems", storeID]);
      client.invalidateQueries(["getItem", storeID, variables.syncGuid]);
    }
  })
}

export function UpdateStoreItem(client: QueryClient, storeID: number){
  return useMutation({
    mutationFn: (item: Item) =>
      fetch(`${baseurl}/Store/updateItem/${storeID}`, {
        credentials: 'include',
        method: 'PATCH',
        body: JSON.stringify(item),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        return res.ok
      }),

    onSuccess: (_, variables) => {
      client.invalidateQueries(["getStoreItems", storeID]);
      client.invalidateQueries(["getItem", storeID, variables.syncGuid]);
    }
  })
}

export function DeleteStoreItem(client: QueryClient, storeID: number){
  return useMutation({
    mutationFn: (item: Item) =>
      fetch(`${baseurl}/Store/deleteItem/${storeID}`, {
        credentials: 'include',
        method: 'DELETE',
        body: JSON.stringify(item),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        return res.ok
      }),

    onSuccess: (_, variables) => {
      client.invalidateQueries(["getStoreItems", storeID]);
      client.invalidateQueries(["getItem", storeID, variables.syncGuid]);
    }
  })
}
//#endregion

//#region Supplier
export function GetStoreSuppliers(storeID: number) {
  return useQuery({
    queryKey: ["getStoreSuppliers", storeID],
    queryFn: ({signal}) =>
      fetch(`${baseurl}/Store/suppliers/${storeID}`, {
        credentials: 'include',
        signal
      }).then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch')
        }
        return res.json() as unknown as Supplier[]
      }),
  })
}

export function GetStoreSupplierView(storeID: number, filter: SupplierFilter) {
  return useQuery({
    queryKey: ["getStoreSupplierView", storeID, filter],
    queryFn: ({signal}) =>
      {
        return fetch(`${baseurl}/Store/suppliersView/${storeID}`, {
          credentials: 'include',
          signal,
          method: 'POST',
          body: JSON.stringify(filter),
          headers: {
            "Content-Type": "application/json",
          },
        }).then((res) => {
          if (!res.ok) {
            throw new Error('Failed to fetch')
          }
          return res.json() as unknown as SupplierPackage
        });
    }
  })
}

export function GetSupplier(storeID: number, supplierID: string) {
  return useQuery({
    queryKey: ["getSupplier", storeID, supplierID],
    queryFn: ({signal}) =>
      fetch(`${baseurl}/Store/supplier/${storeID}/${supplierID}`, {
        credentials: 'include',
        signal
      }).then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch')
        }
        return res.json() as unknown as Supplier
      }),
  })
}

export function GetSupplierItems(storeID: number, supplierID: string) {
  return useQuery({
    queryKey: ["getSupplierItems", storeID, supplierID],
    queryFn: ({signal}) =>
      fetch(`${baseurl}/Store/supplier/${storeID}/${supplierID}/items`, {
        credentials: 'include',
        signal
      }).then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch')
        }
        return res.json() as unknown as ItemPreview[]
      }),
  })
}

export function SearchSupplierItems(storeID: number, supplierSyncGuid: string, searchTerm: string) {
  return useQuery({
    queryKey: ["searchSupplierItems", storeID, supplierSyncGuid, searchTerm],
    queryFn: ({signal}) =>
    {
      if(!searchTerm)
        return [];

      return fetch(`${baseurl}/Store/supplier/searchStoreItems`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify({
          storeID,
          supplierSyncGuid,
          searchTerm
        }),
        headers: {
          "Content-Type": "application/json",
        },
        signal
      }).then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch')
        }
        return res.json() as unknown as ItemPreview[]
      });
    }
  })
}

export function CreateStoreSupplier(client: QueryClient, storeID: number){
  return useMutation({
    mutationFn: (supplier: SupplierInjestPackage) =>
      fetch(`${baseurl}/Store/createSupplier/${storeID}`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(supplier),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        return res.ok
      }),

    onSuccess: (_, variables) => {
      client.invalidateQueries(["getStoreSuppliers", storeID]);
      client.invalidateQueries(["getStoreSupplierView", storeID]);
      client.invalidateQueries(["getSupplier", storeID, variables.supplier.syncGuid]);
      client.invalidateQueries(["getSupplierItems", storeID, variables.supplier.syncGuid]);
    }
  })
}

export function UpdateStoreSupplier(client: QueryClient, storeID: number){
  return useMutation({
    mutationFn: (supplier: SupplierInjestPackage) =>
      fetch(`${baseurl}/Store/updateSupplier/${storeID}`, {
        credentials: 'include',
        method: 'PATCH',
        body: JSON.stringify(supplier),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        return res.ok
      }),

    onSuccess: (_, variables) => {
      client.invalidateQueries(["getStoreSuppliers", storeID]);
      client.invalidateQueries(["getStoreSupplierView", storeID]);
      client.invalidateQueries(["getSupplier", storeID, variables.supplier.syncGuid]);
      client.invalidateQueries(["getSupplierItems", storeID, variables.supplier.syncGuid]);
    }
  })
}

export function DeleteStoreSupplier(client: QueryClient, storeID: number){
  return useMutation({
    mutationFn: (supplier: Supplier) =>
      fetch(`${baseurl}/Store/deleteSupplier/${storeID}`, {
        credentials: 'include',
        method: 'DELETE',
        body: JSON.stringify(supplier),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        return res.ok
      }),

    onSuccess: (_, variables) => {
      client.invalidateQueries(["getStoreSuppliers", storeID]);
      client.invalidateQueries(["getStoreSupplierView", storeID]);
      client.invalidateQueries(["getSupplier", storeID, variables.syncGuid]);
      client.invalidateQueries(["getSupplierItems", storeID, variables.syncGuid]);
    }
  })
}
//#endregion

//#region Virtual Stores
export function GetOrgVirtualStores(orgID: number) {
  return useQuery({
    queryKey: ["getVirtualStores", orgID],
    queryFn: ({signal}) =>
      fetch(`${baseurl}/Organization/stores/${orgID}`, {
        credentials: 'include',
        signal
      }).then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch')
        }
        return res.json() as unknown as VirtualStore[]
      }),
  })
}

export function CreateOrgVirtualStore(client: QueryClient, orgID: number){
  return useMutation({
    mutationFn: (virtualStore: VirtualStore) =>
      fetch(`${baseurl}/Organization/store/${orgID}`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(virtualStore),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        return res.ok
      }),

    onSuccess: () => {
      client.invalidateQueries(["getVirtualStores", orgID]);
    }
  })
}

export function UpdateOrgVirtualStore(client: QueryClient, orgID: number){
  return useMutation({
    mutationFn: (virtualStore: VirtualStore) =>
      fetch(`${baseurl}/Organization/store/${orgID}`, {
        credentials: 'include',
        method: 'PATCH',
        body: JSON.stringify(virtualStore),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        return res.ok
      }),

    onSuccess: () => {
      client.invalidateQueries(["getVirtualStores", orgID]);
    }
  })
}

export function DeleteOrgVirtualStore(client: QueryClient, orgID: number){
  return useMutation({
    mutationFn: (virtualStore: VirtualStore) =>
      fetch(`${baseurl}/Organization/store/${orgID}`, {
        credentials: 'include',
        method: 'DELETE',
        body: JSON.stringify(virtualStore),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        return res.ok
      }),

    onSuccess: () => {
      client.invalidateQueries(["getVirtualStores", orgID]);
    }
  })
}
//#endregion