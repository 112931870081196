import { IoAddCircleOutline, IoArrowBack } from "react-icons/io5";
import FormCheckbox from "../../../Components/FormCheckbox";
import FormInput from "../../../Components/FormInput";
import { ReadableTime } from "../../../Helper/ConversionHelper";
import { useQueryClient } from "react-query";
import { useState } from "react";
import { FaMoneyBillWave, FaPen, FaTrashAlt } from "react-icons/fa";
import { CreateStoreTender, DeleteStoreTender, GetStoreCurrencies, UpdateStoreTender } from "../../../Services/Requests";
import Tender from "../../../Model/POSModel/Tender";
import PaymentMethods from "../../../Model/POSModel/PaymentMethods";

export const TenderCard: React.FC<{ tender: Tender, storeID:number, modifyUser:string, editable?:boolean, deleteable?:boolean, isOrgStore?:boolean }> = ({ tender, storeID, modifyUser, editable = true, deleteable = true, isOrgStore = false }) => {

    const [editMode, setEditMode] = useState(false);

    return(
        <div className={`bg-slate-300 rounded-2xl px-4 pt-2 pb-4 transition-all duration-300 ease-linear border-black`}>
            {   !editMode &&
                <div className="flex justify-between items-center">
                    <div className="flex justify-between items-center space-x-2">
                        <h2 className="font-bold text-lg">{tender.description}</h2>
                        {editable && isOrgStore === tender.isCompanyData && <FaPen size="18" className="items-center relative flex justify-center
                        hover:text-orange-500 transition-all duration-300 ease-linear cursor-pointer"
                        onClick={() => setEditMode(true)}/>}
                    </div>
                    
                    <div className="flex justify-between items-center space-x-2">
                        <p className={`items-center relative flex justify-center
                        ${ tender.active ? "bg-green-500" : "bg-red-500"}
                        rounded-md text-white p-1 h-8`} >{tender.active ? "Enabled" : "Disabled"}</p>
                        
                        <FaMoneyBillWave size="45" className="items-center relative flex justify-center"/>
                    </div>
                </div>
            }
            

            { editMode ?
                <TenderEdit tender={tender} setEditMode={setEditMode} storeID={storeID} deleteable={deleteable}/>
                :
                <TenderInfo tender={tender} modifyUser={modifyUser} />
            }
        </div>
    );
}


interface FormProps {
    handleSubmit(e: any): void,
    onChange(e: any): void,
    onCheckChange(e: any): void,
    setEditMode(state: boolean): void,
    handleDelete?(): void,
    copy: Tender,
    isLoading: boolean,
    isError: boolean,
    storeID: number
}

const TenderForm: React.FC<FormProps> = ({handleSubmit, onChange, onCheckChange, setEditMode, handleDelete, copy, isLoading, isError, storeID}) => {

    const { isLoading:isLoadingCurrency, data:currencyData } = GetStoreCurrencies(storeID)

    return (
        <form onSubmit={handleSubmit}>
            <FormCheckbox className="my-4 flex space-x-2" label="Active"
            name="active" checked={copy.active} onChange={onCheckChange} />

            <FormInput className="" label="Code"
            type="text" required={true} pattern="^(?! *$)[A-Za-z0-9\- .]+$" errorMessage="An code is required. (No special characters)" placeholder="Debit"
            name="code" value={copy.code} onChange={onChange}/>

            <FormInput className="mb-2" label="Description"
            type="text" required={true} pattern="^(?! *$)[A-Za-z0-9\- .]+$" errorMessage="An description is required. (No special characters)" placeholder="DC"
            name="description" value={copy.description} onChange={onChange}/>

            <div className="mb-8">
                <label className="block text-gray-700 text-sm font-bold mb-2">Format</label>
                <select name="additionalDetailType" value={copy.additionalDetailType} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
                focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                onChange={onChange} required
                >
                    <option>Select an option</option>
                    <option value={Number(PaymentMethods.Cash)}>Cash</option>
                    <option value={Number(PaymentMethods.Cheque)}>Cheque</option>
                    <option value={Number(PaymentMethods.CreditCard)}>Credit Card</option>
                    <option value={Number(PaymentMethods.DebitCard)}>Debit Card</option>
                    <option value={Number(PaymentMethods.CreditOrDebitCard)}>Credit/Debit Card</option>
                    <option value={Number(PaymentMethods.Voucher)}>Voucher</option>
                    <option value={Number(PaymentMethods.Account)}>Account</option>
                    <option value={Number(PaymentMethods.Points)}>Points</option>
                    <option value={Number(PaymentMethods.Other)}>Other</option>
                </select>                

                <label className="block text-gray-700 text-sm font-bold my-2">Currency</label>
                { isLoadingCurrency?
                    <p>Loading</p>
                    :
                    <select name="currencyID" value={copy.currencyID} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
                    focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    onChange={onChange} required
                    >
                        <option>Select an option</option>
                        { currencyData && currencyData.map((currency) => {
                            return <option value={currency.syncGuid}>{currency.description} ({currency.code})</option>
                        })}
                    </select>
                }

                
            </div>

            <FormCheckbox className="mb-4 flex space-x-2" label="Pop cash drawer"
            name="popCashDrawer" checked={copy.popCashDrawer} onChange={onCheckChange} />

            <FormCheckbox className="mb-4 flex space-x-2" label="Require signature"
            name="signatureRequired" checked={copy.signatureRequired} onChange={onCheckChange} />

            <div className="flex justify-between">
                <IoArrowBack size="28" className="items-center relative flex justify-center mt-1
                    hover:text-white transition-all duration-300 ease-linear cursor-pointer"
                    onClick={() => setEditMode(false)}/>

                { handleDelete &&
                    <div className="flex flex-col items-center">
                        <p className="text-sm">Delete</p>
                        <FaTrashAlt size="28" className="items-center relative flex justify-center
                        hover:text-red-600 transition-all duration-300 ease-linear cursor-pointer"
                        onClick={() => handleDelete()}/>
                    </div>
                }

                {
                    isLoading ?
                    <button className="bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" disabled>
                        Processing...
                    </button>
                    :
                    isError ?
                    <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                        Error
                    </button>
                    :
                    <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                    Save
                    </button>
                }
            </div>
        </form>
    );
}

const TenderInfo: React.FC<{ tender: Tender, modifyUser:string }> = ({ tender, modifyUser }) => {


    return(
        <div>
            <p className="text-sm text-gray-700 -my-1">
                <span className="font-bold">Code: </span>
                {tender.code}
            </p>
            <p className="text-sm text-gray-700 -my-1">
                <span className="font-bold">Modify user: </span>
                {modifyUser}
            </p>
            <p className="text-sm text-gray-700 -my-1">
                <span className="font-bold">Last modified: </span>
                {ReadableTime(tender.modifyDatetime)}
            </p>
            <p className="text-sm text-gray-700 -my-1">
                <span className="font-bold">Company managed: </span>
                {tender.isCompanyData ? "Yes" : "No"}
            </p>
        </div>
    );
}


const TenderEdit: React.FC<{ tender: Tender, storeID: number, setEditMode: (state: boolean) => void, deleteable:boolean }> = ({ tender, setEditMode, storeID, deleteable }) => {

    const queryClient = useQueryClient();
    const { mutateAsync, isLoading, isError } = UpdateStoreTender(queryClient, storeID);
    const { mutateAsync: deleteAsync, isLoading: isDeleting } = DeleteStoreTender(queryClient, storeID)
    const [copy, setCopy] = useState(tender);

    const onChange = (e:any) => {
        setCopy({...copy, [e.target.name]: e.target.value})
    }
    const onCheckChange = (e:any) => {
        setCopy({...copy, [e.target.name]: e.target.checked})
        console.log(copy);
    }
    const handleSubmit = async (event: any) => {
        event.preventDefault();

        try {
            let result = await mutateAsync(copy);

            if(result){
                setEditMode(false);
            }

        } catch (e) {
            console.error(e);
        }
    }
    const handleDelete = async () => {
        try {
            let result = await deleteAsync(copy);

            // if(result){
            //     setEditMode(false);
            // }

        } catch (e) {
            console.error(e);
        }
    }

    return(
        <TenderForm handleSubmit={handleSubmit} onChange={onChange} onCheckChange={onCheckChange} setEditMode={setEditMode} handleDelete={deleteable ? handleDelete : undefined} copy={copy} isLoading={isLoading} isError={isError} storeID={storeID} />
    );
}

export const TenderNew: React.FC<{ storeID: number, }> = ({ storeID, }) => {
    const [editMode, setEditMode] = useState(false);

    const queryClient = useQueryClient();
    const { mutateAsync, isLoading, isError } = CreateStoreTender(queryClient, storeID);

    // useEffect(() => {
    //     resetEditMode(false);
    // }, [departmentGuid])

    const template = {
        code: "",
        description: "",
        active: true,
        modifyUser: "00000000-0000-0000-0000-000000000000",
        modifyDatetime: new Date(),
        syncGuid: "00000000-0000-0000-0000-000000000000", //Placeholder - replaced by server
        isCompanyData: false,
        additionalDetailType: 0,
        printerValidation: false,
        preventOverTendering: false,
        scanCode: 0,
        validationLine1: "",
        validationLine2: "",
        validationLine3: "",
        maximumAmount: 0,
        roundToValue: 0,
        popCashDrawer: false,
        currencyID: "00000000-0000-0000-0000-000000000000",
        displayOrder: 0,
        validationMask: "",
        signatureRequired: false,
        allowMutipleEntires: false,
        debitSurcharge: 0,
        supportCashBack: false,
        cashBackLimit: 0,
        cashBackFee: 0,
        cashbackTenderID: 0,
        verifyCheckInfo: false,
        verifyViaEDC: false,
      };

    const [copy, setCopy] = useState<Tender>(template);

    const resetEditMode = (state: boolean) => {
        setEditMode(state);
        setCopy(template);
    }

    const onChange = (e:any) => {
        setCopy({...copy, [e.target.name]: e.target.value})
        console.log(copy);
    }
    const onCheckChange = (e:any) => {
        setCopy({...copy, [e.target.name]: e.target.checked})
        console.log(copy);
    }


    const handleSubmit = async (event: any) => {
        event.preventDefault();

        try {
            let result = await mutateAsync(copy);

            if(result){
                resetEditMode(false);
            }

        } catch (e) {
            console.error(e);
        }
    }

    return (
        <div className={`relative flex rounded-2xl px-4 py-2 justify-center bg-slate-300
                transition-all duration-300 ease-linear ${editMode ? "" : "cursor-pointer hover:bg-slate-600 hover:text-white" }`}
                onClick={() => !editMode && setEditMode(true)}>


                { !editMode ?
                    <IoAddCircleOutline size="28"/>
                    :
                    <TenderForm handleSubmit={handleSubmit} onChange={onChange} onCheckChange={onCheckChange} setEditMode={resetEditMode} copy={copy} isLoading={isLoading} isError={isError} storeID={storeID} />
                }
                
            </div>
    );
}