import logo from '../Assets/Logo.png';
import e200 from '../Assets/E200.png';
import {useNavigate} from "react-router-dom";
import {useFusionAuth} from "@fusionauth/react-sdk";
import {useEffect} from "react";

function Landing(){
  const navigate = useNavigate();

  const {isLoggedIn, isFetchingUserInfo, startLogin, startRegister,} = useFusionAuth();

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/home');
    }
  }, [isLoggedIn, navigate]);

  if (isLoggedIn || isFetchingUserInfo) {
    return null;
  }

  return (
    <div className=' bg-landing bg-no-repeat bg-cover h-full flex content-center items-center justify-center space-x-72'>
      <div className='flex-col mb-52'>
        <img className=' h-64 pb-14 mb-8' src={logo} alt='1Solutions Logo'/>
        <div className=' bg-offWhite bg-opacity-60 rounded-2xl p-12 w-full justify-center flex'>
          <div className='flex-col flex'>
            <button className=' h-12 w-32 bg-primaryBlue hover:opacity-70 text-white text-2xl font-Lexend font-bold m-2 rounded-lg transition-all duration-300 ease-linear' onClick={() => startLogin()}>Login</button>
            <button className=' h-12 w-32 bg-primaryBlue hover:opacity-70 text-white text-2xl font-Lexend font-bold m-2 rounded-lg transition-all duration-300 ease-linear' onClick={() => startRegister()}>Register</button>
          </div>
        </div>
      </div>
      <img className=' object-scale-down h-4/5 mt-40 pl-2' src={e200} alt='E200 POS Hardware'/>
    </div>
  )
}
  
export default Landing;
  