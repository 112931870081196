import { FaBox, FaBuilding, FaMoneyBillWave, FaPercentage, FaEnvelope, FaTag, FaTruck } from "react-icons/fa";
import { Page } from "./Pages";

export const StoreScrollBar: React.FC<{ selectedPage:Page, setSelectedPage: (page:Page) => void }> = ({ selectedPage, setSelectedPage }) => {

    const handleSelect = (page:Page) => {
        setSelectedPage(page);
    }

    return(
        <div className="flex flex-row overflow-x-auto space-x-2 w-full">
            <ScrollBarItem icon={<FaBuilding size="28"/>} text="Departments & Categories" page={Page.Department} selectedPage={selectedPage} handleSelect={handleSelect}/>
            <ScrollBarItem icon={<FaBox size="28"/>} text="Items" page={Page.Item} selectedPage={selectedPage} handleSelect={handleSelect}/>
            <ScrollBarItem icon={<FaTag size="28"/>} text="Discount (Item)" page={Page.QuantityDiscount} selectedPage={selectedPage} handleSelect={handleSelect}/>
            {/* <ScrollBarItem icon={<FaClipboardList size="28"/>} text="Discount (Preset)" page={Page.Placeholder} selectedPage={selectedPage} handleSelect={handleSelect}/> */}
            <ScrollBarItem icon={<FaTruck size="28"/>} text="Suppliers" page={Page.Supplier} selectedPage={selectedPage} handleSelect={handleSelect}/>
            <ScrollBarItem icon={<FaEnvelope size="28"/>} text="Item Messages" page={Page.ItemMessage} selectedPage={selectedPage} handleSelect={handleSelect}/>
            <ScrollBarItem icon={<FaPercentage size="28"/>} text="Taxes" page={Page.Tax} selectedPage={selectedPage} handleSelect={handleSelect}/>
            <ScrollBarItem icon={<FaMoneyBillWave size="28"/>} text="Payment Methods" page={Page.Tender} selectedPage={selectedPage} handleSelect={handleSelect}/>
        </div>
    );
}

export const OrgScrollBar: React.FC<{ selectedPage:Page, setSelectedPage: (page:Page) => void }> = ({ selectedPage, setSelectedPage }) => {

    const handleSelect = (page:Page) => {
        setSelectedPage(page);
    }

    return(
        <div className="flex flex-row overflow-x-auto space-x-2 w-full">
            <ScrollBarItem icon={<FaBuilding size="28"/>} text="Departments & Categories" page={Page.Department} selectedPage={selectedPage} handleSelect={handleSelect}/>
            <ScrollBarItem icon={<FaBox size="28"/>} text="Items" page={Page.Item} selectedPage={selectedPage} handleSelect={handleSelect}/>
            <ScrollBarItem icon={<FaTag size="28"/>} text="Discount (Item)" page={Page.QuantityDiscount} selectedPage={selectedPage} handleSelect={handleSelect}/>
            {/* <ScrollBarItem icon={<FaClipboardList size="28"/>} text="Discount (Preset)" page={Page.Placeholder} selectedPage={selectedPage} handleSelect={handleSelect}/> */}
            <ScrollBarItem icon={<FaTruck size="28"/>} text="Suppliers" page={Page.Supplier} selectedPage={selectedPage} handleSelect={handleSelect}/>
            <ScrollBarItem icon={<FaEnvelope size="28"/>} text="Item Messages" page={Page.ItemMessage} selectedPage={selectedPage} handleSelect={handleSelect}/>
            <ScrollBarItem icon={<FaPercentage size="28"/>} text="Taxes" page={Page.Tax} selectedPage={selectedPage} handleSelect={handleSelect}/>
        </div>
    );
}

const ScrollBarItem: React.FC<{ icon: any, text:string, page:Page, selectedPage:Page, handleSelect: (page:Page) => void }> = ({ icon, text, page, selectedPage, handleSelect }) => {

    return(
        <div className={`${page === selectedPage ? "bg-white" : "bg-black"} group cursor-pointer min-w-28 w-28 justify-center rounded-md`}
        onClick={() => handleSelect(page)}>
            <div className="flex items-center justify-center
                h-12 w-12 my-2 mx-auto shadow-lg
                bg-secondaryBlue text-primaryBlue
                group-hover:bg-primaryBlue group-hover:text-white
                rounded-3xl group-hover:rounded-xl
                transition-all duration-300 ease-linear
                ">
                {icon}
            </div>
            <p className="p-2 m-2
                rounded-md shadow-md
                text-white bg-tertiaryBlue
                text-xs font-bold text-center">
                    {text}
            </p>
        </div>
    );
}