export enum TransactionType{
    Sale = 1,
    NoSale = 7,
    AbortedTransaction = 8,
    CashDrop = 9,
    Payout = 10,
    XReport = 14,
    ZReport = 15,
    ZZReport = 16,
    BlindClose = 17
}

export type JournalView = {
    batchID: number,
    transactionType: number,
    time: Date,
    cashierID: string,
    registerNumber: string,
    transactionNumber: number,
    receiptNumber: string,
    tenderName: string,
    tenderTotal: number
}

export type JournalPackage = {
    totalCount: number,
    transactions: JournalView[]
}