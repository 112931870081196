import { FaBook, FaBoxes } from "react-icons/fa";
import { Page } from "./Pages";

export const ScrollBar: React.FC<{ selectedPage:Page, setSelectedPage: (page:Page) => void }> = ({ selectedPage, setSelectedPage }) => {

    const handleSelect = (page:Page) => {
        setSelectedPage(page);
    }

    return(
        <div className="flex flex-row overflow-x-auto space-x-2 w-full">
            <ScrollBarItem icon={<FaBoxes size="28"/>} text="Batches" page={Page.Batch} selectedPage={selectedPage} handleSelect={handleSelect}/>
            <ScrollBarItem icon={<FaBook size="28"/>} text="Journal" page={Page.Journal} selectedPage={selectedPage} handleSelect={handleSelect}/>
        </div>
    );
}

const ScrollBarItem: React.FC<{ icon: any, text:string, page:Page, selectedPage:Page, handleSelect: (page:Page) => void }> = ({ icon, text, page, selectedPage, handleSelect }) => {

    return(
        <div className={`${page == selectedPage ? "bg-white" : "bg-black"} group cursor-pointer min-w-28 w-28 justify-center rounded-md`}
        onClick={() => handleSelect(page)}>
            <div className="flex items-center justify-center
                h-12 w-12 my-2 mx-auto shadow-lg
                bg-secondaryBlue text-primaryBlue
                group-hover:bg-primaryBlue group-hover:text-white
                rounded-3xl group-hover:rounded-xl
                transition-all duration-300 ease-linear
                ">
                {icon}
            </div>
            <p className="p-2 m-2
                rounded-md shadow-md
                text-white bg-tertiaryBlue
                text-xs font-bold text-center">
                    {text}
            </p>
        </div>
    );
}