import { Category } from "../Model/POSModel/Category";
import Currency from "../Model/POSModel/Currency";
import { Department } from "../Model/POSModel/Department";
import { PosUser } from "../Model/POSModel/PosUser"
import { Register } from "../Model/POSModel/Register"
import { Supplier } from "../Model/POSModel/Supplier";
import Tax from "../Model/POSModel/Tax";

export const FindRegisterName = (id: string, data: Register[]): string => {
    let register = data.find(i => i.syncGuid === id);
    return register ? register.description : "Unknown Register";
}

export const FindEmployeeName = (id: string, data: PosUser[]): string => {
    let user = data.find(i => i.syncGuid === id);
    return user ? user.firstName + " " + user.lastName : "Unknown User";
}

export const FindDepartmentName = (id: string, data: Department[]): string => {
    let obj = data.find(i => i.syncGuid === id);
    return obj ? obj.name : "";
}

export const FindCategoryName = (id: string, data: Category[]): string => {
    let obj = data.find(i => i.syncGuid === id);
    return obj ? obj.name : "";
}

export const FindSupplierName = (id: string, data: Supplier[]): string => {
    let obj = data.find(i => i.syncGuid === id);
    return obj ? obj.name : "None (N/A)";
}

export const FindTaxName = (id: string, data: Tax[]): string => {
    let obj = data.find(i => i.syncGuid === id);
    return obj ? `${obj.description} (${obj.code})` : "None (N/A)";
}

export const FindCurrencyName = (id: string, data: Currency[]): string => {
    let obj = data.find(i => i.syncGuid === id);
    return obj ? `${obj.description} (${obj.code})` : "None (N/A)";
}