import {useNavigate} from "react-router-dom";
import {useFusionAuth} from "@fusionauth/react-sdk";
import {useEffect, useState} from "react";
import FormInput from '../Components/FormInput';
import { UpdateAccount } from '../Model/Transactional';
import { GetUser, UpdateUser } from "../Services/Requests";
import { useQueryClient } from "react-query";

function Account(){
  const navigate = useNavigate();
  const [values, setValues] = useState<UpdateAccount>({
    firstName: "",
    lastName: "",
    email: "",
    phone: ""
  })

  const {isLoggedIn, isFetchingUserInfo} = useFusionAuth();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/');
    }
  }, [isLoggedIn, navigate]);

  const { isLoading: isLoadingGU, data } = GetUser();
  const queryClient = useQueryClient();
  const { mutateAsync, data: updateData, isLoading: isLoadingUU, isError } = UpdateUser(queryClient);

  useEffect(() => {
    if(!isLoadingGU && data){
      setValues(data);
    }
  }, [data, isLoadingGU]);

  if (!isLoggedIn || isFetchingUserInfo || isLoadingGU) {
    return null;
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    try {
        await mutateAsync(values);
    } catch (e) {
        console.error(e);
    }
  }
  const onChange = (e:any) => {
    setValues({...values, [e.target.name]: e.target.value})
  }

  return (
    <div className="flex justify-center">
      <div className='pt-6'>
        <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" onSubmit={handleSubmit}>
          <div className="mb-4">
            <h1 className='font-bold text-xl'>Edit Account Details</h1>
            <p className='text-sm'>Please make any needed adjustments to your information.</p>
          </div>
          <div>
            <FormInput className="mb-4" label="First Name" 
            type="text" required={true} pattern="^(?! *$)[A-Za-z0-9\- .]+$" errorMessage="An address is required. (No special characters)" placeholder="7-35 Goderich Rd." name="firstName" value={values["firstName"]} onChange={onChange}/>

            <FormInput className="mb-4" label="Last Name" 
            type="text" required={true} pattern="^(?! *$)[A-Za-z0-9\- .]+$" errorMessage="An address is required. (No special characters)" placeholder="7-35 Goderich Rd." name="lastName" value={values["lastName"]} onChange={onChange}/>

            <FormInput className="mb-4" label="Email" 
            type="email" required={true} errorMessage="A valid email is required." placeholder="me@example.com" name="email" value={values["email"]} onChange={onChange}/>

            <FormInput className="mb-4" label="Phone" 
            type="text" required={true} pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" errorMessage="A valid phone number is required. Format: 123-456-7890" placeholder="123-456-7890" name="phone" value={values["phone"]} onChange={onChange}/>
          </div>

          <div className="flex items-center justify-center">
            { isLoadingUU ?
              <button className="bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" disabled>
                Processing...
              </button>
              :
              isError ?
              <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                Error
              </button>
              :
              updateData ?
              <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                Saved
              </button>
              :
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                Save
              </button>
            }
          </div>
        </form>
        
        <p className="text-center text-gray-500 text-xs">
            &copy;2024 1Solutions Data Corperation. All rights reserved.
        </p>
      </div>
    </div>
  )
}
  
export default Account;
  