import { Store } from "../../../Model/Store";
import { GetStoreEmployees, GetStoreTenders, GetStoreTendersUsed } from "../../../Services/Requests";
import { TenderCard, TenderNew } from "../Components/TenderCard";
import { FindEmployeeName } from "../../../Helper/FinderHelper";

export const TenderPage: React.FC<{ store:Store, isOrgStore?:boolean }> = ({ store, isOrgStore = false }) => {

    const { isLoading, data } = GetStoreTenders(store.id);
    const { isLoading:isLoadingUsed, data:dataUsed } = GetStoreTendersUsed(store.id);
    const { isLoading:isLoadingUsers, data:userData } = GetStoreEmployees(store.id);

    return(
        <div className="flex justify-evenly pt-6 px-2 flex-row h-screen space-x-2">
            <div className="bg-offWhite shadow-xl rounded-3xl px-5 pb-8 mb-4 flex flex-col">
                <div className="mb-4 mt-4">
                    <div className="flex justify-between items-center">
                        <h1 className='font-bold text-xl'>Payment Methods:</h1>
                        {/* <button onClick={() => setSearchVisible(true)}>
                            <FaSearch />
                        </button> */}
                    </div>
                    Payment methods that have been used in a transaction cannot be deleted.
                </div>
                <div className="bg-slate-400 rounded-lg overflow-y-auto space-y-7 p-3">

                    { !isLoading && !isLoadingUsed && !isLoadingUsers ?
                        data && dataUsed && userData && data.map((tender) => {
                            return <TenderCard tender={tender} storeID={store.id} key={tender.syncGuid} modifyUser={FindEmployeeName(tender.modifyUser, userData)} deleteable={!dataUsed.includes(tender.syncGuid)} isOrgStore={isOrgStore}/>
                        })
                        :
                        <div>Loading...</div>
                    }

                    <TenderNew storeID={store.id} />
                </div>
            </div>            
        </div>
    );
}