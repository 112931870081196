import { useEffect, useState } from "react";
import FormInput from "../../../Components/FormInput";
import QuantityDiscount, { Basket, QuantityDiscountInjestPackage, QuantityDiscountType } from "../../../Model/POSModel/QuantityDiscount";
import { ItemPreview } from "../../../Model/POSModel/Item";
import { CreateStoreQuantityDiscount, DeleteStoreQuantityDiscount, SearchStoreItemsNotInBaskets, UpdateStoreQuantityDiscount } from "../../../Services/Requests";
import { ReadableMoney } from "../../../Helper/ConversionHelper";
import { FaTimes, FaTrashAlt } from "react-icons/fa";
import { useQueryClient } from "react-query";
import FormCheckbox from "../../../Components/FormCheckbox";
import { ConvertDiscountType } from "../../../Helper/EnumToString";

interface ItemSearchProps {
    storeID: number,
    discount: QuantityDiscount,
    baskets: Basket[],
    selectedBasketIndex: number,
    setEditingBasket(baskets?: Basket[]):void,
    addItemToBasket(item: ItemPreview):void
};

export const ItemSearchWindow: React.FC<ItemSearchProps> = ({ storeID, discount, addItemToBasket, baskets }) => {

    const [tempSearchTerm, setTempSearchTerm] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTempSearchTerm(event.target.value);
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        setSearchTerm(tempSearchTerm);
    }

    const handleSelectItem = (item: ItemPreview) => {
        //onSelect(item);
        //onClose();
    }

    const { data, isLoading } = SearchStoreItemsNotInBaskets(storeID, discount.syncGuid, searchTerm);
    const [filteredData, setFilteredData] = useState<ItemPreview[]>([]);

    useEffect(() => {
        if(data){
            let flatItems = baskets.flatMap(basket => basket.items.flatMap(item => item.syncGuid));
            setFilteredData(data.filter(item => !flatItems.includes(item.syncGuid)));
        }
    }, [data, baskets]);

    return (
        <div className="bg-offWhite shadow-xl rounded-3xl px-5 pb-8 flex flex-col w-1/2">
            <h1 className='font-bold text-xl my-4'>Search Items</h1>
            <p>Items used in other discounts are excluded from the results.</p>
            <form onSubmit={handleSubmit}>
                <div className="flex space-x-2 items-end">
                    <FormInput type="text" value={tempSearchTerm} onChange={handleSearchChange} placeholder="2L Cola" className="w-full"/>
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                        Search
                    </button>
                </div>
            </form>
            <ul className='bg-slate-400 rounded-lg overflow-y-auto mt-2 h-full'>
                {isLoading && <span className="w-full flex justify-center">Loading...</span>}
                {filteredData.map(item => (
                    <li className='p-2 cursor-pointer hover:bg-gray-200' key={item.syncGuid}
                    onClick={() => addItemToBasket(item)}
                    >
                        <SearchResult data={item}/>
                    </li>
                ))}
            </ul>
        </div>
    );
}

type SearchResultProps = {
    data: ItemPreview,
}

const SearchResult: React.FC<SearchResultProps> = ({data}) => {
    return (
        <div className="bg-slate-300 rounded-lg px-2 py-2 grid grid-cols-3 gap-4">
            <p>{data.itemNumber}</p>
            <p>{data.description}</p>
            <p>{ReadableMoney(data.price)}</p>
        </div>
    );
}

interface BasketEditProps {
    storeID: number,
    discount: QuantityDiscount,
    baskets: Basket[],
    selectedBasketIndex: number,
    setSelecedBasket(index: number):void,
    removeItemFromBasket(itemIndex: number):void
};

export const BasketEditWindow: React.FC<BasketEditProps> = ({ storeID, discount, baskets, selectedBasketIndex, setSelecedBasket, removeItemFromBasket }) => {

    var basket = baskets[selectedBasketIndex];

    return(
        <div className="bg-offWhite shadow-xl rounded-3xl px-5 pb-8 flex flex-col w-1/2">
            <h1 className='font-bold text-xl my-4'>Items to discount</h1>

            {   discount.type >= QuantityDiscountType.MMPriceBasket &&
                <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
                    {baskets.map((bskt, index) => 
                        <li className="me-2">
                            <button onClick={() => setSelecedBasket(index)} disabled={index === selectedBasketIndex} className={`inline-block p-4 rounded-t-lg ${index === selectedBasketIndex ? "active text-blue-600 bg-gray-100 dark:bg-gray-800 dark:text-blue-500" : "hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300"}`}>
                                {"Basket " + (bskt.number + 1)}
                            </button>
                        </li>)
                    }
                </ul>
            }

            <div className="overflow-y-auto">

                <div className="bg-slate-400">
                    <table className="w-full table-auto">
                        <thead className="bg-slate-400 sticky top-0">
                            <tr>
                            <th>Item #</th>
                            <th>Description</th>
                            <th>Amount</th>
                            <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {basket.items.map((item, index) => {
                                return (<tr key={item.syncGuid} className={"even:bg-offWhite odd:bg-slate-300 border-b border-gray-700"}>
                                <td>
                                    <div className="flex justify-center">
                                    {item.itemNumber}
                                    </div>
                                </td>
                                <td>
                                    <div className="flex justify-center">
                                    {item.description}
                                    </div>
                                </td>
                                <td>
                                    <div className="flex justify-center">
                                    {ReadableMoney(item.price)}
                                    </div>
                                </td>
                                <td>
                                    <div className="flex justify-center">
                                        <button onClick={() => removeItemFromBasket(index)}>
                                            <FaTimes size="22" className="items-center relative flex justify-center
                                            hover:text-red-600 transition-all duration-300 ease-linear cursor-pointer"/>
                                        </button>
                                    </div>
                                </td>
                                </tr>)
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

interface EditQuantityDiscountProps {
    editingDiscount:QuantityDiscount,
    storeID: number,
    editingBasket: Basket[],
    onWindowExit(): void,
    setEditingDiscount(discount: QuantityDiscount): void
};

export const EditQuantityDiscountWindow: React.FC<EditQuantityDiscountProps> = ({editingDiscount:copy, storeID, editingBasket, onWindowExit, setEditingDiscount:setCopy}) => {

    //const [copy, setCopy] = useState(editingDiscount);

    const queryClient = useQueryClient();
    const { mutateAsync: createAsync, isLoading: isCreateLoading, isError:isCreateError } = CreateStoreQuantityDiscount(queryClient, storeID);
    const { mutateAsync: updateAsync, isLoading: isUpdateLoading, isError:isUpdateError } = UpdateStoreQuantityDiscount(queryClient, storeID);
    const { mutateAsync: deleteAsync, isLoading: isDeleting } = DeleteStoreQuantityDiscount(queryClient, storeID);

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    let [isModifyLoading, setIsModifyLoading] = useState(false);
    let [isError, setIsError] = useState(false);

    useEffect(() => {
        setIsModifyLoading(isCreateLoading || isUpdateLoading);
        setIsError(isCreateError || isUpdateError);
    }, [isCreateLoading, isUpdateLoading, isCreateError, isUpdateError])

    useEffect(() => {
        if(copy){
            let startDate = copy.startDate ? new Date(copy.startDate).toISOString().split('T')[0] : "";
            setStartDate(startDate);

            let endDate = copy.endDate ? new Date(copy.endDate).toISOString().split('T')[0] : "";
            setEndDate(endDate);
        }
    }, [copy])

    const handleSubmit = async (event: any) => {
        event.preventDefault(); 

        let discountPackage: QuantityDiscountInjestPackage = {
            quantityDiscount: copy,
            baskets: editingBasket
        };

        try {
            let result = copy.syncGuid === "00000000-0000-0000-0000-000000000000" ? await createAsync(discountPackage) : await updateAsync(discountPackage);

            if(result){
                onWindowExit();
            }

        } catch (e) {
            console.error(e);
        }
    }

    const handleDelete = async () => {
        try {
            if(!copy)
                return;

            let result = await deleteAsync(copy);

            if(result){
                onWindowExit();
            }

        } catch (e) {
            console.error(e);
        }
    }

    const onChange = (e:any) => {
        copy && setCopy({...copy, [e.target.name]: e.target.value})
    }
    const onChangeNumber = (e:any) => {
        copy && setCopy({...copy, [e.target.name]: Number(e.target.value)})
    }
    const onCheckChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.type === "radio") {
            setCopy({
                ...copy!,
                [e.target.name]: e.target.value === "true"
            });
        } else {
            setCopy({
                ...copy!,
                [e.target.name]: e.target.checked
            });
        }
    };

    //console.log(basketData);
    console.log(copy);

    return (
        <div className="bg-offWhite shadow-xl rounded-3xl px-5 pb-8 mb-4 flex flex-col w-1/2">
            <div className="mb-4 mt-4">
                <div className="flex justify-between items-center">
                    <div className="flex justify-between items-center space-x-2">
                        <h1 className='font-bold text-xl'>{copy ? "Edit Discount" : "Discount Details"}</h1>
                    </div>
                    <button onClick={onWindowExit}>
                        <FaTimes size="28" className="items-center relative flex justify-center
                        hover:text-red-600 transition-all duration-300 ease-linear cursor-pointer"/>
                    </button>
                </div>
            </div>

            <div className="overflow-y-auto">
                <form className="space-y-2" onSubmit={handleSubmit}>
                    <FormCheckbox className="flex space-x-2" label="Active"
                    name="active" checked={copy.active} onChange={onCheckChange} />

                    <FormInput label="Start Date"
                    type="date" required
                    name="startDate" value={startDate} onChange={onChange} />

                    <FormInput label="End Date"
                    type="date" required
                    name="endDate" value={endDate} onChange={onChange} />

                    <FormInput label="Description"
                    type="text" required={true} pattern="^(?! *$)[A-Za-z0-9\$\*\- .]+$" errorMessage="A description is required. (No special characters)" placeholder="Buy 3 X for $2"
                    name="description" value={copy.description} onChange={onChange}/>

                    <FormCheckbox className="flex space-x-2" label="Show Discount Message (2nd monitor)"
                    name="showDiscountMessage" checked={copy.showDiscountMessage} onChange={onCheckChange} />

                    <FormCheckbox className="flex space-x-2" label="Ignore Other Discount"
                    name="ignoreOtherDiscount" checked={copy.ignoreOtherDiscount} onChange={onCheckChange} />

                    <div>
                        <label className="block text-gray-700 text-sm font-bold mb-2">Type</label>
                        <select name="type" value={copy.type} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
                        focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        onChange={onChangeNumber} required
                        >
                            <option>Select an option</option>
                            <option value={QuantityDiscountType.MMPrice}>{ConvertDiscountType(QuantityDiscountType.MMPrice)}</option>
                            <option value={QuantityDiscountType.MMPercentOff}>{ConvertDiscountType(QuantityDiscountType.MMPercentOff)}</option>
                            <option value={QuantityDiscountType.BuyXGetY}>{ConvertDiscountType(QuantityDiscountType.BuyXGetY)}</option>
                            <option value={QuantityDiscountType.MMPriceBasket}>{ConvertDiscountType(QuantityDiscountType.MMPriceBasket)}</option>
                            <option value={QuantityDiscountType.MMPercentOffBasket}>{ConvertDiscountType(QuantityDiscountType.MMPercentOffBasket)}</option>
                        </select>
                    </div>

                    { copy.type === QuantityDiscountType.BuyXGetY &&
                        <div>
                            <FormInput
                            type="number" min="1" step="1" required={true} label="Quantity to buy at full price"
                            name="quantity1" value={copy.quantity1} onChange={onChangeNumber}/>

                            <FormInput
                            type="number" min="0" step="1" required={true} label="Quantity to get discount price"
                            name="quantity2" value={copy.quantity2} onChange={onChangeNumber}/>

                            <FormInput
                            type="number" min="0.00" step="0.01" required={true} label="Discount price ($)"
                            name="price2" value={copy.price2} onChange={onChangeNumber}/>
                        </div>
                    }

                    { (copy.type === QuantityDiscountType.MMPriceBasket || copy.type === QuantityDiscountType.MMPercentOffBasket) &&
                        <div className="space-y-2">
                            <FormInput
                            type="number" min="1" step="1" required={true} label="Quantity at basket 1"
                            name="quantity1" value={copy.quantity1} onChange={onChangeNumber}/>

                            <FormInput
                            type="number" min="0" step="1" required={true} label="Quantity at basket 2"
                            name="quantity2" value={copy.quantity2} onChange={onChangeNumber}/>

                            <FormInput
                            type="number" min="0" step="1" required={true} label="Quantity at basket 3"
                            name="quantity3" value={copy.quantity3} onChange={onChangeNumber}/>
                        </div>
                    }

                    { (copy.type === QuantityDiscountType.MMPrice || copy.type === QuantityDiscountType.MMPriceBasket) &&
                        <div className="flex flex-col space-x-2 pt-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Prices in this table are</label>
                            
                            <span><input
                            type="radio"
                            name="extendedPriceDiscount" 
                            checked={!copy.extendedPriceDiscount}
                            value="false"
                            onChange={onCheckChange}/> Unit Prices</span>
                            
                            <span><input
                            type="radio"
                            name="extendedPriceDiscount"
                            checked={copy.extendedPriceDiscount}
                            value="true"
                            onChange={onCheckChange}/> Extended Prices</span>
                        </div>
                    }

                    { copy.type === QuantityDiscountType.MMPrice &&
                        <div className="">
                            <table className="w-full table-auto">
                                <thead className="bg-slate-400">
                                    <tr>
                                    <th>Quantity</th>
                                    <th>Reg. Price ($)</th>
                                    <th>Price A ($)</th>
                                    <th>Price B ($)</th>
                                    <th>Price C ($)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                        <FormInput
                                        type="number" min="1" step="1" required={true}
                                        name="quantity1" value={copy.quantity1} onChange={onChangeNumber}/>
                                        </td>

                                        <td>
                                        <FormInput
                                        type="number" min="0.00" step="0.01" required={true}
                                        name="price1" value={copy.price1} onChange={onChangeNumber}/>
                                        </td>

                                        <td>
                                        <FormInput
                                        type="number" min="0.00" step="0.01" required={true}
                                        name="price1A" value={copy.price1A} onChange={onChangeNumber}/>
                                        </td>

                                        <td>
                                        <FormInput
                                        type="number" min="0.00" step="0.01" required={true}
                                        name="price1B" value={copy.price1B} onChange={onChangeNumber}/>
                                        </td>

                                        <td>
                                        <FormInput
                                        type="number" min="0.00" step="0.01" required={true}
                                        name="price1C" value={copy.price1C} onChange={onChangeNumber}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        <FormInput
                                        type="number" min="0" step="1" required={true}
                                        name="quantity2" value={copy.quantity2} onChange={onChangeNumber}/>
                                        </td>

                                        <td>
                                        <FormInput
                                        type="number" min="0.00" step="0.01" required={true}
                                        name="price2" value={copy.price2} onChange={onChangeNumber}/>
                                        </td>

                                        <td>
                                        <FormInput
                                        type="number" min="0.00" step="0.01" required={true}
                                        name="price2A" value={copy.price2A} onChange={onChangeNumber}/>
                                        </td>

                                        <td>
                                        <FormInput
                                        type="number" min="0.00" step="0.01" required={true}
                                        name="price2B" value={copy.price2B} onChange={onChangeNumber}/>
                                        </td>

                                        <td>
                                        <FormInput
                                        type="number" min="0.00" step="0.01" required={true}
                                        name="price2C" value={copy.price2C} onChange={onChangeNumber}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        <FormInput
                                        type="number" min="0" step="1" required={true}
                                        name="quantity3" value={copy.quantity3} onChange={onChangeNumber}/>
                                        </td>

                                        <td>
                                        <FormInput
                                        type="number" min="0.00" step="0.01" required={true}
                                        name="price3" value={copy.price3} onChange={onChangeNumber}/>
                                        </td>

                                        <td>
                                        <FormInput
                                        type="number" min="0.00" step="0.01" required={true}
                                        name="price3A" value={copy.price3A} onChange={onChangeNumber}/>
                                        </td>

                                        <td>
                                        <FormInput
                                        type="number" min="0.00" step="0.01" required={true}
                                        name="price3B" value={copy.price3B} onChange={onChangeNumber}/>
                                        </td>

                                        <td>
                                        <FormInput
                                        type="number" min="0.00" step="0.01" required={true}
                                        name="price3C" value={copy.price3C} onChange={onChangeNumber}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        <FormInput
                                        type="number" min="0" step="1" required={true}
                                        name="quantity4" value={copy.quantity4} onChange={onChangeNumber}/>
                                        </td>

                                        <td>
                                        <FormInput
                                        type="number" min="0.00" step="0.01" required={true}
                                        name="price4" value={copy.price4} onChange={onChangeNumber}/>
                                        </td>

                                        <td>
                                        <FormInput
                                        type="number" min="0.00" step="0.01" required={true}
                                        name="price4A" value={copy.price4A} onChange={onChangeNumber}/>
                                        </td>

                                        <td>
                                        <FormInput
                                        type="number" min="0.00" step="0.01" required={true}
                                        name="price4B" value={copy.price4B} onChange={onChangeNumber}/>
                                        </td>

                                        <td>
                                        <FormInput
                                        type="number" min="0.00" step="0.01" required={true}
                                        name="price4C" value={copy.price4C} onChange={onChangeNumber}/>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    }

                    { copy.type === QuantityDiscountType.MMPercentOff &&
                        <div className="">
                            <table className="w-full table-auto">
                                <thead className="bg-slate-400">
                                    <tr>
                                    <th>Quantity</th>
                                    <th>Reg. Price (%)</th>
                                    <th>Price A (%)</th>
                                    <th>Price B (%)</th>
                                    <th>Price C (%)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                        <FormInput
                                        type="number" min="1" step="1" required={true}
                                        name="quantity1" value={copy.quantity1} onChange={onChangeNumber}/>
                                        </td>

                                        <td>
                                        <FormInput
                                        type="number" min="0.00" step="0.01" required={true}
                                        name="percentOffRegularPrice1" value={copy.percentOffRegularPrice1} onChange={onChangeNumber}/>
                                        </td>

                                        <td>
                                        <FormInput
                                        type="number" min="0.00" step="0.01" required={true}
                                        name="percentOffPrice1A" value={copy.percentOffPrice1A} onChange={onChangeNumber}/>
                                        </td>

                                        <td>
                                        <FormInput
                                        type="number" min="0.00" step="0.01" required={true}
                                        name="percentOffPrice1B" value={copy.percentOffPrice1B} onChange={onChangeNumber}/>
                                        </td>

                                        <td>
                                        <FormInput
                                        type="number" min="0.00" step="0.01" required={true}
                                        name="percentOffPrice1C" value={copy.percentOffPrice1C} onChange={onChangeNumber}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        <FormInput
                                        type="number" min="0" step="1" required={true}
                                        name="quantity2" value={copy.quantity2} onChange={onChangeNumber}/>
                                        </td>

                                        <td>
                                        <FormInput
                                        type="number" min="0.00" step="0.01" required={true}
                                        name="percentOffRegularPrice2" value={copy.percentOffRegularPrice2} onChange={onChangeNumber}/>
                                        </td>

                                        <td>
                                        <FormInput
                                        type="number" min="0.00" step="0.01" required={true}
                                        name="percentOffPrice2A" value={copy.percentOffPrice2A} onChange={onChangeNumber}/>
                                        </td>

                                        <td>
                                        <FormInput
                                        type="number" min="0.00" step="0.01" required={true}
                                        name="percentOffPrice2B" value={copy.percentOffPrice2B} onChange={onChangeNumber}/>
                                        </td>

                                        <td>
                                        <FormInput
                                        type="number" min="0.00" step="0.01" required={true}
                                        name="percentOffPrice2C" value={copy.percentOffPrice2C} onChange={onChangeNumber}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        <FormInput
                                        type="number" min="0" step="1" required={true}
                                        name="quantity3" value={copy.quantity3} onChange={onChangeNumber}/>
                                        </td>

                                        <td>
                                        <FormInput
                                        type="number" min="0.00" step="0.01" required={true}
                                        name="percentOffRegularPrice3" value={copy.percentOffRegularPrice3} onChange={onChangeNumber}/>
                                        </td>

                                        <td>
                                        <FormInput
                                        type="number" min="0.00" step="0.01" required={true}
                                        name="percentOffPrice3A" value={copy.percentOffPrice3A} onChange={onChangeNumber}/>
                                        </td>

                                        <td>
                                        <FormInput
                                        type="number" min="0.00" step="0.01" required={true}
                                        name="percentOffPrice3B" value={copy.percentOffPrice3B} onChange={onChangeNumber}/>
                                        </td>

                                        <td>
                                        <FormInput
                                        type="number" min="0.00" step="0.01" required={true}
                                        name="percentOffPrice3C" value={copy.percentOffPrice3C} onChange={onChangeNumber}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        <FormInput
                                        type="number" min="0" step="1" required={true}
                                        name="quantity4" value={copy.quantity4} onChange={onChangeNumber}/>
                                        </td>

                                        <td>
                                        <FormInput
                                        type="number" min="0.00" step="0.01" required={true}
                                        name="percentOffRegularPrice4" value={copy.percentOffRegularPrice4} onChange={onChangeNumber}/>
                                        </td>

                                        <td>
                                        <FormInput
                                        type="number" min="0.00" step="0.01" required={true}
                                        name="percentOffPrice4A" value={copy.percentOffPrice4A} onChange={onChangeNumber}/>
                                        </td>

                                        <td>
                                        <FormInput
                                        type="number" min="0.00" step="0.01" required={true}
                                        name="percentOffPrice4B" value={copy.percentOffPrice4B} onChange={onChangeNumber}/>
                                        </td>

                                        <td>
                                        <FormInput
                                        type="number" min="0.00" step="0.01" required={true}
                                        name="percentOffPrice4C" value={copy.percentOffPrice4C} onChange={onChangeNumber}/>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    }

                    { copy.type === QuantityDiscountType.MMPriceBasket &&
                        <div className="grid grid-cols-4 gap-4">
                            <FormInput label="Reg. Price ($)"
                            type="number" min="0.00" step="0.01" required={true}
                            name="price1" value={copy.price1} onChange={onChangeNumber}/>

                            <FormInput label="Price A ($)"
                            type="number" min="0.00" step="0.01" required={true}
                            name="price1A" value={copy.price1A} onChange={onChangeNumber}/>

                            <FormInput label="Price B ($)"
                            type="number" min="0.00" step="0.01" required={true}
                            name="price1B" value={copy.price1B} onChange={onChangeNumber}/>

                            <FormInput label="Price C ($)"
                            type="number" min="0.00" step="0.01" required={true}
                            name="price1C" value={copy.price1C} onChange={onChangeNumber}/>
                        </div>
                    }

                    { copy.type === QuantityDiscountType.MMPercentOffBasket &&
                        <div className="grid grid-cols-4 gap-4">
                            <FormInput label="Reg. Price (%)"
                            type="number" min="0.00" step="0.01" required={true}
                            name="percentOffRegularPrice1" value={copy.percentOffRegularPrice1} onChange={onChangeNumber}/>

                            <FormInput label="Price A (%)"
                            type="number" min="0.00" step="0.01" required={true}
                            name="percentOffPrice1A" value={copy.percentOffPrice1A} onChange={onChangeNumber}/>

                            <FormInput label="Price B (%)"
                            type="number" min="0.00" step="0.01" required={true}
                            name="percentOffPrice1B" value={copy.percentOffPrice1B} onChange={onChangeNumber}/>

                            <FormInput label="Price C (%)"
                            type="number" min="0.00" step="0.01" required={true}
                            name="percentOffPrice1C" value={copy.percentOffPrice1C} onChange={onChangeNumber}/>
                        </div>
                    }

                    <div className="flex justify-between px-8 pt-2 sticky bottom-0 bg-offWhite">
                        <div className="flex flex-col items-center">
                            <p className="text-sm">Delete</p>
                            <FaTrashAlt size="28" className="items-center relative flex justify-center
                            hover:text-red-600 transition-all duration-300 ease-linear cursor-pointer"
                            onClick={() => handleDelete()}/>
                        </div>

                        {
                            isModifyLoading ?
                            <button className="bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" disabled>
                                Processing...
                            </button>
                            :
                            isError ?
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                                Error
                            </button>
                            :
                            <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                            Save
                            </button>
                        }
                    </div>
                </form>
            </div>
        </div>
    );
}