import { Store } from "../../../Model/Store";
import { GetStoreItemMessages, GetStoreEmployees } from "../../../Services/Requests";
import { ItemMessageCard, ItemMessageNew } from "../Components/ItemMessageCard";
import { FindEmployeeName } from "../../../Helper/FinderHelper";

export const ItemMessagePage: React.FC<{ store:Store, isOrgStore?:boolean }> = ({ store, isOrgStore = false }) => {

    const { isLoading, data } = GetStoreItemMessages(store.id);

    return(
        <div className="flex justify-evenly pt-6 px-2 flex-row h-screen space-x-2">
            <div className="bg-offWhite shadow-xl rounded-3xl px-5 pb-8 mb-4 flex flex-col">
                <div className="mb-4 mt-4">
                    <div className="flex justify-between items-center">
                        <h1 className='font-bold text-xl'>Item Messages:</h1>
                        {/* <button onClick={() => setSearchVisible(true)}>
                            <FaSearch />
                        </button> */}
                    </div>
                </div>
                <div className="bg-slate-400 rounded-lg overflow-y-auto space-y-7 p-3">

                    { !isLoading ?
                        data && data.map((itemMessage) => {
                            return <ItemMessageCard itemMessage={itemMessage} storeID={store.id} key={itemMessage.syncGuid} isOrgStore={isOrgStore} />
                        })
                        :
                        <div>Loading...</div>
                    }

                    <ItemMessageNew storeID={store.id} />
                </div>
            </div>            
        </div>
    );
}