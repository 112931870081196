import {useNavigate} from "react-router-dom";
import {useFusionAuth} from "@fusionauth/react-sdk";
import {useEffect, useState} from "react";
import { GetStoresRegisters } from "../Services/Requests";
import { POSSystem } from "../Model/POSSystem";
import { POSCard, NewPOSCard } from "../Components/POSCards";
import { StoreCard, NewStoreCard } from "../Components/StoreCards";


function MyStore(){
    const navigate = useNavigate();
    const {isLoggedIn, isFetchingUserInfo} = useFusionAuth();

    useEffect(() => {
    if (!isLoggedIn) {
        navigate('/');
    }
    }, [isLoggedIn, navigate]);


    const { isLoading: isLoadingSR, data } = GetStoresRegisters();

    const [selectedID, setSelectedID] = useState(-1);
    const [posMachines, setPOSMachines] = useState<POSSystem[] | undefined>();

    const handleSelectStore = (storeID: number) => {
        setSelectedID(storeID);
    };

    useEffect(() => {
        if(selectedID !== -1 && data){
            let store = data.find(i => i.store.id === selectedID);
            store && setPOSMachines(store.posSystems);
        }
    }, [selectedID, data])


    if (!isLoggedIn || isFetchingUserInfo || isLoadingSR) {
        return null;
    }

    return (
        <div className="flex justify-evenly pt-6 px-2 flex-row h-full space-x-2">
            <div className="bg-offWhite shadow-xl rounded-3xl px-5 pb-8 mb-4 flex flex-col">
                <div className="mb-4 mt-4">
                    <h1 className='font-bold text-xl'>My Stores:</h1>
                    <p className='text-sm'>To get started, select one of your stores from the list or create a new store.</p>
                </div>
                <div className="bg-slate-400 rounded-lg overflow-y-auto space-y-7 p-3">

                    {
                        data && data.map((storeReg) => {
                            return <StoreCard storeData={storeReg} onSelectStore={handleSelectStore} isSelected={selectedID === storeReg.store.id} key={storeReg.store.id}/>
                        })
                    }

                    <NewStoreCard />
                </div>
            </div>

            { selectedID !== -1 &&
                <div className="bg-offWhite shadow-xl rounded-3xl px-5 pb-8 mb-4 flex flex-col">
                    <div className="mb-4 mt-4">
                        <h1 className='font-bold text-xl'>POS Machines:</h1>
                        <p className='text-sm'>View the status of pos machines linked to the currently selected store.</p>
                    </div>
                    
                    <div className="bg-slate-400 rounded-lg overflow-y-auto space-y-7 p-3">

                        {
                            posMachines && posMachines.map((machine) => {
                                return <POSCard  key={machine.id} posData={machine} />
                            })
                        }

                        { posMachines && posMachines.length < 3 &&
                            <NewPOSCard storeID={selectedID}/>
                        }
                    </div>
                    { posMachines &&
                        <p>{posMachines.length} POS Machines (Max 3)</p>
                    }
                </div>
            }
        </div>
    )
}

export default MyStore;