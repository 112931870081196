import { FaPen, FaTimes, FaTrashAlt } from "react-icons/fa";
import { ReadableMoney } from "../../../Helper/ConversionHelper";
import { Department } from "../../../Model/POSModel/Department";
import { Item, ItemTemplate, ItemView } from "../../../Model/POSModel/Item";
import { PosUser } from "../../../Model/POSModel/PosUser";
import { CreateStoreItem, DeleteStoreItem, GetItem, UpdateStoreItem } from "../../../Services/Requests";
import { Supplier } from "../../../Model/POSModel/Supplier";
import { IoArrowBack } from "react-icons/io5";
import FormCheckbox from "../../../Components/FormCheckbox";
import FormInput from "../../../Components/FormInput";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { Category } from "../../../Model/POSModel/Category";
import Tax from "../../../Model/POSModel/Tax";
import { FindCategoryName, FindDepartmentName, FindSupplierName, FindTaxName } from "../../../Helper/FinderHelper";

const ItemDetails: React.FC<{selectedItem: ItemView, storeID: number, onWindowExit():void, isOrgStore?:boolean, departments:Department[], categories:Category[], suppliers:Supplier[], taxes:Tax[] }> = ({selectedItem, storeID, onWindowExit, isOrgStore = false, departments, categories, suppliers, taxes }) => {

    const {isLoading, data} = GetItem(storeID, selectedItem.syncGuid);
    const [editMode, setEditMode] = useState(false);
    const [copy, setCopy] = useState<Item | undefined>();

    useEffect(() => {
        setEditMode(false);
        if(selectedItem.syncGuid === "00000000-0000-0000-0000-000000000000"){
            setCopy(ItemTemplate);
            setEditMode(true);
        }
    }, [selectedItem]);

    useEffect(() => {
        if(data)
            setCopy(data);
    }, [data]);

    const queryClient = useQueryClient();
    const { mutateAsync: createAsync, isLoading: isCreateLoading, isError:isCreateError } = CreateStoreItem(queryClient, storeID);
    const { mutateAsync: updateAsync, isLoading: isUpdateLoading, isError:isUpdateError } = UpdateStoreItem(queryClient, storeID);
    const { mutateAsync: deleteAsync, isLoading: isDeleting } = DeleteStoreItem(queryClient, storeID);

    let [isModifyLoading, setIsModifyLoading] = useState(false);
    let [isError, setIsError] = useState(false);

    useEffect(() => {
        setIsModifyLoading(isCreateLoading || isUpdateLoading);
        setIsError(isCreateError || isUpdateError);
    }, [isCreateLoading, isUpdateLoading, isCreateError, isUpdateError])

    const onChange = (e:any) => {
        copy && setCopy({...copy, [e.target.name]: e.target.value})
    }
    const onChangeNumber = (e:any) => {
        copy && setCopy({...copy, [e.target.name]: Number(e.target.value)})
    }
    const onCheckChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.type === "radio") {
            setCopy({
                ...copy!,
                [e.target.name]: e.target.value === "true"
            });
        } else {
            setCopy({
                ...copy!,
                [e.target.name]: e.target.checked
            });
        }
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        if(!copy)
            return;

        try {
            let result = copy.syncGuid === "00000000-0000-0000-0000-000000000000" ? await createAsync(copy) : await updateAsync(copy);

            if(result){
                onWindowExit();
            }

        } catch (e) {
            console.error(e);
        }
    }

    const handleDelete = async () => {
        try {
            if(!copy)
                return;

            let result = await deleteAsync(copy);

            if(result){
                onWindowExit();
            }

        } catch (e) {
            console.error(e);
        }
    }

    return (
        <div className="bg-offWhite shadow-xl rounded-3xl px-5 pb-8 mb-4 flex flex-col w-1/2">

            <div className="mb-4 mt-4">
                <div className="flex justify-between items-center">
                    <h1 className='font-bold text-xl'>Item Details</h1>
                    <button onClick={onWindowExit}>
                        <FaTimes size="28" className="items-center relative flex justify-center
                        hover:text-red-600 transition-all duration-300 ease-linear cursor-pointer"/>
                    </button>
                </div>
            </div>

            {!editMode ?
            <div>
                

                {isLoading && <span>Loading...</span>}
                {!isLoading && data && 
                <div className="overflow-y-auto">

                    <div className="flex justify-start items-center space-x-2">
                        <h2 className="font-bold text-lg">{data.description}</h2>
                        {isOrgStore === data.isCompanyData && <FaPen size="18" className="items-center relative flex justify-center
                        hover:text-orange-500 transition-all duration-300 ease-linear cursor-pointer"
                        onClick={() => setEditMode(true)}/>}
                    </div>

                    <label className="block text-gray-700 text-sm font-bold -mb-1 mt-4">Item Number</label>
                    <span className="w-full">{data.itemNumber}</span>

                    <label className="block text-gray-700 text-sm font-bold -mb-1">Barcode</label>
                    <span className="w-full">{data.barcodeNumber}</span>

                    <div className="grid grid-cols-2 gap-x-4 mt-6">
                        <div>
                            <label className="block text-gray-700 text-sm font-bold -mb-1">Department</label>
                            <span className="w-full">{FindDepartmentName(data.departmentID, departments)}</span>
                        </div>
                        <div>
                            <label className="block text-gray-700 text-sm font-bold -mb-1">Category</label>
                            <span className="w-full">{FindCategoryName(data.categoryID, categories)}</span>
                        </div>
                        <div>
                            <label className="block text-gray-700 text-sm font-bold -mb-1">Sales Tax 1</label>
                            <span className="w-full">{FindTaxName(data.taxId, taxes)}</span>
                        </div>
                        <div>
                            <label className="block text-gray-700 text-sm font-bold -mb-1">Sales Tax 2</label>
                            <span className="w-full">{FindTaxName(data.taxId2, taxes)}</span>
                        </div>
                        <div>
                            <label className="block text-gray-700 text-sm font-bold -mb-1">Supplier</label>
                            <span className="w-full">{FindSupplierName(data.supplierID, suppliers)}</span>
                        </div>
                        <div>
                            <label className="block text-gray-700 text-sm font-bold -mb-1">Price</label>
                            {ReadableMoney(data.price)}
                        </div>
                        <div>
                            <label className="block text-gray-700 text-sm font-bold -mb-1">Cost</label>
                            {ReadableMoney(data.cost)}
                        </div>
                        <div>
                            <label className="block text-gray-700 text-sm font-bold -mb-1">Price A</label>
                            {ReadableMoney(data.priceA)}
                        </div>
                        <div>
                            <label className="block text-gray-700 text-sm font-bold -mb-1">Price B</label>
                            {ReadableMoney(data.priceB)}
                        </div>
                        <div>
                            <label className="block text-gray-700 text-sm font-bold -mb-1">Price C</label>
                            {ReadableMoney(data.priceC)}
                        </div>
                        {/* <div>
                            <label className="block text-gray-700 text-sm font-bold -mb-1">Last modified on POS by:</label>
                            {FindEmployeeName(data.modifyUser, userData)}
                        </div> */}
                    </div>


                </div>}
            </div>
            :
            <div className="overflow-y-auto">
                {copy && <ItemForm handleSubmit={handleSubmit} onChange={onChange} onCheckChange={onCheckChange} onChangeNumber={onChangeNumber} 
                setEditMode={setEditMode} handleDelete={handleDelete} copy={copy} isLoading={isModifyLoading} isError={isError}
                departments={departments} categories={categories} suppliers={suppliers} taxes={taxes}
                />}
            </div>
        }
            
        </div>
    );
}

export default ItemDetails;

interface FormProps {
    handleSubmit(e: any): void,
    onChange(e: any): void,
    onCheckChange(e: any): void,
    onChangeNumber(e: any): void,
    setEditMode(state: boolean): void,
    handleDelete?(): void,
    copy: Item,
    isLoading: boolean,
    isError: boolean,
    departments: Department[],
    categories: Category[],
    suppliers: Supplier[],
    taxes: Tax[]
}

const ItemForm: React.FC<FormProps> = ({handleSubmit, onChange, onCheckChange, onChangeNumber, setEditMode, handleDelete, copy, isLoading, isError, departments, categories, suppliers, taxes}) => {

    return (
        <form onSubmit={handleSubmit}>
            <FormInput className="mb-4" label="Item Number"
            type="text" required={true} pattern="^(?! *$)[A-Za-z0-9\- .]+$" errorMessage="An item number is required." placeholder="0821516166"
            name="itemNumber" value={copy.itemNumber} onChange={onChange}/>

            <FormInput className="mb-4" label="Barcode"
            type="text" required={true} pattern="^(?! *$)[A-Za-z0-9\- .]+$" errorMessage="A barcode is required." placeholder="0821516166"
            name="barcodeNumber" value={copy.barcodeNumber} onChange={onChange}/>

            <FormInput className="mb-4" label="Description"
            type="text" required={true} pattern="^(?! *$)[A-Za-z0-9\- .]+$" errorMessage="A description is required. (No special characters)" placeholder="Coke 2L"
            name="description" value={copy.description} onChange={onChange}/>

            <FormCheckbox className="mb-4 flex space-x-2" label="Active"
            name="active" checked={copy.active} onChange={onCheckChange} />

            <div className="grid grid-cols-2 gap-x-4">
                <div>
                    <label className="block text-gray-700 text-sm font-bold my-2">Department</label>
                    <select name="departmentID" value={copy.departmentID} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
                    focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    onChange={onChange} required
                    >
                    <option value="">Select an option</option>
                    { departments.map((department) => {
                        return <option value={department.syncGuid}>{department.name}</option>
                    })}
                </select>
                </div>
                <div>
                    <label className="block text-gray-700 text-sm font-bold my-2">Category</label>
                    <select name="categoryID" value={copy.categoryID} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
                    focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    onChange={onChange} required
                    >
                    <option value="">Select an option</option>
                    { categories.map((category) => {
                        return <option value={category.syncGuid}>{category.name}</option>
                    })}
                    </select>
                </div>
                <div>
                    <label className="block text-gray-700 text-sm font-bold my-2">Sales Tax 1</label>
                    <select name="taxId" value={copy.taxId} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
                    focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    onChange={onChange} required
                    >
                    <option value="">Select an option</option>
                    { taxes.map((tax) => {
                        return <option value={tax.syncGuid}>{tax.description} ({tax.code})</option>
                    })}
                    </select>
                </div>
                <div>
                    <label className="block text-gray-700 text-sm font-bold my-2">Sales Tax 2</label>
                    <select name="taxId2" value={copy.taxId2} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
                    focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    onChange={onChange}
                    >
                    <option value="00000000-0000-0000-0000-000000000000">None (N/A)</option>
                    { taxes.map((tax) => {
                        return <option value={tax.syncGuid}>{tax.description} ({tax.code})</option>
                    })}
                    </select>
                </div>
                <div className="mb-2">
                    <label className="block text-gray-700 text-sm font-bold my-2">Supplier</label>
                    <select name="supplierID" value={copy.supplierID} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
                    focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    onChange={onChange} required
                    >
                    <option value="00000000-0000-0000-0000-000000000000">None (N/A)</option>
                    { suppliers.map((supplier) => {
                        return <option value={supplier.syncGuid}>{supplier.code}</option>
                    })}
                    </select>
                </div>
                <div>
                    <FormInput
                    type="number" min="0.00" step="0.01" required={true} label="Price" className="mt-2"
                    name="price" value={copy.price} onChange={onChangeNumber}/>
                </div>
                <div className="mb-2">
                    <FormInput
                    type="number" min="0.00" step="0.01" required={true} label="Cost"
                    name="cost" value={copy.cost} onChange={onChangeNumber}/>
                </div>
                <div>
                    <FormInput
                    type="number" min="0.00" step="0.01" required={true} label="Price A"
                    name="priceA" value={copy.priceA} onChange={onChangeNumber}/>
                </div>
                <div>
                    <FormInput
                    type="number" min="0.00" step="0.01" required={true} label="Price B"
                    name="priceB" value={copy.priceB} onChange={onChangeNumber}/>
                </div>
                <div>
                    <FormInput
                    type="number" min="0.00" step="0.01" required={true} label="Price C"
                    name="priceC" value={copy.priceC} onChange={onChangeNumber}/>
                </div>
                {/* <div>
                    <label className="block text-gray-700 text-sm font-bold -mb-1">Last modified on POS by:</label>
                    {FindEmployeeName(data.modifyUser, userData)}
                </div> */}
            </div>
            
            <div className="flex justify-between mt-4">
                {copy.syncGuid !== "00000000-0000-0000-0000-000000000000" && <IoArrowBack size="28" className="items-center relative flex justify-center mt-1
                    hover:text-white transition-all duration-300 ease-linear cursor-pointer"
                    onClick={() => setEditMode(false)}/>}

                { handleDelete &&
                    <div className="flex flex-col items-center">
                        <p className="text-sm">Delete</p>
                        <FaTrashAlt size="28" className="items-center relative flex justify-center
                        hover:text-red-600 transition-all duration-300 ease-linear cursor-pointer"
                        onClick={() => handleDelete()}/>
                    </div>
                }

                {
                    isLoading ?
                    <button className="bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" disabled>
                        Processing...
                    </button>
                    :
                    isError ?
                    <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                        Error
                    </button>
                    :
                    <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                    Save
                    </button>
                }
            </div>
        </form>
    );
}