import { QuantityDiscountType } from "../Model/POSModel/QuantityDiscount";
import { Role } from "../Model/Roles";

export function ConvertRole(role: Role){
    switch(role){
        case Role.Owner:
            return "Owner";

        case Role.Admin:
            return "Admin";

        case Role.StoreOwner:
            return "Store Owner";

        case Role.Accountant:
            return "Accountant";

        default:
            return "Unknown";
    }
}

export const ConvertDiscountType = (type: QuantityDiscountType): string => {
    switch(type){
        case QuantityDiscountType.MMPrice: return "Mix and Match: Price";
        case QuantityDiscountType.MMPercentOff: return "Mix and Match: Percent off";
        case QuantityDiscountType.BuyXGetY: return "Buy X, Get Y";
        case QuantityDiscountType.MMPriceBasket: return "Mix and Match: Price(Basket)";
        case QuantityDiscountType.MMPercentOffBasket: return "Mix and Match: Percent off(Basket)";

        default: return "Unknown";
    }
}