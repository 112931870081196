import { useState } from "react";

const FormCheckbox = (props) => {
    const { label, onChange, checked, ...inputProps} = props;

    return (
        <div className={props.className}>
            <label className="block text-gray-700 text-sm font-bold mb-1">{label}</label>
            <input type="checkbox" {...inputProps} onChange={onChange} checked={checked}
            className={``}
            />
        </div>
    )
}

export default FormCheckbox