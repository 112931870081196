export enum Page
{
    Placeholder,
    NoData,
    Department,
    Item,
    Tax,
    Tender,
    ItemMessage,
    QuantityDiscount,
    Supplier
}