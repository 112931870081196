import { useEffect, useState } from "react";
import { FaAngleDoubleLeft, FaChevronLeft, FaChevronRight, FaAngleDoubleRight } from "react-icons/fa";

interface PageNavigatorProps {
    totalCount?: number,
    chunkSize: number,
    currentPage: number,
    setPageInfo(page:number, chunkSize: number): void
}

const PageNavigator:React.FC<PageNavigatorProps> = ({totalCount, chunkSize, currentPage, setPageInfo}) => {

    const [totalPages, setTotalPages] = useState<number | undefined>();

    useEffect(() => {
        if(totalCount){
            setTotalPages(Math.ceil(totalCount/chunkSize))
        }
    }, [totalCount, chunkSize])

    const previousPage = () => {
        if(currentPage <= 1)
            setPageInfo(1, chunkSize);
        else
            setPageInfo(currentPage - 1, chunkSize);
    }

    const nextPage = () => {
        if(!totalPages)
            return;

        if(currentPage >= totalPages)
            setPageInfo(totalPages, chunkSize);
        else
            setPageInfo(currentPage + 1, chunkSize);
    }

    return(
        <div className="flex flex-row rounded-md border border-gray-700">
            <button className="bg-slate-300 h-full flex items-center border border-gray-700"
            onClick={() => setPageInfo(1, chunkSize)}>
                <FaAngleDoubleLeft size="28" />
            </button>
            <button className="bg-slate-300 h-full flex items-center border border-gray-700"
            onClick={previousPage}>
                <FaChevronLeft size="22" />
            </button>
            <span className="flex items-center justify-center mx-2">{currentPage} / {totalPages ? totalPages : "?"}</span>
            <select name="chunkSize" value={chunkSize} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
                    focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    onChange={(e) => setPageInfo(1, parseInt(e.target.value))}
                    >
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={200}>200</option>
            </select>
            <button className="bg-slate-300 h-full flex items-center border border-gray-700"
            onClick={nextPage}>
                <FaChevronRight size="22" />
            </button>
            <button className="bg-slate-300 h-full flex items-center border border-gray-700"
            onClick={() => totalPages && setPageInfo(totalPages, chunkSize)}>
                <FaAngleDoubleRight size="28" />
            </button>
        </div>
    );
}

export default PageNavigator;