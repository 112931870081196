import { useEffect, useState } from "react";
import TimePicker from "react-time-picker";
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';



const FormTime = ({ name, onChange, initialDateUTC, label, className }) => {

    const [selectedTime, setSelectedTime] = useState('00:00');

    useEffect(() => {
        if (initialDateUTC) {
          const localDate = new Date(initialDateUTC);
          const hours = String(localDate.getHours()).padStart(2, '0');
          const minutes = String(localDate.getMinutes()).padStart(2, '0');
          setSelectedTime(`${hours}:${minutes}`);
        }
      }, [initialDateUTC]);
    
      const handleTimeChange = (value) => {
        setSelectedTime(value);
        if (value) {
          const [hours, minutes] = value.split(':');
          const localDate = new Date();
          localDate.setHours(parseInt(hours, 10));
          localDate.setMinutes(parseInt(minutes, 10));
          localDate.setSeconds(0);
          localDate.setMilliseconds(0);
          const utcDate = new Date(localDate.toISOString());
          onChange({ target: { name, value: utcDate } });
        } else {
          onChange({ target: { name, value: null } });
        }
      };

    return (
        <div className={className}>
            <label className="block text-gray-700 text-sm font-bold mb-1">{label}</label>
            <TimePicker
                onChange={handleTimeChange}
                value={selectedTime}
                disableClock
                format="HH:mm"
                clearIcon={null}
                className="custom-time-picker"
            />
            <style>
                {`
                .custom-time-picker__wrapper {
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    padding: 4px;
                    display: flex;
                    align-items: center;
                }
                .custom-time-picker__inputGroup__input {
                    height: 24px;
                    font-size: 16px;
                    color: #000;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    padding: 4px;
                    margin: 0 2px;
                }
                .custom-time-picker__inputGroup__input::-webkit-outer-spin-button,
                .custom-time-picker__inputGroup__input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
                .custom-time-picker__inputGroup__input[type="number"] {
                    -moz-appearance: textfield;
                }
                `}
            </style>
        </div>
    )
}

export default FormTime