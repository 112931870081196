import { RequireAuth } from "@fusionauth/react-sdk";
import { GetOrgInfo, GetOrgs, GetOrgVirtualStores, JoinOrg } from "../Services/Requests";
import FormInput from "../Components/FormInput";
import { JoinGroup, OrgInfo } from "../Model/Transactional";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { NewOrgCard, OrgCard } from "../Components/OrgCards";
import { LoadingOrgMemberCard, OrgMemberCard } from "../Components/OrgMemberCards";
import { HiddenText } from "../Components/HiddenText";
import { Role } from "../Model/Roles";
import { Organization } from "../Model/Organization";
import { VirtualStoreCard, VirtualStoreNew } from "../Components/VirtualStoreCards";


function MyOrgs() {

    const [selectedOrg, setSelectedOrg] = useState(-1);

    const { isLoading: isLoadingOrg, data: orgData } = GetOrgs();
    const queryClient = useQueryClient();
    const { isLoading: isJoining, mutateAsync: joinAsync } = JoinOrg(queryClient);
    const [input, setInput] = useState<JoinGroup>({
        linkCode: ""
    });

    useEffect(() => { //Clear selected group if group no longer exists
        if(orgData && selectedOrg !== -1){
            let org = orgData.find(i => i.org.id === selectedOrg)
            if(!org){
                setSelectedOrg(-1);
            }
        };
    }, [selectedOrg, orgData])

    const { isLoading, data } = GetOrgInfo(selectedOrg);

    if(isLoadingOrg)
        return null;

    const handleSelectOrg = (orgID: number) => {
        setSelectedOrg(orgID);
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        if(!input.linkCode)
            return;

        try {
            let result = await joinAsync(input);

            if(result){
                setInput({
                    linkCode: ""
                })
            }

        } catch (e) {
            console.error(e);
        }
    };

    const onChange = (e:any) => {
        setInput({...input, [e.target.name]: e.target.value})
    };

    return(
        <RequireAuth>
            <div className="flex justify-evenly pt-6 flex-row h-full">
                <div className="bg-offWhite shadow-xl rounded-3xl px-5 pb-8 mb-4 flex flex-col">
                    <div className="mb-4 mt-4">
                        <h1 className='font-bold text-xl'>My Organizations:</h1>
                        <p className='text-sm'>To get started, select one of your organizations from the list or join a new one.</p>
                        <form className="flex flex-row w-full justify-between h-12" onSubmit={handleSubmit}>
                            <FormInput className="w-full" placeholder="Link Code" name="linkCode" value={input["linkCode"]} onChange={onChange} pattern="^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$"/>
                            { isJoining?
                                <button className="mt-2 ml-2 shadow px-4 bg-green-700 text-white font-bold rounded focus:outline-none focus:shadow-outline" disabled>
                                Joining
                                </button>
                                :
                                <button className="mt-2 ml-2 shadow px-4 bg-green-500 hover:bg-green-700 text-white font-bold rounded focus:outline-none focus:shadow-outline" type="submit">
                                Join
                                </button>
                            }
                            
                        </form>
                    </div>
                    <div className="bg-slate-400 rounded-lg overflow-y-auto space-y-7 p-3 h-full">
                        { orgData && orgData.map((org) => {
                            return <OrgCard orgData={org} onSelectOrg={handleSelectOrg} isSelected={selectedOrg === org.org.id} key={org.org.id}/>
                        })}
                        <NewOrgCard />
                    </div>
                </div>

                {   selectedOrg !== -1 &&
                    <OrgInfoWindow data={data} isLoading={isLoading} />
                }

                {   selectedOrg !== -1 && data && (data.userRole === Role.Owner || data.userRole === Role.Admin) &&
                    <VirtualStoresWindow org={data.org} />
                }
            </div>
        </RequireAuth>
    )
}

const OrgInfoWindow: React.FC<{ data?: OrgInfo, isLoading:boolean }> = ({ data, isLoading }) => {

    if(isLoading)
        return(
            <div className="bg-offWhite shadow-xl rounded-3xl px-5 pb-8 mb-4 flex flex-col">
                <div className="mb-4 mt-4">
                    <h1 className='font-bold text-xl'>Loading</h1>
                </div>
                <div className="bg-slate-400 rounded-lg overflow-y-auto space-y-7 p-3 h-full">
                    <div className="animate-pulse space-y-4">
                        <LoadingOrgMemberCard />
                        <LoadingOrgMemberCard />
                        <LoadingOrgMemberCard />
                    </div>
                </div>
            </div>
        )
    else if(data)
        return(
            <div className="bg-offWhite shadow-xl rounded-3xl px-5 pb-8 mb-4 flex flex-col">
                <div className="mb-4 mt-4">
                    <h1 className='font-bold text-xl'>{data.org.name}</h1>
                    { (data.org.linkCode && (data.userRole === Role.Owner || data.userRole === Role.Admin)) && <p className='text-sm font-semibold'>Link Code: <HiddenText className="font-normal" input={data.org.linkCode} /></p>}
                </div>
                <div className="bg-slate-400 rounded-lg overflow-y-auto space-y-7 p-3 h-full">
                    { data.members.map((member) => {
                        return <OrgMemberCard orgMember={member} clientRole={data.userRole} orgID={data.org.id} key={member.userID}/>
                    })}
                </div>
                <p>{data.members.length} Member(s)</p>
            </div>
        )
    else
        return(
            null
    )
}

const VirtualStoresWindow: React.FC<{ org:Organization }> = ({ org }) => {

    const {isLoading, data} = GetOrgVirtualStores(org.id);

    return(
        <div className="bg-offWhite shadow-xl rounded-3xl px-5 pb-8 mb-4 flex flex-col max-w-96">
            <div className="mb-4 mt-4">
                <h1 className='font-bold text-xl'>Virtual Stores</h1>
                <p className='text-sm'>Create a virtual store where you can easily add items and promotions, assign it to your organization members, and have those updates seamlessly applied to their physical stores.</p>
            </div>
            <div className="bg-slate-400 rounded-lg overflow-y-auto space-y-7 p-3 h-full">
                { data && 
                    data.map((store) => {
                        return <VirtualStoreCard copy={store} orgID={org.id} key={store.id} />
                    })
                }
                { isLoading ?
                    <div className="animate-pulse space-y-4">
                        <LoadingOrgMemberCard />
                        <LoadingOrgMemberCard />
                        <LoadingOrgMemberCard />
                    </div>
                    :
                    <VirtualStoreNew orgID={org.id} />
                }
            </div>
        </div>
    );
}

export default MyOrgs;