import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import * as reactRouterDom from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import {FusionAuthProvider} from "@fusionauth/react-sdk";
import {config} from "./config";
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <FusionAuthProvider {...config}>
        <QueryClientProvider client={queryClient}>
          <App/>
        </QueryClientProvider>
      </FusionAuthProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
