import { FaBriefcase, FaPen, FaTrashAlt } from "react-icons/fa";
import { IoAddCircleOutline, IoArrowBack } from "react-icons/io5";
import FormInput from "./FormInput";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { VirtualStore } from "../Model/VirtualStore";
import { CreateOrgVirtualStore, DeleteOrgVirtualStore, UpdateOrgVirtualStore } from "../Services/Requests";

interface CardProps {
    copy: VirtualStore,
    orgID: number,
}
export const VirtualStoreCard: React.FC<CardProps> = ({ copy, orgID }) => {

    const [editMode, setEditMode] = useState(false);

    return(
        <div className={`bg-slate-300 rounded-2xl px-4 pt-2 pb-4 transition-all duration-300 ease-linear border-black`}>
            {   !editMode &&
                <div className="flex justify-between items-center">
                    <div className="flex justify-between items-center space-x-2">
                        <h2 className="font-bold text-lg">{copy.name}</h2>
                        <FaPen size="18" className="items-center relative flex justify-center
                        hover:text-orange-500 transition-all duration-300 ease-linear cursor-pointer"
                        onClick={() => setEditMode(true)}/>
                    </div>
                    
                    <div className="flex justify-between items-center space-x-2">
                        {/* <p className={`items-center relative flex justify-center
                        ${ category.active ? "bg-green-500" : "bg-red-500"}
                        rounded-md text-white p-1 h-8`} >{category.active ? "Enabled" : "Disabled"}</p> */}
                        
                        <FaBriefcase size="45" className="items-center relative flex justify-center"/>
                    </div>
                </div>
            }
            

            { editMode ?
                <VirtualStoreEdit store={copy} setEditMode={setEditMode} orgID={orgID} />
                :
                <VirtualStoreInfo store={copy} />
            }
        </div>
    );
}


interface FormProps {
    handleSubmit(e: any): void,
    onChange(e: any): void,
    onCheckChange(e: any): void,
    setEditMode(state: boolean): void,
    handleDelete?(): void,
    copy: VirtualStore,
    isLoading: boolean,
    isError: boolean,
}

const VirtualStoreForm: React.FC<FormProps> = ({handleSubmit, onChange, onCheckChange, setEditMode, handleDelete, copy, isLoading, isError}) => {

    return (
        <form onSubmit={handleSubmit}>
            <FormInput className="mb-4" label="Name"
            type="text" required={true} pattern="^(?! *$)[A-Za-z0-9\- .]+$" errorMessage="An name is required. (No special characters)" placeholder="Default Store"
            name="name" value={copy.name} onChange={onChange}/>
            
            <div className="flex justify-between">
                <IoArrowBack size="28" className="items-center relative flex justify-center mt-1
                    hover:text-white transition-all duration-300 ease-linear cursor-pointer"
                    onClick={() => setEditMode(false)}/>

                { handleDelete &&
                    <div className="flex flex-col items-center">
                        <p className="text-sm">Delete</p>
                        <FaTrashAlt size="28" className="items-center relative flex justify-center
                        hover:text-red-600 transition-all duration-300 ease-linear cursor-pointer"
                        onClick={() => handleDelete()}/>
                    </div>
                }

                {
                    isLoading ?
                    <button className="bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" disabled>
                        Processing...
                    </button>
                    :
                    isError ?
                    <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                        Error
                    </button>
                    :
                    <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                    Save
                    </button>
                }
            </div>
        </form>
    );
}

const VirtualStoreInfo: React.FC<{ store: VirtualStore }> = ({ store }) => {


    return(
        null
        // <div>
        //     <p className="text-sm text-gray-700 -my-1">
        //         <span className="font-bold">Code: </span>
        //         {category.code}
        //     </p>
        //     {/* <p className="text-sm text-gray-700 -my-1">
        //         <span className="font-bold">Department: </span>
        //         {category.departmentGuid}
        //     </p> */}
        //     <p className="text-sm text-gray-700 -my-1">
        //         <span className="font-bold">Modify user: </span>
        //         {modifyUser}
        //     </p>
        //     <p className="text-sm text-gray-700 -my-1">
        //         <span className="font-bold">Last modified: </span>
        //         {ReadableTime(store.modifyDatetime)}
        //     </p>
        //     <p className="text-sm text-gray-700 -my-1">
        //         <span className="font-bold">Company managed: </span>
        //         {category.isCompanyData ? "Yes" : "No"}
        //     </p>
        //     {/* <p className="text-sm text-gray-700 -my-1">
        //         <span className="font-bold">Message: </span>
        //         {category.messageGuid}
        //     </p> */}
        // </div>
    );
}

interface EditProps {
    store: VirtualStore,
    orgID: number,
    setEditMode: (state: boolean) => void
}

const VirtualStoreEdit: React.FC<EditProps> = ({ store, orgID, setEditMode }) => {

    const queryClient = useQueryClient();
    const { mutateAsync, isLoading, isError } = UpdateOrgVirtualStore(queryClient, orgID);
    const { mutateAsync: deleteAsync, isLoading: isDeleting } = DeleteOrgVirtualStore(queryClient, orgID)
    const [copy, setCopy] = useState(store);

    const onChange = (e:any) => {
        setCopy({...copy, [e.target.name]: e.target.value})
    }
    const onCheckChange = (e:any) => {
        setCopy({...copy, [e.target.name]: e.target.checked})
        console.log(copy);
    }
    const handleSubmit = async (event: any) => {
        event.preventDefault();

        try {
            let result = await mutateAsync(copy);

            if(result){
                setEditMode(false);
            }

        } catch (e) {
            console.error(e);
        }
    }
    const handleDelete = async () => {
        try {
            let result = await deleteAsync(copy);

            // if(result){
            //     setEditMode(false);
            // }

        } catch (e) {
            console.error(e);
        }
    }

    return(
        <VirtualStoreForm handleSubmit={handleSubmit} onChange={onChange} onCheckChange={onCheckChange} setEditMode={setEditMode} handleDelete={handleDelete} copy={copy} isLoading={isLoading} isError={isError} />
    );
}

interface newProps {
    orgID: number,
}

export const VirtualStoreNew: React.FC<newProps> = ({ orgID }) => {
    const [editMode, setEditMode] = useState(false);

    const queryClient = useQueryClient();
    const { mutateAsync, isLoading, isError } = CreateOrgVirtualStore(queryClient, orgID);

    const template = {
        id: 0,
        name: "",
      };

    const [copy, setCopy] = useState<VirtualStore>(template);

    const resetEditMode = (state: boolean) => {
        setEditMode(state);
        setCopy(template);
    }

    const onChange = (e:any) => {
        setCopy({...copy, [e.target.name]: e.target.value})
        console.log(copy);
    }
    const onCheckChange = (e:any) => {
        setCopy({...copy, [e.target.name]: e.target.checked})
        console.log(copy);
    }


    const handleSubmit = async (event: any) => {
        event.preventDefault();

        try {
            let result = await mutateAsync(copy);

            if(result){
                resetEditMode(false);
            }

        } catch (e) {
            console.error(e);
        }
    }

    return (
        <div className={`relative flex rounded-2xl px-4 py-2 justify-center bg-slate-300
                transition-all duration-300 ease-linear ${editMode ? "" : "cursor-pointer hover:bg-slate-600 hover:text-white" }`}
                onClick={() => !editMode && setEditMode(true)}>


                { !editMode ?
                    <IoAddCircleOutline size="28"/>
                    :
                    <VirtualStoreForm handleSubmit={handleSubmit} onChange={onChange} onCheckChange={onCheckChange} setEditMode={resetEditMode} copy={copy} isLoading={isLoading} isError={isError} />
                }
                
            </div>
    );
}