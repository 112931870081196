import { IoAddCircleOutline, IoArrowBack } from "react-icons/io5";
import FormCheckbox from "../../../Components/FormCheckbox";
import FormInput from "../../../Components/FormInput";
import { ReadableTime } from "../../../Helper/ConversionHelper";
import { useQueryClient } from "react-query";
import { useEffect, useState } from "react";
import { FaBuilding, FaPen, FaTrashAlt } from "react-icons/fa";
import { Category } from "../../../Model/POSModel/Category";
import { CreateStoreCategory, DeleteStoreCategory, GetStoreDepartments, UpdateStoreCategory } from "../../../Services/Requests";

export const CategoryCard: React.FC<{ category: Category, storeID:number, modifyUser:string, isOrgStore?:boolean }> = ({ category, storeID, modifyUser, isOrgStore = false }) => {

    const [editMode, setEditMode] = useState(false);

    return(
        <div className={`bg-slate-300 rounded-2xl px-4 pt-2 pb-4 transition-all duration-300 ease-linear border-black`}>
            {   !editMode &&
                <div className="flex justify-between items-center">
                    <div className="flex justify-between items-center space-x-2">
                        <h2 className="font-bold text-lg">{category.name}</h2>
                        {isOrgStore === category.isCompanyData && <FaPen size="18" className="items-center relative flex justify-center
                        hover:text-orange-500 transition-all duration-300 ease-linear cursor-pointer"
                        onClick={() => setEditMode(true)}/>}
                    </div>
                    
                    <div className="flex justify-between items-center space-x-2">
                        <p className={`items-center relative flex justify-center
                        ${ category.active ? "bg-green-500" : "bg-red-500"}
                        rounded-md text-white p-1 h-8`} >{category.active ? "Enabled" : "Disabled"}</p>
                        
                        <FaBuilding size="45" className="items-center relative flex justify-center"/>
                    </div>
                </div>
            }
            

            { editMode ?
                <CategoryEdit category={category} setEditMode={setEditMode} storeID={storeID}/>
                :
                <CategoryInfo category={category} modifyUser={modifyUser} />
            }
        </div>
    );
}


interface FormProps {
    handleSubmit(e: any): void,
    onChange(e: any): void,
    onCheckChange(e: any): void,
    setEditMode(state: boolean): void,
    handleDelete?(): void,
    copy: Category,
    isLoading: boolean,
    isError: boolean,
    storeID: number
}

const CategoryForm: React.FC<FormProps> = ({handleSubmit, onChange, onCheckChange, setEditMode, handleDelete, copy, isLoading, isError, storeID}) => {

    const { isLoading: isDeptLoading, data } = GetStoreDepartments(storeID);

    return (
        <form onSubmit={handleSubmit}>
            <FormInput className="mb-4" label="Name"
            type="text" required={true} pattern="^(?! *$)[A-Za-z0-9\- .]+$" errorMessage="An name is required. (No special characters)" placeholder="Fruit"
            name="name" value={copy.name} onChange={onChange}/>

            <FormInput className="mb-4" label="Code"
            type="text" required={true} pattern="^(?! *$)[A-Za-z0-9\- .]+$" errorMessage="An code is required. (No special characters)" placeholder="C1"
            name="code" value={copy.code} onChange={onChange}/>

            <FormCheckbox className="mb-4 flex space-x-2" label="Active"
            name="active" checked={copy.active} onChange={onCheckChange} />

            <div className="mb-8">
                <label className="block text-gray-700 text-sm font-bold mb-2">Department</label>
                { isDeptLoading?
                    <p>Loading</p>
                    :
                    <select name="departmentID" value={copy.departmentID} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
                    focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    onChange={onChange} required
                    >
                    {/* <option value="">Select an option</option> */}
                    { data && data.map((department) => {
                        return <option value={department.syncGuid}>{department.name}</option>
                    })}
                </select>
                }

                
            </div>
            
            <div className="flex justify-between">
                <IoArrowBack size="28" className="items-center relative flex justify-center mt-1
                    hover:text-white transition-all duration-300 ease-linear cursor-pointer"
                    onClick={() => setEditMode(false)}/>

                { handleDelete &&
                    <div className="flex flex-col items-center">
                        <p className="text-sm">Delete</p>
                        <FaTrashAlt size="28" className="items-center relative flex justify-center
                        hover:text-red-600 transition-all duration-300 ease-linear cursor-pointer"
                        onClick={() => handleDelete()}/>
                    </div>
                }

                {
                    isLoading ?
                    <button className="bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" disabled>
                        Processing...
                    </button>
                    :
                    isError ?
                    <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                        Error
                    </button>
                    :
                    <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                    Save
                    </button>
                }
            </div>
        </form>
    );
}

const CategoryInfo: React.FC<{ category: Category, modifyUser:string }> = ({ category, modifyUser }) => {


    return(
        <div>
            <p className="text-sm text-gray-700 -my-1">
                <span className="font-bold">Code: </span>
                {category.code}
            </p>
            {/* <p className="text-sm text-gray-700 -my-1">
                <span className="font-bold">Department: </span>
                {category.departmentGuid}
            </p> */}
            <p className="text-sm text-gray-700 -my-1">
                <span className="font-bold">Modify user: </span>
                {modifyUser}
            </p>
            <p className="text-sm text-gray-700 -my-1">
                <span className="font-bold">Last modified: </span>
                {ReadableTime(category.modifyDatetime)}
            </p>
            <p className="text-sm text-gray-700 -my-1">
                <span className="font-bold">Company managed: </span>
                {category.isCompanyData ? "Yes" : "No"}
            </p>
            {/* <p className="text-sm text-gray-700 -my-1">
                <span className="font-bold">Message: </span>
                {category.messageGuid}
            </p> */}
        </div>
    );
}


const CategoryEdit: React.FC<{ category: Category, storeID: number, setEditMode: (state: boolean) => void }> = ({ category, setEditMode, storeID }) => {

    const queryClient = useQueryClient();
    const { mutateAsync, isLoading, isError } = UpdateStoreCategory(queryClient, storeID);
    const { mutateAsync: deleteAsync, isLoading: isDeleting } = DeleteStoreCategory(queryClient, storeID)
    const [copy, setCopy] = useState(category);

    const onChange = (e:any) => {
        setCopy({...copy, [e.target.name]: e.target.value})
    }
    const onCheckChange = (e:any) => {
        setCopy({...copy, [e.target.name]: e.target.checked})
        console.log(copy);
    }
    const handleSubmit = async (event: any) => {
        event.preventDefault();

        try {
            let result = await mutateAsync(copy);

            if(result){
                setEditMode(false);
            }

        } catch (e) {
            console.error(e);
        }
    }
    const handleDelete = async () => {
        try {
            let result = await deleteAsync(copy);

            // if(result){
            //     setEditMode(false);
            // }

        } catch (e) {
            console.error(e);
        }
    }

    return(
        <CategoryForm handleSubmit={handleSubmit} onChange={onChange} onCheckChange={onCheckChange} setEditMode={setEditMode} handleDelete={handleDelete} copy={copy} isLoading={isLoading} isError={isError} storeID={storeID} />
    );
}

export const CategoryNew: React.FC<{ storeID: number, departmentID: string }> = ({ storeID, departmentID }) => {
    const [editMode, setEditMode] = useState(false);

    const queryClient = useQueryClient();
    const { mutateAsync, isLoading, isError } = CreateStoreCategory(queryClient, storeID);

    useEffect(() => {
        resetEditMode(false);
    }, [departmentID])

    const template = {
        departmentID: departmentID,
        name: "",
        code: "",
        active: true,
        modifyUser: "00000000-0000-0000-0000-000000000000",
        modifyDatetime: new Date(),
        syncGuid: "00000000-0000-0000-0000-000000000000", //Placeholder - replaced by server
        isCompanyData: false,
        messageID: "00000000-0000-0000-0000-000000000000",
      };

    const [copy, setCopy] = useState<Category>(template);

    const resetEditMode = (state: boolean) => {
        setEditMode(state);
        setCopy(template);
    }

    const onChange = (e:any) => {
        setCopy({...copy, [e.target.name]: e.target.value})
        console.log(copy);
    }
    const onCheckChange = (e:any) => {
        setCopy({...copy, [e.target.name]: e.target.checked})
        console.log(copy);
    }


    const handleSubmit = async (event: any) => {
        event.preventDefault();

        try {
            let result = await mutateAsync(copy);

            if(result){
                resetEditMode(false);
            }

        } catch (e) {
            console.error(e);
        }
    }

    return (
        <div className={`relative flex rounded-2xl px-4 py-2 justify-center bg-slate-300
                transition-all duration-300 ease-linear ${editMode ? "" : "cursor-pointer hover:bg-slate-600 hover:text-white" }`}
                onClick={() => !editMode && setEditMode(true)}>


                { !editMode ?
                    <IoAddCircleOutline size="28"/>
                    :
                    <CategoryForm handleSubmit={handleSubmit} onChange={onChange} onCheckChange={onCheckChange} setEditMode={resetEditMode} copy={copy} isLoading={isLoading} isError={isError} storeID={storeID} />
                }
                
            </div>
    );
}