import React from 'react';
import CurrencyInput from 'react-currency-input-field';

interface CurrencyInputFieldProps {
  name: string;
  value: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  currency: string;
}

const FormCurrency: React.FC<CurrencyInputFieldProps> = ({ name, value, onChange, currency }) => {

    const initialValue = value;

  const handleValueChange = (value: string | undefined, name: string | undefined) => {
    if (value !== undefined && name !== undefined) {
      const numericValue = parseFloat(value);
      const event = {
        target: {
          name: name,
          value: numericValue,
        },
      } as unknown as React.ChangeEvent<HTMLInputElement>;
      onChange(event);
    }
  };

  return (
    <CurrencyInput
      id="currency-input"
      name={name}
      value={initialValue}
      decimalsLimit={2}
      allowDecimals={true}
      decimalScale={2}
      prefix={currency}
      onValueChange={(value, name) => handleValueChange(value, name)}
      className="currency-input"
    />
  );
};

export default FormCurrency;
