export type Store = {
    id: number,
    name: string,
    address: string,
    city: string,
    province: string,
    postalCode: string,
    status: StoreStatus
}

export enum StoreStatus{
    Deactivated,
    Requested,
    Active
}